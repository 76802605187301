import React, { useState, useEffect, useRef } from 'react';
import Moment from 'react-moment';
import { useSelector } from 'react-redux';
import {
  Icon,
  Image as SemanticImage,
  Tab,
  Loader,
  Dimmer,
  Menu,
  Popup,
  Label,
  Modal,
  Button,
  Header,
} from 'semantic-ui-react';
import { usePermissionsSimplified } from '../../../../hooks/usePermissionsSimplified';

import { deletePart } from '../../../config/functions';

import Details from './details';
import Files from './files';
import { selectTranslations } from '../../../config/i18n/slice';
import { toast } from 'react-toastify';
import ToolTip from 'react-power-tooltip';
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  ShareIcon,
} from '../../../../Assets/Icons/svg';
import useDeletePermissionModal from '../../../../hooks/Messages/useDeletePermissionModal';
import Inventory from './inventory';
import Log from './log';
import { Resizable } from 're-resizable';

function Preview({
  toggled,
  allIds,
  role,
  untoggle,
  edit,
  targetedData,
  refresh,
  setRefresh,
  setModalWidth,
  modalWidth,
  setFilterOptions,
  setTogglePreviw
}) {
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const t = useSelector(selectTranslations);

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return 'JOD';
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const {
    setDeletePermissionModal,
    DeletePermissionModal,
  } = useDeletePermissionModal();

  const [toggleAsideModal, setToggleAsideModal] = useState(false);

  const [fetching, setFetchLoading] = useState(true);
  const [DeleteSuccessModal, setDeleteSuccessModal] = useState(false);
  const [deleteErrorMessage, setDeleteErrorMessage] = useState(false);
  const [data, setData] = useState(null);

  const [deleteModal, setDeleteModal] = useState(false);

  const [showExclTooltip, setShowExclTooltip] = useState(false);
  const [showPdfTooltip, setShowPdfTooltip] = useState(false);
  const [showShareTooltip, setShareTooltip] = useState(false);
  const [showEditTooltip, setEditTooltip] = useState(false);
  const [showDeleteTooltip, setDeleteTooltip] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  useEffect(() => {
    setFetchLoading(true);
    if (targetedData) {
      setData(targetedData);
      setFetchLoading(false);
    }
  }, [targetedData]);

  const [popupIsOpen, setPopupIsOpen] = useState(false);

  const popUpRef = useRef(null);

  useEffect(() => {
    document.body.addEventListener('click', (e) => {
      let execlude_ids = ['popupTrigger'];
      // console.log(e.target.id);
      if (!execlude_ids.includes(e.target.id))
        setTimeout(() => setPopupIsOpen(false), 10);
      // if (e.target.id != "areaPopup") closeAllFilters();
    });
  }, [popUpRef]);

  /* The above code is defining an array called `panes` which contains objects with `menuItem` and `pane`
properties. Each `menuItem` represents a tab title and the `pane` contains the content to be
displayed when the tab is selected. The content is JSX code that renders different components like
`Inventory`, `Details`, `POs`, and `Log` with specific props passed to them. This code is likely
used to create a tabbed interface for displaying different types of data or information. */
  let panes = [
    {
      menuItem: t.parts.form.store_qty,
      pane: {
        key: 'inventory',
        content: (
          <div className="pane-body">
            <Inventory
            allIds={allIds}
            setTogglePreviw={setTogglePreviw}
            role={role}
            setModalWidth={setModalWidth}
            modalWidth={modalWidth}
              data={data}
              untoggle={untoggle}
              setFilterOptions={setFilterOptions}
              toggleAsideModal={toggleAsideModal}
              setToggleAsideModal={setToggleAsideModal}
            />
          </div>
        ),
      },
    },
    {
      menuItem: t.common.details,
      pane: {
        key: 'details',
        content: (
          <div className="pane-body">
            <Details data={data} untoggle={untoggle} />
          </div>
        ),
      },
    },
    // {
    //   menuItem: t.workOrders.details_view.log,
    //   pane: {
    //     key: 'log',
    //     content: (
    //       <div className="pane-body">
    //         <Log targetedData={data} />
    //       </div>
    //     ),
    //   },
    // },
  ];

  // if (userPermissions?.['purchase-order']?.view) {
  //   panes.splice(2, 0, {
  //     menuItem: t.parts.form.po,
  //     pane: {
  //       key: 'po',
  //       content: (
  //         <div className="pane-body">
  //           <Files targetedData={data} />
  //         </div>
  //       ),
  //     },
  //   });
  // }

  /*
   * The `deleteHandler` function handles the deletion of data and displays appropriate messages based on the response status.
   */
  const deleteHandler = async () => {
    const response = await deletePart(targetedData.id);

    // console.log(response);

    if (response.status == 200) {
      setDeleteErrorMessage(t.parts.delete_success);
      toast.success(t.parts.delete_success);
      toggleDeleteSuccessModal('data');
    } else if (response.status == 400) {
      toast.error(t.parts.delete_error);
    } else {
      toast.error(t.common.something_wrong);
    }
  };
  /*
   * The function `toggleDeleteSuccessModal` resets filter options, clears data, closes a modal, and triggers a refresh when a condition is met.
   */
  const toggleDeleteSuccessModal = (data) => {
    if (data) {
      setFilterOptions({
        vendors: [],
        serialNumber: '',
        categories: [],
        teams: [],
        status: [''],
        partType: [''],
        assets: [],
        locations: [],
        area: [],
        serialNumber: '',
      });
      setData();
      untoggle();
      setToggleAsideModal(false);
      setRefresh(!refresh);
    }
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={`aside-modal preview procedure ${toggled ? 'active' : ''}`}
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
        <div className="modal-header detail-header">
          <div className="flexbox justify-between align-center">
            <div className="name">
              {t.parts.form.part} #{data?.attributes?.countId }
            </div>

            <div className="flexbox align-center detail-header-buttons">
              <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowPdfTooltip(true)}
                onMouseLeave={() => setShowPdfTooltip(false)}
                // onClick={() => generatePDF()}
              >
                <DownloadIconBucket />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showPdfTooltip}
                  textBoxWidth="60px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_pdf}
                  </span>
                </ToolTip>
              </span>
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShowExclTooltip(true)}
                onMouseLeave={() => setShowExclTooltip(false)}
                onClick={() => downloadXLS()}
              >
                <FileDownloadIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showExclTooltip}
                  textBoxWidth="67px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.export_excel}
                  </span>
                </ToolTip>
              </span> */}
              {/* <span
                className="header-button c-pointer"
                style={{ position: 'relative' }}
                onMouseOver={() => setShareTooltip(true)}
                onMouseLeave={() => setShareTooltip(false)}
              >
                <ShareIcon fill={'#788CA5'} />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  arrowAlign="center"
                  position="bottom center"
                  show={showShareTooltip}
                  textBoxWidth="40px"
                  padding="5px 5px 5px 8px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.share}
                  </span>
                </ToolTip>
              </span> */}
              {userPermissions?.part?.edit ||
              checkForPermission(
                targetedData?.attributes?.createdByUser?.data?.id
              ) ? (
                <span
                  className="header-button c-pointer mt-1"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setEditTooltip(true)}
                  onMouseLeave={() => setEditTooltip(false)}
                  onClick={() => {
                    edit();
                    setPopupIsOpen(false);
                  }}
                >
                  <EditTextIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    arrowAlign="center"
                    position="bottom center"
                    show={showEditTooltip}
                    textBoxWidth="33px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.edit}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              {userPermissions?.part?.delete ? (
                <span
                  className="header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setDeleteTooltip(true)}
                  onMouseLeave={() => setDeleteTooltip(false)}
                  onClick={() => {
                    setDeletePermissionModal(true);
                    setPopupIsOpen(false);
                  }}
                >
                  <DeleteIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    arrowAlign="center"
                    position="bottom center"
                    show={showDeleteTooltip}
                    textBoxWidth="41px"
                    padding="5px 5px 5px 8px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.delete}
                    </span>
                  </ToolTip>
                </span>
              ) : null}
              <span
                className={`procedure-header-button c-pointer ${
                  selectedLanguage == 'en' ? 'ml-1' : 'mr-3'
                }`}
                style={{ position: 'relative' }}
                onMouseOver={() => setCancleTooltip(true)}
                onMouseLeave={() => setCancleTooltip(false)}
                onClick={() => {
                  untoggle();
                  setToggleAsideModal(false);
                }}
              >
                <CloseIcon />
                <ToolTip
                  color="#fff"
                  backgroundColor="#172B4D"
                  position={
                    selectedLanguage == 'en' ? 'bottom center' : 'bottom center'
                  }
                  arrowAlign="center"
                  show={showCancleTooltip}
                  textBoxWidth="36px"
                  padding="5px 5px 5px 7px"
                  borderRadius="5px"
                  static
                >
                  <span className="work-order-preview-tooltip">
                    {t.common.close}
                  </span>
                </ToolTip>
              </span>
            </div>
          </div>
        </div>
        <div className="body">
          {data ? (
            <Tab panes={panes} renderActiveOnly={false} />
          ) : (
            <Dimmer active inverted>
              <Loader inverted>{t.common.loading}</Loader>
            </Dimmer>
          )}
        </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => {
          setData();
          untoggle();
          setToggleAsideModal(false);
        }}
      ></div>

      <Modal
        size="tiny"
        open={deleteModal}
        onClose={() => {
          setDeleteModal(false);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper red">
              <Icon name="close" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.parts.delete_part_statement}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="red"
            onClick={() => {
              setDeleteModal(false);
              deleteHandler();
            }}
          >
            {t.common.delete}
          </Button>
          <Button color="black" onClick={() => setDeleteModal(false)}>
            {t.common.keep}
          </Button>
        </Modal.Actions>
      </Modal>
      <Modal
        size="tiny"
        open={DeleteSuccessModal}
        onClose={() => {
          setDeleteSuccessModal('close');
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              {deleteErrorMessage ? (
                <Icon name="close" color="red" size="huge" />
              ) : (
                <Icon name="check" color="white" size="huge" />
              )}
            </div>
            <p className="status-modal-popup-message">
              {deleteErrorMessage
                ? t.parts.related_part_error
                : t.parts.delete_success}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() =>
              toggleDeleteSuccessModal(deleteErrorMessage ? '' : 'close')
            }
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal>
      {/* Delete Permission Modal */}
      <DeletePermissionModal
        yesCallback={() => {
          setDeleteModal(false);
          deleteHandler();
        }}
        title={t.parts.add}
      >
        <span>{t.parts.delete_part_statement}</span>
      </DeletePermissionModal>
    </>
  );
}

export default Preview;
