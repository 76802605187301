import React, { useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import {
  createNewSet,
  currencyDecimalPlaces,
  fetchInvLocationsOptions,
  getActiveUsersByRole,
  getAllAreaLocations,
  getAllParentLocations,
  getAllParentLocations2,
  getAllParts,
  getAllSets,
  getAllVendros,
  updatePurchaseOrder,
  updateSet,
  uploadFile,
  urlToFile,
} from "../../config/functions";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { useDispatch, useSelector } from "react-redux";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";

import { selectTranslations } from "../../config/i18n/slice";

import { toast } from "react-toastify";

import { CloseIcon } from "../../../Assets/Icons/svg";
import ToolTip from "react-power-tooltip";

import { fetchSetsInitData } from "../../../redux/reducers/setsReducer";
import { cloneDeep } from "lodash";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { useEffect } from "react";
import PurchaseForm from "./purchaseForm";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { format } from "date-fns";
import { Resizable } from "re-resizable";

/*
 * The `Edit` function in JavaScript is used to handle editing functionality for purchase orders including fetching data, updating details, and managing attachments.
 */
function Edit({
  toggled,
  untoggle,
  setSelectedDates,
  setDueDates,
  setFilterOptions,
  setRefresh,
  refresh,
  targetedData,
  role,
  allIds,
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);

  const [attachments, setAttachments] = useState({
    warrantyFile: [],
  });

  const [options, setOptions] = useState({
    locations: [],
    // area: [],
    vendor: [],
    people: [],
  });

  const [fetchingLocation, setFetchingLocation] = useState(true);
  const [fetchingVendors, setFetchingVendros] = useState(true);
  const [fetchingPoeple, setFetchingPoeple] = useState(true);

  /*
   * The fetchVendorsData function fetches vendor data and maps it to create options for a dropdown menu.
   */
  const fetchVendorsData = async () => {
    const fetchData = await getAllVendros(organisationId);
    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions({
        ...options,
        vendor: the_options,
      });
    }
  };

  /*
   * The fetchPeopleData function fetches active user data by role and maps it to create options for a dropdown menu with user information and profile images.
   */
  const fetchPeopleData = async () => {
    const fetchData = await getActiveUsersByRole(
      ["limited_admin", "admin"],
      organisationId
    );
    if (fetchData.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function `handleDecimalPoints` formats a numerical value to a specified number of decimal places and removes trailing zeros after the decimal point.
   */
  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const truncatedValue = Math.trunc(value * factor) / factor;
    const formattedValue = truncatedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after the decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  /*
   * The function `fetchLocations` retrieves area locations data and formats it into an array of objects with specific keys and values.
   */
  const fetchLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(organisationId);
    if (fetchData?.length > 0) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
          address:el?.address ? el?.address : null,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        };
      });
      setOptions({
        ...options,
        locations: the_options,
      });

      return the_options;
    }
  };

  /*
   * The fetchArea function retrieves area options based on a selected location ID and updates the options state with the filtered area options that are designated as stores.
   */
  // const fetchArea = async (id, index) => {
  //   let currentSelectedLocation = options.locations.find(
  //     (location) => location.value == id
  //   );

  //   let the_options_area = [];
  //   if (currentSelectedLocation) {
  //     currentSelectedLocation.area.forEach((el) => {
  //       // if (el.attributes.isStore)
  //         the_options_area.push({
  //           key: `${el.id}`,
  //           value: el.id,
  //           text: `${el.attributes.name}`,
  //         });
  //     });
  //   }

  //   setOptions((prev) => {
  //     return {
  //       ...prev,

  //       area: the_options_area,
  //     };
  //   });

  //   // setOptions({
  //   //   ...options,
  //   //   area: areaOptions,
  //   // });
  // };

  const [partsData, setPartsData] = useState([]);
  const [addSetModal, setAddSetModal] = useState(false);
  const [initPartsData, setInitPartsData] = useState([]);
  const [initSetsData, setInitSetsData] = useState([]);

  const [setsData, setSetsData] = useState([]);

  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const [editSetData, setEditData] = useState();
  const [addPartModal, setAddPartModal] = useState(false);

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /*
   * The function `fetchOptions` retrieves data for vendors, locations, and people, then formats and sets the options for vendor selection, people selection, locations, and areas.
   */
  const fetchOptions = async () => {
    const [vendors, fetchDataLocation, people] = await Promise.all([
      getAllVendros(organisationId),
      fetchInvLocationsOptions(organisationId),
      getActiveUsersByRole(["limited_admin", "admin"], organisationId),
    ]);

    let vendors_options = [];
    if (vendors?.response) {
      vendors_options = vendors.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          address: el?.attributes?.billingInformation?.address,
        };
      });

      setFetchingVendros(false);
    }

    let people_options = [];
    if (people?.data) {
      people_options = people?.data?.map((el) => {
        const the_image = el.profileImage?.url
          ? el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          // description: `${teams_string_array.join(' , ')}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });

      setFetchingPoeple(false);
    }

    let the_options = [];

    if (fetchDataLocation?.length > 0) {
      the_options = fetchDataLocation.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
          image: { src: location_icon },
          address: el?.address ? el?.address : null,
          className: "main-location-dropdown-option",
        };
      });
    }

    // let the_options_area = [];

    // let currentSelectedLocation = the_options?.find(
    //   (location) =>
    //     location.value ==
    //     targetedData?.attributes?.shippingAddress?.companyName?.data?.id
    // );
    // if (currentSelectedLocation) {
    //   currentSelectedLocation.area.forEach((el) => {

    //       the_options_area.push({
    //         key: `${el.id}`,
    //         value: el.id,
    //         text: `${el.attributes.name}`,
    //       });
    //   });
    // }

    setOptions({
      ...options,
      vendor: vendors.response?.length > 0 ? vendors_options : [],
      people: people?.data?.length > 0 ? people_options : [],
      locations: fetchDataLocation?.length > 0 ? the_options : [],
      // area: the_options_area.length > 0 ? the_options_area : [],
    });

    setFetchingLocation(false);

    // targetedData?.attributes?.inventory.map((inventory)=>{

    // })
  };

  /*
   * The `uploadFilessHandler` function filters and maps changed files before uploading them and returning the response.
   */
  const uploadFilessHandler = async (files = []) => {
    // console.log(files);
    let theFiles = files.filter((el) => el.isChanged);
    theFiles = theFiles.map((el) => el.file);
    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  /*
   * The `addParts` function asynchronously fetches parts data, processes it based on selected parts, and sets the state for displaying parts in a modal for selection.
   */
  const addParts = async (selectedPartsData = []) => {
    setInitChange(true);
    if (partsData?.length <= 0) {
      // setLoading(true);
      const fetchData = await getAllParts(
        organisationId,
        "",
        1,
        99999,
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        [],
        "",
        [],
        role,
        allIds
      );
      let allPartData = fetchData?.response?.flatMap((part) => {
        if (part?.attributes?.inventory?.length > 0) {
          return part.attributes.inventory
          .filter((inv) => {
            // If the role is not super_admin or admin, check if lodatacation ID is in allIds
            if (!(role === "super_admin" || role === "admin")) {
              return allIds?.includes(inv?.location?.data?.id);
            }
            // For super_admin or admin, skip the check and include all data
            return true;
          })
            .map((inv) => {
              return {
                id: `${part?.id}-${inv?.location?.data?.id}`,
                part_id: part?.id,
                countId: part?.attributes?.countId,
                name: part.attributes.name,
                location: inv?.location?.data?.attributes?.name,
                locationId: inv?.location?.data?.id,
                totalCost: inv?.unitCost || 0,
                quantity: 1,
                unitCost: inv?.unitCost || 0,
                unitType: part.attributes.measuringUnit,
                selectedPart: false,
                fillMax: false,
                availableQty: inv?.availableQuantity ? inv.availableQuantity : 0,
                orderedQuantity: inv?.orderedQuantity ? inv?.orderedQuantity : 0,
              };
            });
        }
      
        // If there's no inventory, return an empty array
        return [];
      });

      if (fetchData?.response) {
        const filteredParts = allPartData
        ?.map((part) => {
          // let selectedPart = selectedPartsData.find(
          //   (el) => el?.id == part?.id
          // );

          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id == part.id
          );

          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              part_id: part.part_id,
              countId: part?.countId,
              name: part.name,
              location: part?.location,
              locationId: part?.locationId,
              totalCost: part?.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              fillMax: part?.fillMax,
              availableQty: part.availableQty,
              orderedQuantity: part.orderedQuantity,
            };
          }
        })
        .filter((part) => part !== null);
        // const allInitData = fetchData.response.map((part) => ({
        //   id: part.id,
        //   countId: part.attributes?.countId,
        //   name: part.attributes.name,
        //   totalCost: handleDecimalPoints(part.attributes.unitCost),
        //   quantity: 1,
        //   unitCost: handleDecimalPoints(part.attributes.unitCost),
        //   unitType: part.attributes.measuringUnit,
        //   selectedPart: false, // Check if part is selected
        //   fillMax: false,
        //   availableQty: part?.attributes?.totalAvailableQuantity
        //     ? part.attributes.totalAvailableQuantity
        //     : 0,
        //   orderedQuantity: part?.attributes?.totalOrderedQuantity
        //     ? part.attributes.totalOrderedQuantity
        //     : 0,
        // }));
        // const filteredParts = allInitData
        //   ?.map((part) => {
        //     const isSelected = selectedPartsData.some(
        //       (selectedPart) => selectedPart.id === part.id
        //     );
        //     let selectedPart = selectedPartsData?.find(
        //       (el) => el?.id == part?.id
        //     );
        //     // console.log('selectedPart: ', selectedPart);
        //     if (isSelected) {
        //       return null; // Skip this part
        //     } else {
        //       return {
        //         id: part.id,
        //         name: part.name,
        //         countId: part?.countId,
        //         totalCost: selectedPart
        //           ? handleDecimalPoints(selectedPart.totalCost)
        //           : handleDecimalPoints(part?.attributes?.unitCost),
        //         quantity: selectedPart ? selectedPart?.quantity : 1,
        //         unitCost: handleDecimalPoints(part.unitCost),
        //         unitType: part.measuringUnit,
        //         selectedPart: selectedPart ? true : false,
        //         fillMax: selectedPart ? selectedPart.fillMax : false,
        //         availableQty: part?.totalAvailableQuantity
        //           ? part.totalAvailableQuantity
        //           : 0,
        //         orderedQuantity: part?.totalOrderedQuantity
        //           ? part.totalOrderedQuantity
        //           : 0,
        //       };
        //     }
        //   })
        //   .filter((part) => part !== null);
        // filteredParts.sort((a, b) => {
        //   return (b.selectedPart === true) - (a.selectedPart === true);
        // });

        setPartsData(filteredParts);
        setInitPartsData(filteredParts);
        setAllPartsData(allPartData);
        // setLoading(false);
      } else {
        toast.error(t.common.something_wrong);
        // setLoading(false);
      }
      // setLoading(false);
    } else {
      // setLoading(true);
      const filteredParts = partsData?.map((part) => {
        let selectedPart = selectedPartsData.find((el) => el?.id == part?.id);
        console.log("selectedPart: ", selectedPart);

        return {
          selectedPart: selectedPart ? true : false,
          id: part.id,
          name: part.name,
          countId: part?.countId,
          totalCost: handleDecimalPoints(part.totalCost),

          quantity: part?.quantity ? part?.quantity : null,
          unitCost: handleDecimalPoints(part.unitCost),
          unitType: part.unitType,
          availableQty: part.availableQty,
          orderedQuantity: part.orderedQuantity,
          fillMax: selectedPart ? selectedPart.fillMax : false,
        };
      });
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      // setLoading(false);
    }
    setAddPartModal(true);
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();
      const formattedParts = targetedData?.attributes?.orderItems?.items?.map(
        (part) => {
          // Determine if the part is selected by checking if its id exists in selectedPartsData

          // Constructing the formatted part object
          return {
            selectedPart: true,
            fillMax: part.fillToMax,
            countId: part.part.data.attributes.countId,
            id: `${part?.part.data.id}-${part?.location?.data?.id}`,
            part_id: part?.part?.data?.id,
            name: part?.part?.data?.attributes?.name,
            location: part?.location?.data?.attributes?.name,
            locationId: part?.location?.data?.id,
            totalCost:
              part?.totalCost !== null
                ? handleDecimalPoints(part.totalCost)
                : 0,
            quantity: part?.qtyToOrder,
            unitType: part.part.data.attributes.measuringUnit,
            unitCost:part?.unitCost !== null ? handleDecimalPoints(part.unitCost) : 0,


              // part?.totalCost !== null
              //   ? handleDecimalPoints(
              //       Number(part?.totalCost) / Number(part?.qtyToOrder)
              //     )
              //   : 0,
            availableQty: part?.part?.data?.attributes?.inventory?.length > 0
              ? part?.part?.data?.attributes?.inventory?.find((inv)=> inv?.location?.data?.id ==part?.location?.data?.id )?.availableQuantity
              : 0,
            orderedQuantity: part?.part?.data?.attributes?.inventory?.length > 0
            ? part?.part?.data?.attributes?.inventory?.find((inv)=> inv?.location?.data?.id ==part?.location?.data?.id )?.orderedQuantity
            : 0,
          };
        }
      );

      const detailsData = {
        id: targetedData?.attributes?.details?.id,
        title: targetedData?.attributes?.details?.title,
        vendor: targetedData?.attributes?.details?.vendor?.data?.id,
        address: targetedData?.attributes?.details?.address
          ? targetedData?.attributes?.details?.address
          : "",
        people:
          targetedData?.attributes?.details?.people?.data?.length > 0
            ? targetedData.attributes.details.people.data.map(
                (el_user) => el_user.id
              )
            : [],
        poNumber: targetedData?.attributes?.details?.poNumber || "",
        dueDate: targetedData?.attributes?.details?.dueDate
          ? format(
              new Date(targetedData?.attributes?.details.dueDate),
              "yyyy-MM-dd"
            )
          : null,
        additionalInformation:
          targetedData?.attributes?.details?.additionalInformation,
      };

      const orderItemsData = {
        id: targetedData?.attributes?.orderItems?.id,
        items: formattedParts,
        taxes: targetedData?.attributes?.orderItems?.taxes
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.taxes)
          : null,
        taxAmt: targetedData?.attributes?.orderItems?.taxAmt
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.taxAmt)
          : null,
        shipAmt: targetedData?.attributes?.orderItems?.shipAmt
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.shipAmt)
          : null,
        otherAmt: targetedData?.attributes?.orderItems?.otherAmt
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.otherAmt)
          : null,
        shippingCost: targetedData?.attributes?.orderItems?.shippingCost
          ? handleDecimalPoints(
              targetedData?.attributes?.orderItems?.shippingCost
            )
          : null,
        otherCost: targetedData?.attributes?.orderItems?.otherCost
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.otherCost)
          : null,
        subTotal: targetedData?.attributes?.orderItems?.subTotal
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.subTotal)
          : null,
        totalCost: targetedData?.attributes?.orderItems?.totalCost
          ? handleDecimalPoints(targetedData?.attributes?.orderItems?.totalCost)
          : null,
        shippingCostInPercentage:
          targetedData?.attributes?.orderItems?.shippingCostInPercentage,
        taxesInPercentage:
          targetedData?.attributes?.orderItems?.taxesInPercentage,
        otherCostInPercentage:
          targetedData?.attributes?.orderItems?.otherCostInPercentage,
      };

      const shippingAddressData = {
        id: targetedData?.attributes?.shippingAddress?.id,
        companyName: targetedData?.attributes?.shippingAddress?.companyName
          ?.data?.id
          ? targetedData?.attributes?.shippingAddress?.companyName?.data?.id
          : "",
        // area: targetedData?.attributes?.shippingAddress?.area?.data?.id
        //   ? targetedData?.attributes?.shippingAddress?.area?.data?.id
        //   : "",
        contactPhoneNumber: targetedData?.attributes?.shippingAddress
          ?.contactPhoneNumber
          ? "+" + targetedData?.attributes?.shippingAddress?.contactPhoneNumber
          : null,
        address: targetedData?.attributes?.shippingAddress?.address
          ? targetedData?.attributes?.shippingAddress?.address
          : "",
        state: targetedData?.attributes?.shippingAddress?.state
          ? targetedData?.attributes?.shippingAddress?.state
          : "",
        zipcode: targetedData?.attributes?.shippingAddress?.zipcode
          ? targetedData?.attributes?.shippingAddress?.zipcode
          : null,
        city: targetedData?.attributes?.shippingAddress?.city
          ? targetedData?.attributes?.shippingAddress?.city
          : "",
      };

      const billingAddressData = {
        id: targetedData?.attributes?.billingAddressData?.id,
        companyName: targetedData?.attributes?.billingAddress?.companyName
          ? targetedData?.attributes?.billingAddress?.companyName
          : "",
        contactPhoneNumber: targetedData?.attributes?.billingAddress
          ?.contactPhoneNumber
          ? "+" + targetedData?.attributes?.billingAddress?.contactPhoneNumber
          : null,
        address: targetedData?.attributes?.billingAddress?.address
          ? targetedData?.attributes?.billingAddress?.address
          : "",
        sameAsShippingAddress:
          targetedData?.attributes?.billingAddress?.sameAsShippingAddress,
      };

      addParts(formattedParts);
      setSelectedTablePartData(formattedParts);

      setAttachments({
        ...attachments,
        warrantyFile:
          targetedData?.attributes?.attachments &&
          targetedData?.attributes?.attachments?.data?.length
            ? targetedData?.attributes.attachments?.data?.map((one) => {
                return {
                  id: one?.id,
                  file: urlToFile(one?.attributes?.url, one?.attributes?.name),
                  name: one?.attributes?.name,
                  type: one?.attributes?.mime,
                  cancelPopup: false,
                  isChanged: false,
                };
              })
            : [],
      });

      setEditData({
        details: detailsData,
        orderItems: orderItemsData,
        shippingAddress: shippingAddressData,
        billingAddress: billingAddressData,
      });
    }
  }, [toggled]);

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const { initData } = useSelector((state) => ({
    initData: state.sets.initData,
  }));

  const [initChange, setInitChange] = useState(false);

  const { setCancelModal, CancelModal } = useCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);

  const dispatch = useDispatch();

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  /*
   * The `handleAddSet` function in JavaScript handles the process of adding a set of items to a purchase
   * order, including transforming data, uploading files, updating the purchase order, and displaying success/error messages.
   */
  const handleAddSet = async (values, resetForm) => {
    let addedSetsData = cloneDeep(values);
    const numParts = addedSetsData.orderItems.items;
    setButtonStatus(1);

    if (numParts.length >= 1) {
      const transformedItemsArray = addedSetsData.orderItems.items.map(
        (item) => ({
          part: item.part_id,
          location: item?.locationId,
          qtyToOrder: Number(item.quantity),
          totalCost: Number(item.totalCost),
          fillToMax: item?.fillMax ? item?.fillMax : false,
          unitCost: Number(item?.unitCost),
          // totalCost: item.totalCost,
        })
      );

      addedSetsData.orderItems.items = transformedItemsArray;

      const transformedData = {
        details: addedSetsData.details,
        orderItems: addedSetsData.orderItems,
        shippingAddress: addedSetsData.shippingAddress,
        billingAddress: addedSetsData.billingAddress,
      };

      const warrantyFileResponse =
        attachments.warrantyFile && attachments?.warrantyFile?.length
          ? await uploadFilessHandler(attachments.warrantyFile)
          : null;

      let theAttachments =
        warrantyFileResponse?.status == 200
          ? warrantyFileResponse.data.map((el) => el.id)
          : [];

      let unchangedAttachments = attachments.warrantyFile.filter(
        (el) => !el.isChanged
      );
      unchangedAttachments =
        unchangedAttachments.length > 0
          ? unchangedAttachments.map((el) => el.id)
          : [];
      // console.log(unchangedAttachments)
      const toBeSendedCertificateFiles = theAttachments.concat(
        unchangedAttachments
      );

      const response = await updatePurchaseOrder(
        transformedData.details,
        transformedData.orderItems,
        transformedData.shippingAddress,
        transformedData.billingAddress,
        organisationId,
        targetedData?.id,
        toBeSendedCertificateFiles
      );

      if (response.status == 200) {
        // setRefresh(!refresh);
        setButtonStatus(0);

        // setFilterOptions({
        //   vendors: [],
        //   totalCost: {
        //     from: null,
        //     to: null,
        //   },
        //   status: [],
        //   submissionDate: [],
        //   dueDate: [],
        // });
        setSelectedDates([]);
        setDueDates([]);
        untoggle();
        toast.success(t.po.form.update_success);
        resetForm();

        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        setButtonStatus(0);
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
        }
      } else {
        setButtonStatus(0);
        toast.error(t.common.something_wrong);
      }
    } else {
      setButtonStatus(0);
      toast.error(t.po.form.add_valiadtion);
    }
    setButtonStatus(0);
  };

  /*
   * The `cancelHandler` function adjusts the modal width, hides the modal, and resets certain state variables.
   */
  const cancelHandler = () => {
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled && !toggleAsideModal
            ? "aside-modal po active procedure"
            : "aside-modal po procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"550px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.po.edit} #{targetedData?.attributes?.countId}
              </div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>

          <PurchaseForm
            role={role}
            allIds={allIds}
            setToggleAsideModal={setToggleAsideModal}
            toggleAsideModal={toggleAsideModal}
            initialFormValues={editSetData}
            initChange={initChange}
            setInitChange={setInitChange}
            buttonStatus={buttonStatus}
            cancelHandler={cancelHandler}
            setNewCancelModal={setNewCancelModal}
            handleAddSet={handleAddSet}
            setOptions={setOptions}
            // fetchArea={fetchArea}
            fetchLocations={fetchLocations}
            fetchVendorsData={fetchVendorsData}
            options={options}
            setPartsData={setPartsData}
            partsData={partsData}
            setInitPartsData={setInitPartsData}
            initPartsData={initPartsData}
            setInitSetsData={setInitSetsData}
            initSetsData={initSetsData}
            setSetsData={setSetsData}
            setsData={setsData}
            setAttachments={setAttachments}
            attachments={attachments}
            edit={true}
            fetchingLocation={fetchingLocation}
            setSelectedTablePartData={setSelectedTablePartData}
            selectedTablePartData={selectedTablePartData}
            setAllPartsData={setAllPartsData}
            allPartsData={allPartsData}
            prevPartsData={prevPartsData}
            setPrevPartsData={setPrevPartsData}
          />
        </Resizable>
      </div>
      <div
        className={
          toggled && !toggleAsideModal
            ? "aside-modal-background active"
            : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Edit;
