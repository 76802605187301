import { Success } from "../../../../../../Assets/Icons/svg";

export default {
  title: "Assets",
  title_small: "assets",
  add: "Asset",
  asset_name_data: "Asset Name",
  message: "Message",
  asset: "Asset",
  edit_asset: "Edit Asset",
  deactivate: "Deactivate",
  activate: "Activate",
  deactivate_asset: "Deactivate Asset",
  activate_asset: "Activate Asset",
  dispose: "Dispose",
  disposed_asset: "Disposed",
  filter: {
    searchByName: "Search by name",
    searchBySerialNumber: "Search by serial number",
    area: "Area",
    model: "Model",
    wed: "WED",
    barcode: "Bar Code",
    vendors: "Vendors",
    createdByUser: "Created By",
    createdAt: "Created At",
    qrcode: "QR Code",
    qr_barcode: "QR Code/ Bar Code ",
  },

  sheet: {
    sheet_name: "Asset Name*",
    sheet_description: "Description",
    sheet_parent_asset: "Parent Asset Name",
    sheet_serialNumber: "Serial Number *",
    qr_barcode: "QR Code/ Bar Code*",
    modal: "Model",
    category: "Category*",
    location: "Location Name*",
    area: "Area",
    customer: "Customer Name",
    vendor: "Vendor Name",
    teams: "Teams",
    people: "People",
    p_price: "Purchase Price ",
    manufacturer: "Manufacturer",
    d_rate: "Depreciation Rate (%)",
    e_life: "Estimate Useful Life (Yr)",
    w_date: "Warranty Expiration Date",
    placed_date: "Placed In Service Date",
    add_date: "Additional Information",
  },

  sheet2: {
    sheet_name: "اسم الممتلك*",
    sheet_description: "الوصف",
    sheet_parent_asset: "اسم الممتلك الرىْيسي",
    sheet_serialNumber: "الرقم التسلسلي *",
    qr_barcode: "رمز الاستجابة السريعة / رمز الباركود*",
    modal: "النوع",
    category: "الفئة*",
    location: "اسم الموقع",
    area: "المنطقة",
    customer: "اسم العميل",
    vendor: "اسم المورد",
    teams: "الفرق",
    people: "الأفراد",
    p_price: "سعر الشراء",
    manufacturer: "المصنّع",
    d_rate: "معدل الاستهلاك (%)",
    e_life: "العمر الإنتاجي المقدّر (سنة)",
    w_date: "تاريخ انتهاء الكفالة",
    placed_date: "وضع في الخدمة",
    add_date: "معلومات اضافية",
  },

  work_order_status: {
    dispose: "Disposed",
    inactive: "Inactive",
    active: "Active",
  },
  disposed: "Dispose Asset",
  add_reason: "Add reason to dispose",
  added_cost: "Added Cost",
  added_cost_placeholder: "Add the cost",
  asset_info: "Asset Information",
  table: {
    name: "Name",
    sheet_name: "Asset Name*",
    ID: "ID",
    serialNumber: "Serial Number",
    sheet_serialNumber: "Serial Number *",
    area: "Area",
    model: "Model",
    barcode: "Barcode",
    vendor: "Vendors",
    wed: "WED",
    createdByUser: "Created By",
    createdAt: "Created At",
    updatedAt: "Updated At",
    status: "Status",
  },
  contains: "Contains",
  search: "Search",
  reset: "Reset",
  attach_qr_code: "Attach QR Code",
  warranty_options: {
    any_date: "Any Date",
    today: "Today",
    tomorrow: "Tomorrow",
    next_7_days: "Next 7 Days",
    this_month: "This Month",
    this_year: "This Year",
    expired: "Expired",
  },
  created_at_options: {
    any_date: "Any Date",
    today: "Today",
    this_month: "This Month",
    this_year: "This Year",
    last_7_days: "Last 7 Days",
    last_month: "Last Month",
    last_year: "Last Year",
  },
  select_people: "Select People",
  select_vendor: "Select Vendor",
  form: {
    sheet_teams: "Teams",
    child_asset: "Child Assets",
    add_asset: "Add Asset",
    name: "Name",
    enter_asset_name: "Enter Asset Name",
    description: "Description",
    describe_asset: "Describe asset",
    serial_number: "Serial Number",
    enter_serial_number: "Enter Serial Number",
    procedure: "Procedure",
    select_procedure: "Select Procedure",
    model: "Model",
    enter_asset_model: "Enter Asset Model",
    area: "Area",
    enter_asset_area: "Enter Asset Area",
    category: "Category",
    sheet_category: "Category*",
    select_category: "Select Category",
    image: "Asset Image",
    vendor: "Vendors",
    sheet_vendor: "Vendor Name",
    customer: "Customers",
    sheet_customer: "Customer Name",
    purchase_price: "Purchase Price",
    sheet_purchase_price: "Purchase Price ",
    add_purchase_price: "Add purchase price",
    manufacturer: "Manufacturer",
    add_manufacturer: "Add manufacturer",
    depreciation_rate: "Depreciation Rate",
    sheet_depreciation_rate: "Depreciation Rate (%)",
    add_depreciation_rate: "Add depreciation rate",
    estimated_life: "Estimated Useful Life",
    sheet_estimated_life: "Estimate Useful Life (Yr)",
    add_estimated_life: "Add estimated useful life",
    placed_in_service_date: "Placed In Service",
    sheet_placed_in_service_date: "Placed In Service Date",
    add_placed_in_service_date: "Add placed in service date",
    date_placeholder: "dd/mm/yyyy",
    warranty_expiration_date: "Warranty Expiration Date",
    add_warranty_expiration_date: "Add warranty expiration date",
    additional_information: "Additional Information",
    add_additional_information: "Add additional information",
    warranty_file: "Warranty File",
    add: "Add",
    allowed_type: "Allowed Type:",
    submit: "Submit",
    cancel: "Cancel",
    is_there_sub_location: "Is there any sub locations?",
    is_there_area: "Is there any area/s?",
    sub_locations: "Sub Locations",
    select_sub_locations: "Select sub locations",
    add_area: "Add Area",
    select_category: "Select Category",
    select_vendor: "Select Vendor",
    select_customer: "Select Customer",
    location: "Location",
    sheet_location: "Location Name",
    select_location: "Select Location",
    asset_added_success: "Asset Added Successfully",
    delete_asset_statement: "Are you sure you want to delete this asset?",
    activate_asset_statement1: "Are you sure, you want to activate the asset",
    deactivate_asset_statement1:
      "Are you sure, you want to deactivate the asset",
    update_asset_status_statement:
      "Do you want to deactivate the associated children assets?",
    update_asset_status_statement_active:
      "Are you sure you want to activate the asset",
    related_status_asset_error: "This Asset cannot be deactivate",
    delete_asset_success: "Asset deleted successfully",
    update_status_asset_success: "Asset activated successfully",
    update_status_asset_success_deactivate:
      "Asset deactivated/disposed successfully",
    related_asset_error: "This Asset cannot be deteleted",
    parts: "Parts",
    current_customer: "Current Customer",
    current_location: "Current Location",
  },
  upload_part: "Upload Parts Sheet",
  bulk_part: "Bulk Upload Parts",
  import_part: "Import Parts",
  imported_asset: "Assets Uploaded",
  imported_part: "Parts Uploaded",
  part_temp: "Download Part Template",
  parts_temp_xls: "Parts Template.xlsx",
  no_barcode: "No asset profiles contain this given QR/Bar Code",
  serial_valid: "Add a valid serial number of 10 digits",
  no_assets_available: "Looks like you don't have Assets yet.",
  press_to_add_assets: "Press the " + " button to add your first Asset.",
  create_asset: "Add Asset",
  asset_already_exist: "Asset already exist with given name",
  asset_create_success: "Asset created successfully",
  upload_excel_asset_file:
    "Upload failed: Unsupported file type. Please upload a file in the correct format (.xls, .xlsx).",
  bulk_assets: "Bulk Upload Assets",
  import_assets: "Import Assets",
  upload_assets: "Upload Assets Sheet",
  browse_btn: "Browse",
  assets_temp: "Download Asset Template",
  assets_temp_xls: "Assets Template.xlsx",
  category_temp_xls: "Category Template.xlsx",
  active: "Active",
  inActive: "Inactive",
  start_add_asset: "Start adding assets",
  no_related_QR_msg: "No related Asset with this QR Code",
  how_to_add_assets: "how to add assets",
  warrenty_file: "Warranty file",
  attach_file: "Attach a file to this asset",
  asset_dispose_statement: "Asset Dispose Information",
  dispose_reason: "Dispose Reason",
  serial_number_exist: "The serial number already exists.",
  add_cost_reason: "Please add the reason and cost",
  completed_wo: "was completed on this asset",
  delete_area:
    "This Area cannot be deleted as it is connected to other data in the system.",
  delete_store: "Unable to deselect store. Linked to a part profile.",
  child_asset: "Is this a child asset?",
  parent_asset: "Parent Asset",
  parent_asset_place: "Select parent asset",
  hirachy_error_title: "Error: Asset Hierarchy Change Not Allowed",
  hirachy_error_desc:
    "The asset you selected cannot be moved to a child position because it currently has its own linked child and grandchild assets. Please review and adjust the asset hierarchy before making changes.",
  review_hirachy: "Review Hierarchy",
  upload_file_valid: "You can only upload .xls or .xlsx",
  max_length_error:
    "The uploaded sheet should not contain more than 200 records.",
  success_import: "Successful Upload",
  failed_import: "Failed Upload",
  deactivate_label_parent: "What do you want to deactivate?",
  activate_label_parent: "What do you want to activate?",
  deactivate_label_option1: "This asset only",
  deactivate_label_option2: "This asset and the associated children assets?",
  asset_bulk_upload_title: "Bulk Upload Assets",
  part_bulk_upload_title: "Bulk Upload Parts",
  part_error:
    "Upload failed: Unsupported file . Please upload the correct Bulk Upload Asset Template.",
  asset_error:
    "Upload failed: Unsupported file . Please upload the correct Bulk Upload Part Template.",
  data_error:
    "Upload failed: The file is empty. Please upload a file with valid data.",
  errors: {
    "The team entered does not exist in the system.":
      "The team entered does not exist in the system.",
    "The max. quantity must be greater than the min. quantity.":
      "The max. quantity must be greater than the min. quantity.",
    "The date format is incorrect.": "The date format is incorrect.",
    "The people entered do not exist in the system.":
      "The people entered do not exist in the system.",
    "No location was entered for the populated area.":
      "No location was entered for the populated area.",
    "Name is empty.": "Name is empty.",
    "Asset name attribute must be unique.":
      "Asset name attribute must be unique.",
    "The parent asset entered does not exist.":
      "The parent asset entered does not exist.",
    "The grand child cannot be set as a parent asset.":
      "The grand child cannot be set as a parent asset.",
    "Serial number is empty.": "Serial number is empty.",
    "Serial number contain 1 digit as a minimum and 13 digits as a maximum.":
      "Serial number contain 1 digit as a minimum and 13 digits as a maximum.",
    "Serial number attribute must be unique.":
      "Serial number attribute must be unique.",
    "Serial Numbers must contain alphanumeric data from 1 to 13 digits":
      "Serial Numbers must contain alphanumeric data from 1 to 13 digits",
    "Serial number contains only digits.":
      "Serial number contains only digits.",
    "No QR/Bar Code selected for this asset.":
      "No QR/Bar Code selected for this asset.",
    "No Category selected for this asset.":
      "No Category selected for this asset.",
    "Undefined Error.": "Undefined Error.",

    "Serial number is not unique.": "Serial number is not unique.",
    "The asset name is not unique.": "The asset name is not unique.",
    "No QR/Bar Code selected for this asset.":
      "No QR/Bar Code selected for this asset.",
    "The parent asset entered does not exist.":
      "The parent asset entered does not exist.",
    "The grandchild cannot be set as a parent asset.":
      "The grandchild cannot be set as a parent asset.",
    "No category was entered.": "No category was entered.",
    "No location was entered for the populated sub-location.":
      "No location was entered for the populated sub-location.",
    "No location was entered for the populated area.":
      "No location was entered for the populated area.",
    "The people entered do not exist in the system.":
      "The people entered do not exist in the system.",
    "The team entered does not exist in the system.":
      "The team entered does not exist in the system.",
    "The data format is incorrect.": "The data format is incorrect.",
  },
};
