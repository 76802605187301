import React, { useState, useEffect } from "react";
import {
  Modal,
  Input,
  Form,
  Divider,
  Dimmer,
  Checkbox,
  Loader,
  Dropdown,
} from "semantic-ui-react";
import * as Yup from "yup";
import Asterisk from "../../components/common/Asterisk";
import { Formik, Form as FormikForm, FieldArray } from "formik";
import { useSelector } from "react-redux";

import { selectTranslations } from "../../config/i18n/slice";
import {
  handleKeyDown,
  getAllParts,
  getAllSets,
  currencyDecimalPlaces,
  getAllActiveSets,
} from "../../config/functions";
import {
  CustomerPlusIcon,
  DeleteIcon,
  CloseIcon,
  SearchIcon,
  ArrowLeft,
  ArrowRight,
} from "../../../Assets/Icons/svg";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";
import _ from "lodash";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/Pagination";
import { toast } from "react-toastify";

const positiveNumRegex = /^[1-9]\d*$/;
/*
 * The `SetForm` function in JavaScript defines a form component with various fields and functionalities for adding parts and sets, handling validations, and displaying modals for adding
 * parts and sets.
 */
const SetForm = ({
  initialFormValues,
  edit = false,
  cancelHandler = () => {},
  buttonStatus,
  initPartsData,
  partsData = [],
  initSetsData,
  setsData = [],
  dropdownOptions,
  prevPartsData = [],
  allSetsData = [],
  prevSetsData = [],
  selectedTableSetData = [],
  allPartsData = [],
  selectedTablePartData = [],
  initChange,
  role,
  allIds,
  setNewCancelModal = () => {},
  setAllSetsData = () => {},
  setAllPartsData = () => {},
  handleAddSet = () => {},
  setInitChange = () => {},
  setSetsData = () => {},
  setInitSetsData = () => {},
  fetchLocations = () => {},
  setPrevPartsData = () => {},
  setPrevSetsData = () => {},
  setInitPartsData = () => {},
  setSelectedTableSetData = () => {},
  setSelectedTablePartData = () => {},
  setPartsData = () => {},
}) => {
  const t = useSelector(selectTranslations);
  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  // const handleDecimalPoints = (value) => {
  //   const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
  //   const factor = Math.pow(10, decimalPlaces);
  //   return (Math.round(value * factor) / factor).toFixed(decimalPlaces);
  // };

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  /* The above code is defining a validation schema using Yup for a person object. The schema includes
validation rules for the person's name, parts array, and sets array. */
  const PersonValidationSchema = Yup.object().shape({
    name: Yup.string().required(t.common.required_field),
    locations: Yup.string().required(t.common.required_field),
    parts: Yup.array().of(
      Yup.object().shape({
        parts_id: Yup.string(),
        quantity: Yup.number()
          .nullable()
          .min(0.1, t.common.quantity_must_be_greater_than_zero) // Add condition for quantity
          .test(
            "is-valid-quantity",
            t.common.valid_digits, // Error message for invalid quantity
            (value) => {
              // Check if quantity matches the pattern
              if (!value) return true; // If quantity is empty, return true
              const regex = /^\d{1,8}(\.\d{1,4})?$/;
              return regex.test(value.toString());
            }
          )
          .required(t.common.required_field),
        totalCost: Yup.string(),
      })
    ),
    sets: Yup.array().of(
      Yup.object().shape({
        sets_id: Yup.string(),
        quantity: Yup.number()
          .nullable()
          .min(0.1, t.common.quantity_must_be_greater_than_zero) // Add condition for quantity
          .test(
            "is-valid-quantity",
            t.common.valid_digits, // Error message for invalid quantity
            (value) => {
              // Check if quantity matches the pattern
              if (!value) return true; // If quantity is empty, return true
              const regex = /^\d{1,8}(\.\d{1,4})?$/;
              return regex.test(value.toString());
            }
          )
          .required(t.common.required_field),
        totalCost: Yup.string(),
      })
    ),
  });

  ////////////////////////////////////////
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };
  const { DisplayFormattedText } = useDisplayFormattedText();

  const [addPartModal, setAddPartModal] = useState(false);
  const [noPartModal, setNoPartModal] = useState(false);
  const [searchTitle, setSearchTitle] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(20);
  const [addSetModal, setAddSetModal] = useState(false);
  const [addSetModalTitle, setAddSetModalTitle] = useState(false);
  const [searchSetTitle, setSearchSetTitle] = useState("");

  let _newData = initPartsData;
  useEffect(() => {
    if (searchTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      _newData =
        searchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
    }
    if (_newData?.length > 0) {
      setPartsData(_newData);
    } else {
      setPartsData([]);
    }
  }, [searchTitle]);

  let _newDataSets = initSetsData;
  useEffect(() => {
    if (searchSetTitle.length > 0) {
      const re = new RegExp(_.escapeRegExp(searchSetTitle), "i");
      const isMatch = (result) => re.test(result?.name);
      _newDataSets =
        searchSetTitle.length > 0
          ? _.filter(_newDataSets, isMatch)
          : _newDataSets;
    }
    if (_newDataSets?.length > 0) {
      setSetsData(_newDataSets);
    } else {
      setSetsData([]);
    }
  }, [searchSetTitle]);

  /*
   * The `addParts` function in JavaScript fetches parts data, filters out selected parts, and updates the state accordingly and open add part modal.
   */
  const addParts = async (selectedPartsData = [], locations) => {
    setInitChange(true);
    // if (partsData?.length <= 0) {
    setLoading(true);
    const fetchData = await getAllParts(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      [],
      [locations],
      [],
      [],
      [],
      "",
      [],
      role
      // locations,
    );

    if (fetchData?.response) {
      if (fetchData?.response?.length == 0) {
        setLoading(false);
        setNoPartModal(true);
      } else {
        const filteredParts = fetchData?.response?.flatMap((part) =>
          part?.attributes?.inventory?.length > 0
            ? part.attributes.inventory
                .filter((inv) => inv?.location?.data?.id == locations)
                .map((inv) => ({
                  id: part.id,
                  name: part.attributes.name,
                  totalCost:
                    inv?.unitCost !== null
                      ? handleDecimalPoints(inv?.unitCost)
                      : 0,
                  quantity: 1,
                  unitCost:
                    inv?.unitCost !== null
                      ? handleDecimalPoints(inv?.unitCost)
                      : 0,
                  unitType: part?.attributes?.measuringUnit,
                  selectedPart: false,
                }))
            : []
        );

        const filteredPartsData = filteredParts?.map((part) => {
          let selectedPart = selectedPartsData.find((el) => el?.id == part?.id);

          if (selectedPart) {
            return null;
          } else {
            return {
              selectedPart: false,
              id: part.id,
              name: part.name,
              totalCost: part?.totalCost,
              unitType: part?.unitType,
              quantity: 1,
              unitCost: part.unitCost,
            };
          }
        }).filter((part) => part !== null);;

        console.log(filteredPartsData,"spdjsjsjsjsjs")
        //   setPartsData(filteredParts);
        //   setInitPartsData(filteredParts);
        //   setLoading(false);

        setPartsData(filteredPartsData);
        setInitPartsData(filteredPartsData);
        setAllPartsData(filteredPartsData);
        setLoading(false);
        setAddPartModal(true);
      }
    } else {
      toast.error(t.common.something_wrong);
      setLoading(false);
      setAddPartModal(true);
    }
    setLoading(false);

    // }
    // else {
    //   setLoading(true);
    //   setAddPartModal(true);

    //   const filteredParts = allPartsData
    //     ?.map((part) => {
    //       const isSelected = selectedPartsData.some(
    //         (selectedPart) => selectedPart.id === part.id
    //       );

    //       if (isSelected) {
    //         return null; // Skip this part
    //       } else {
    //         return {
    //           selectedPart: false,
    //           id: part.id,
    //           name: part.name,
    //           totalCost: handleDecimalPoints(part.totalCost),
    //           unitType: part.unitType,
    //           quantity: 1,
    //           unitCost: handleDecimalPoints(part.unitCost),
    //         };
    //       }
    //     })
    //     .filter((part) => part !== null); // Remove the null entries

    //   setPartsData(filteredParts);
    //   setInitPartsData(filteredParts);
    //   setLoading(false);
    // }
    // else {
    //   setLoading(true);
    //   setAddPartModal(true);
    //   const filteredParts = allPartsData?.map((part) => {
    //     let selectedPart = selectedPartsData.find((el) => el?.id == part?.id);

    //     if(selectedPart){
    //       return null
    //     }else{
    //       return {
    //         selectedPart: false,
    //         id: part.id,
    //         name: part.name,
    //         totalCost: part.totalCost,
    //         unitType: part.unitType,
    //         quantity: 1,
    //         unitCost: part.unitCost,
    //       };
    //     }

    //   });
    //   setPartsData(filteredParts);
    //   setInitPartsData(filteredParts);
    //   setLoading(false);
    // }
  };
  /*
   * The `addParts` function in JavaScript fetches sets data, filters out selected sets, and updates the state accordingly and open add set modal.
   */
  const addSets = async (selectedSetsData = [], locations) => {
    setInitChange(true);
    // if (setsData?.length <= 0) {
    setLoading(true);
    const fetchData = await getAllActiveSets(organisationId, locations);
    console.log("fetchData: ", fetchData);

    if (fetchData) {
      if (fetchData?.length == 0) {
        setLoading(false);
        setNoPartModal(true);
        setAddSetModalTitle(true);
      } else {
        const filteredSets = fetchData?.map((set) => ({
          id: set.id,
          name: set.name,
          totalCost: handleDecimalPoints(set?.totalCost),
          quantity: 1,
          unitCost: handleDecimalPoints(set.totalCost),
          selectedSet: false,
          disable: set?.includeSet,
        }));
        setSetsData(filteredSets);
        setInitSetsData(filteredSets);
        setAllSetsData(filteredSets);
        setLoading(false);
        setAddSetModal(true);
        setAddSetModalTitle(true);
      }
    } else {
      toast.error(t.common.something_wrong);
      setLoading(false);
      setAddSetModal(true);
      setAddSetModalTitle(true);
    }
    setLoading(false);
    // }
    // else {
    //   setLoading(true);

    //   const filteredSets = allSetsData
    //     ?.map((part) => {
    //       const isSelected = selectedSetsData.some(
    //         (selectedPart) => selectedPart.id === part.id
    //       );

    //       if (isSelected) {
    //         return null; // Skip this part
    //       } else {
    //         return {
    //           selectedSet: false,
    //           disable: part?.disable,
    //           id: part.id,
    //           name: part.name,
    //           totalCost: handleDecimalPoints(part.totalCost),
    //           quantity: 1,
    //           unitCost: handleDecimalPoints(part.unitCost),
    //         };
    //       }
    //     })
    //     .filter((part) => part !== null); // Remove the null entries
    //   setSetsData(filteredSets);
    //   setInitSetsData(filteredSets);
    //   setLoading(false);
    //   setAddSetModal(true);
    //   setAddSetModalTitle(true);
    // }
  };

  /**
   * The function `handleAddPartData` closes a modal and add selected parts data.
   */
  const handleAddPartData = async (setFieldValue) => {
    setAddPartModal(false);
    setPrevPartsData([]);
    setSearchTitle("");
    setFieldValue("parts", selectedTablePartData);
  };
  /**
   * The function `handleAddSetData` closes a modal and add selected sets data.
   */
  const handleAddSetData = async (setFieldValue) => {
    setAddSetModal(false);
    setPrevSetsData([]);
    setSearchSetTitle("");
    setFieldValue("sets", selectedTableSetData);
    setAddSetModalTitle(false);
  };

  /*
   * The function `handleRowSelected` manages the selection of rows in a table, updating the data accordingly and moving selected rows to the top.
   */
  const handleRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedPartsData = []
  ) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.unshift({ ...row, selectedPart: true });
      selectedRows.unshift({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData];
    const updatedInitData = [...initPartsData];

    const selectedRowIds = allSelectedRows.map((row) => row.id);

    updatedData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedPart = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedPart = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });

    updatedInitData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedPart = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedPart = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedIndex !== -1 && selectedIndex !== index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedPartIndex !== -1) {
        updatedData[selectedPartIndex].selectedPart = true; // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedPart = true; // Update selected
      }
    });

    // Update state with the modified data
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setPrevPartsData(selectedRows);

    // setPrevPartsData(selectedRows)
    // setFieldValue('parts', allSelectedRows);
  };

  /*
   * The function `handleClosePartModal` filters out parts that are not present in `newAddedData` and
   * updates the state variables accordingly.
   */
  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];
    let parts = [...selectedTablePartData];

    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddPartModal(false);
    setSearchTitle("");

    setSelectedTablePartData(parts);
  };
  /*
   * The function `handleSetRowSelected` manages the selection of rows in a table by updating the data
   * and moving selected rows to the top.
   */

  const handleSetRowSelected = (
    row,
    checked,
    setFieldValue,
    selectedSetsData = []
  ) => {
    let allSelectedRows = [...selectedSetsData];
    let selectedRows = [...prevSetsData];
    if (checked) {
      allSelectedRows.unshift({ ...row, selectedSet: true });
      selectedRows.unshift({ ...row, selectedSet: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...setsData];
    const updatedInitData = [...initSetsData];

    const selectedRowIds = allSelectedRows.map((row) => row.id);

    updatedData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedSet = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedSet = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });

    updatedInitData.forEach((item) => {
      if (selectedRowIds?.length === 0) {
        // If selectedRowIds is empty, set selectedPart to false for all items
        item.selectedSet = false;
      } else {
        // Otherwise, set selectedPart to true if the item is in selectedRowIds array
        item.selectedSet = selectedRowIds.includes(item.id);
      } // Set selectedPart to true if the item is in selectedRowIds array
    });
    // Move selected rows to the top
    selectedRowIds.forEach((selectedRowId, index) => {
      const selectedIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedIndex !== -1 && selectedIndex !== index) {
        const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
        updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
      }

      const selectedPartIndex = updatedData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedPartIndex !== -1) {
        updatedData[selectedPartIndex].selectedSet = true; // Update selected
      }

      const selectedInitIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
        const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
        updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
      }
      const selectedInitPartIndex = updatedInitData.findIndex(
        (item) => item.id === selectedRowId
      );
      if (selectedInitPartIndex !== -1) {
        updatedInitData[selectedInitPartIndex].selectedSet = true; // Update selected
      }
    });

    // Update state with the modified data
    setSetsData(updatedData);
    setInitSetsData(updatedInitData);
    setSelectedTableSetData(allSelectedRows);
    setPrevSetsData(selectedRows);
  };

  /*
   * The function `handleCloseSetModal` removes selected parts from a list and closes a modal.
   */
  const handleCloseSetModal = () => {
    let newAddedData = [...prevSetsData];
    let parts = [...selectedTableSetData];

    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddSetModal(false);
    setSearchSetTitle("");

    setSelectedTableSetData(parts);
  };
  /*
   * The function `removeFromSelectedParts` removes an item from an array of selected parts data at a
   * specified index and updates the state accordingly.
   */

  const removeFromSelectedParts = (
    index,
    selectedPartsData = [],
    setFieldValue = () => {}
  ) => {
    const updatedSelectedPartsData = [...selectedPartsData];
    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData.splice(index, 1); // Remove the item at the specified index
    setFieldValue("parts", updatedSelectedPartsData);
    handleRowSelected(null, false, setFieldValue, updatedSelectedPartsData); // Update the state
  };

  /*
   * The function `removeFromSelectedSets` removes an item from an array of selected sets data and
   * updates the state accordingly.
   */
  const removeFromSelectedSets = (
    index,
    selectedSetsData = [],
    setFieldValue = () => {}
  ) => {
    const updatedSelectedSetsData = [...selectedSetsData];
    updatedSelectedSetsData[index].selectedSet = false;
    updatedSelectedSetsData.splice(index, 1); // Remove the item at the specified index
    setFieldValue("sets", updatedSelectedSetsData);
    handleSetRowSelected(null, false, setFieldValue, updatedSelectedSetsData); // Update the state
  };

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions?.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  return (
    <>
      <Formik
        initialValues={initialFormValues}
        enableReinitialize={true}
        validationSchema={PersonValidationSchema}
        onSubmit={(values, { resetForm }) => {
          let data = handleAddSet(values, resetForm);
        }}
      >
        {({
          errors,
          touched,
          setFieldValue,
          values,
          setFieldTouched,
          handleBlur,
          submitForm,
          resetForm,
        }) => (
          <FormikForm>
            <div
              className="body p-6"
              style={{
                height: "90vh",
                overflowX: "hidden",
                overflowY: "scroll",
              }}
            >
              <Form.Field>
                <label className="label-item">
                  {t.categories.table.name}

                  <Asterisk color="#172B4D" />
                </label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.sets.form.add_name}
                  value={values?.name}
                  onChange={(e, value) => setFieldValue("name", e.target.value)}
                  error={errors.name && touched.name}
                  onFocus={(e) => {
                    setInitChange(true);
                    setFieldTouched("name", true);
                  }}
                />
                {errors.name && touched.name ? (
                  <div className="label-error">{errors.name}</div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.parts.form.inv_loc}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.parts.form.add_inv_loc}
                  noResultsMessage={t.common.no_results_found}
                  fluid
                  search
                  selection
                  // multiple
                  className="loc-dropdown"
                  options={dropdownOptions?.locations}
                  value={values?.locations}
                  onChange={(e, value) => {
                    setFieldValue("locations", value.value);
                    setFieldValue(`parts`, [], false);
                    setFieldValue(`sets`, [], false);
                    setSelectedTablePartData([]);
                    setSelectedTableSetData([]);
                  }}
                  onOpen={() => fetchLocations()}
                  error={errors.locations && touched.locations}
                  onBlur={(e) => {
                    setFieldTouched("locations", true);
                  }}
                />
                {errors.locations && touched.locations ? (
                  <div className="label-error">{errors.locations}</div>
                ) : (
                  ""
                )}
              </Form.Field>
              <label style={{ fontWeight: "500" }} className="label-item">
                {t.parts.tab.first}
              </label>

              {values?.parts?.length > 0 ? (
                <>
                  <FieldArray
                    name="parts"
                    render={(arrayHelpers) => (
                      <div>
                        <div className={"mt-6"}>
                          <div className={`kanbar-header`}>
                            <div class="grid-container-element set">
                              <div class="grid-child-element set">
                                {t.parts.table.name}
                              </div>
                              <div class="grid-child-element set">
                                {t.sets.form.qty}
                              </div>
                              <div class="grid-child-element set">
                                {t.parts.form.total_cost}
                              </div>
                              <div class="grid-child-element set"></div>
                            </div>
                          </div>
                        </div>
                        {values.parts.map((part, index) => (
                          <div
                            className="mt-1 grid-container-element set form"
                            key={index}
                          >
                            {/** both these conventions do the same */}
                            <div
                              className="grid-child-element set form "
                              style={{
                                padding: "14px 9px",
                              }}
                            >
                              <span className="text-center">
                                {DisplayFormattedText(part?.name, 18)}
                              </span>
                            </div>
                            <div
                              className="grid-child-element set form"
                              // style={{
                              //   minWidth: '62px',
                              // }}
                            >
                              <Input
                                autoComplete="new-password"
                                style={{ width: "52px" }}
                                className="d-flex"
                                type="number"
                                onKeyDown={(evt) => handleKeyDown(evt)}
                                value={part?.quantity}
                                onChange={(e) => {
                                  if (Number(e.target.value)) {
                                    let value = parseFloat(
                                      e.target.value
                                    ).toFixed(4);
                                    let total_cost = handleDecimalPoints(
                                      e.target.value * part.unitCost
                                    );
                                    setFieldValue(
                                      `parts[${index}].quantity`,
                                      Number(value)
                                    );
                                    setFieldValue(
                                      `parts[${index}].totalCost`,
                                      Number(total_cost),
                                      false
                                    );
                                  } else {
                                    setFieldValue(
                                      `parts[${index}].quantity`,
                                      ""
                                    );
                                    setFieldValue(
                                      `parts[${index}].totalCost`,
                                      part.unitCost
                                    );
                                  }
                                }}
                                error={
                                  errors &&
                                  errors?.parts &&
                                  errors?.parts?.length > 0 &&
                                  errors?.parts[index] &&
                                  errors?.parts[index]?.quantity
                                  // &&
                                  // touched?.parts[index]?.quantity
                                }
                                onFocus={(e) => {
                                  setFieldTouched(
                                    `parts[${index}].quantity`,
                                    true
                                  );
                                }}
                              >
                                <input />

                                <span
                                  className={` align-items-center justify-content-center ${
                                    selectedLanguage == "en" ? "ml-2" : "mr-2"
                                  }`}
                                  style={{
                                    backgroundColor: "#E9F2FF",
                                    color: "#0C66E4",
                                    padding: "9px 3px 9px 2px",
                                    fontSize: "14px",
                                  }}
                                >
                                  {HandleUnitType(part?.unitType)}
                                </span>
                              </Input>
                              {errors &&
                              errors?.parts &&
                              errors?.parts?.length > 0 &&
                              errors?.parts[index]?.quantity ? (
                                // touched?.parts[index]?.quantity
                                <span className="label-error er-2">
                                  {errors?.parts[index]?.quantity}
                                </span>
                              ) : null}
                            </div>

                            <div
                              className={
                                selectedLanguage == "en"
                                  ? "grid-child-element set form mt-2 ml-4"
                                  : "grid-child-element set form mt-2 mr-4"
                              }
                              // style={{
                              //   fontSize: '16px',
                              //   lineHeight: '24px',
                              //   width: '99px',
                              //   minWidth: '99px',
                              //   display: 'inline-block',
                              // }}
                            >
                              <span className="number-cost-set mt-2">
                                {DisplayFormattedText(
                                  String(part?.totalCost),
                                  7
                                )}
                              </span>
                              <span
                                className={` align-items-center justify-content-center ${
                                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  padding: "9px 3px 9px 2px",
                                  fontSize: "14px",
                                }}
                              >
                                {organisationCurrency}
                              </span>
                            </div>

                            <div
                              className="grid-child-element set form mt-2"
                              onClick={() => {
                                arrayHelpers.remove(index);
                                removeFromSelectedParts(
                                  index,
                                  values.parts,
                                  setFieldValue
                                );
                              }}
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </>
              ) : null}

              <button
                className={`buttun-type-link mt-3`}
                basic
                type="button"
                style={{ background: "transparent" }}
                disabled={!values?.locations}
                onClick={() =>
                  addParts(selectedTablePartData, values?.locations)
                }
              >
                <span className="customer-add-btn">
                  {" "}
                  <CustomerPlusIcon />{" "}
                </span>

                <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  {t.sets.form.add_parts}
                </p>
              </button>

              <Divider />
              <label style={{ fontWeight: "500" }} className="label-item">
                {t.parts.tab.second}
              </label>

              {values?.sets?.length > 0 ? (
                <>
                  <FieldArray
                    name="sets"
                    render={(arrayHelpers) => (
                      <div>
                        <div className={"mt-6"}>
                          <div className={`kanbar-header`}>
                            <div class="grid-container-element set">
                              <div class="grid-child-element set">
                                {t.parts.table.name}
                              </div>
                              <div class="grid-child-element set">
                                {t.sets.form.qty}
                              </div>
                              <div class="grid-child-element set">
                                {t.parts.form.total_cost}
                              </div>
                              <div class="grid-child-element set"></div>
                            </div>
                          </div>
                        </div>
                        {values.sets.map((part, index) => (
                          <div
                            className="mt-1 grid-container-element set form"
                            key={index}
                          >
                            {/** both these conventions do the same */}
                            <div
                              className="grid-child-element set form "
                              style={{
                                padding: "14px 9px",
                              }}
                            >
                              <span className="text-center">
                                {DisplayFormattedText(part?.name, 18)}
                              </span>
                            </div>
                            <div className="grid-child-element set form">
                              <Input
                                autoComplete="new-password"
                                style={{ width: "99px" }}
                                className="d-flex"
                                type="number"
                                onKeyDown={(evt) => handleKeyDown(evt)}
                                value={part?.quantity}
                                onChange={(e) => {
                                  if (Number(e.target.value)) {
                                    let value = parseFloat(
                                      e.target.value
                                    ).toFixed(4);
                                    let total_cost = handleDecimalPoints(
                                      e.target.value * part.unitCost
                                    );
                                    setFieldValue(
                                      `sets[${index}].quantity`,
                                      Number(value)
                                    );
                                    setFieldValue(
                                      `sets[${index}].totalCost`,
                                      Number(total_cost),
                                      false
                                    );
                                  } else {
                                    setFieldValue(
                                      `sets[${index}].quantity`,
                                      ""
                                    );
                                    setFieldValue(
                                      `sets[${index}].totalCost`,
                                      part.unitCost
                                    );
                                  }
                                }}
                                error={
                                  errors &&
                                  errors?.sets &&
                                  errors?.sets?.length > 0 &&
                                  errors?.sets[index]?.quantity
                                  // touched?.sets[index]?.quantity
                                }
                                onFocus={(e) => {
                                  setFieldTouched(
                                    `sets[${index}].quantity`,
                                    true
                                  );
                                }}
                              >
                                <input />
                              </Input>
                              {errors &&
                              errors?.sets &&
                              errors?.sets?.length > 0 &&
                              errors?.sets[index]?.quantity ? (
                                // &&
                                // touched?.sets[index]?.quantity
                                <span className="label-error">
                                  {errors?.sets[index]?.quantity}
                                </span>
                              ) : null}
                            </div>
                            <div
                              className={
                                selectedLanguage == "en"
                                  ? "grid-child-element set form mt-3 ml-4"
                                  : "grid-child-element set form mt-3"
                              }
                              style={{
                                padding: " 0px 0px",
                              }}
                            >
                              <span className="number-cost-set mt-2">
                                {DisplayFormattedText(
                                  String(part?.totalCost),
                                  6
                                )}
                              </span>
                              <span
                                className={` align-items-center justify-content-center ${
                                  selectedLanguage == "en" ? "ml-2" : "mr-2"
                                }`}
                                style={{
                                  backgroundColor: "#E9F2FF",
                                  color: "#0C66E4",
                                  padding: "9px 3px 9px 2px",
                                  fontSize: "14px",
                                }}
                              >
                                {organisationCurrency}
                              </span>
                            </div>

                            <div
                              onClick={() => {
                                arrayHelpers.remove(index);
                                removeFromSelectedSets(
                                  index,
                                  values.sets,
                                  setFieldValue
                                );
                              }}
                              className="grid-child-element set form mt-2"
                            >
                              <DeleteIcon />
                            </div>
                          </div>
                        ))}
                      </div>
                    )}
                  />
                </>
              ) : null}
              <button
                className={`buttun-type-link mt-3`}
                basic
                type="button"
                disabled={!values?.locations}
                style={{ background: "transparent" }}
                onClick={() => addSets(selectedTableSetData, values?.locations)}
              >
                <span className="customer-add-btn">
                  {" "}
                  <CustomerPlusIcon />{" "}
                </span>

                <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  {t.sets.form.add_sets}
                </p>
              </button>

              {/* Location  */}

              <div className="form-button-container mt-10">
                <button
                  type="button"
                  // content={t.procedures.form.cancel_button}
                  // basic
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                  className="form-action-button"
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  type="submit"
                  // content={t.procedures.form.submit.add}
                  // primary
                  // onClick={createNewTeamHandler}
                  // loading={buttonStatus == 1}
                  className="form-action-button blue"
                >
                  {edit ? t.common.edit : t.common.add}
                </button>
              </div>

              {/* Add parts and add sets modal */}

              <Modal
                size="tiny"
                open={addPartModal}
                className="assets-modal part-list-modal"
                style={{ height: "730px" }}
                onClose={() => {
                  handleClosePartModal();
                }}
              >
                <div className="header-container">
                  <span className="assets-header">{t.sets.form.add_parts}</span>
                  <div>
                    <span
                      className="c-pointer text-right"
                      onClick={() => {
                        handleClosePartModal();
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>

                <Modal.Content className="mt-2">
                  <div className="set-input">
                    <span className="search-svg-set">
                      <SearchIcon />
                    </span>
                    <Input
                      style={{ width: "470px" }}
                      placeholder={t.sets.form.search_part}
                      value={searchTitle}
                      onChange={(e) => setSearchTitle(e.target.value)}
                    />
                  </div>
                  {loading ? (
                    <Dimmer active inverted>
                      <Loader inverted content={t.common.loading} />
                    </Dimmer>
                  ) : (
                    <>
                      <div className="parts-listing-container mt-5  bulk-upload-table">
                        <DataTable
                          columns={[
                            {
                              width: "20px",
                              cell: (row) => (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Checkbox
                                      checked={row?.selectedPart}
                                      // defaultChecked={row?.selectedPart}
                                      onChange={(e, value) => {
                                        handleRowSelected(
                                          row,
                                          value.checked,
                                          setFieldValue,
                                          selectedTablePartData
                                        );
                                      }}
                                    />
                                  </div>
                                </div>
                              ),
                            },
                            {
                              minWidth: "350px",
                              //   name: t.categories.table.name,
                              sortField: "name",
                              sortable: true,
                              cell: (row) => (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <span className="id-text-style">
                                      {row?.name ? row?.name : ""}
                                    </span>
                                  </div>
                                </div>
                              ),
                            },
                          ]}
                          data={getPaginatedData(partsData)}
                          noHeader={true}
                          noTableHead={true}
                          onRowClicked={(row) =>
                            handleRowSelected(
                              row,
                              row?.selectedPart ? false : true,
                              setFieldValue,
                              selectedTablePartData
                            )
                          }
                          noDataComponent={<span>{t.sets.no_data}</span>}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <Pagination
                          ofString={t.common.of}
                          total={partsData.length}
                          prevItem={
                            selectedLanguage === "en" ? (
                              <ArrowLeft />
                            ) : (
                              <ArrowRight />
                            )
                          }
                          nextItem={
                            selectedLanguage === "en" ? (
                              <ArrowRight />
                            ) : (
                              <ArrowLeft />
                            )
                          }
                          page={currentPage}
                          limit={pageSize}
                          handleNext={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                          handlePrevious={() => {
                            setCurrentPage(currentPage - 1);
                          }}
                          parts={true}
                        />
                      </div>
                    </>
                  )}
                </Modal.Content>
                {!loading && (
                  <div
                    className={`modal-button-container ${
                      selectedLanguage == "en" ? "mr-8" : "ml-10"
                    }`}
                  >
                    <button
                      className="modal-form-action-button black"
                      onClick={() => {
                        handleClosePartModal();
                      }}
                    >
                      {t.assets.form.cancel}
                    </button>

                    <button
                      onClick={() => handleAddPartData(setFieldValue)}
                      className="modal-form-action-button light-blue"
                    >
                      {t.common.add}
                    </button>
                  </div>
                )}
              </Modal>

              <Modal
                size="tiny"
                open={addSetModal}
                className="assets-modal part-list-modal2"
                style={{ height: "730px" }}
                onClose={() => {
                  handleCloseSetModal();
                }}
              >
                <div className="header-container">
                  <span className="assets-header">{t.sets.form.add_sets}</span>
                  <div>
                    <span
                      className="c-pointer text-right"
                      onClick={() => {
                        handleCloseSetModal();
                      }}
                    >
                      <CloseIcon />
                    </span>
                  </div>
                </div>

                <Modal.Content className="mt-2">
                  <div className="set-input">
                    <span className="search-svg-set">
                      <SearchIcon />
                    </span>
                    <Input
                      style={{ width: "470px" }}
                      placeholder={t.sets.search_sets}
                      value={searchSetTitle}
                      onChange={(e) => setSearchSetTitle(e.target.value)}
                    />
                  </div>
                  {loading ? (
                    <Dimmer active inverted>
                      <Loader inverted content={t.common.loading} />
                    </Dimmer>
                  ) : (
                    <>
                      <div className="parts-listing-container mt-5  bulk-upload-table">
                        <DataTable
                          columns={[
                            {
                              width: "20px",
                              sortField: "name",
                              cell: (row) => (
                                <div className="d-flex align-items-center">
                                  <div>
                                    <Checkbox
                                      checked={row?.selectedSet}
                                      disabled={row?.disable}
                                      className="set-checkbox-gery"
                                      // defaultChecked={row?.selectedSet}
                                      onChange={(e, value) => {
                                        if (!row?.disable) {
                                          handleSetRowSelected(
                                            row,
                                            value.checked,
                                            setFieldValue,
                                            selectedTableSetData
                                          );
                                        }
                                      }}
                                    />
                                  </div>
                                </div>
                              ),
                            },
                            {
                              minWidth: "350px",
                              sortField: "name",
                              sortable: true,
                              cell: (row) => (
                                <div
                                  onClick={() => {
                                    if (!row?.disable) {
                                      handleSetRowSelected(
                                        row,
                                        row?.selectedSet ? false : true,
                                        setFieldValue,
                                        selectedTableSetData
                                      );
                                    }
                                  }}
                                  className="d-flex align-items-center justify-content-between widt-avai-part"
                                  style={
                                    row?.disable
                                      ? { width: "407px" }
                                      : { width: "407px", marginTop: "-2px" }
                                  }
                                >
                                  <h3
                                    style={
                                      row?.disable
                                        ? {
                                            fontSize: "14px",
                                            color: "#172B4D",
                                            marginTop: "7px",
                                          }
                                        : { fontSize: "14px", color: "#172B4D" }
                                    }
                                    className=""
                                  >
                                    {row?.name ? row?.name : ""}
                                  </h3>
                                  {row?.disable ? (
                                    <div
                                      style={
                                        selectedLanguage == "en"
                                          ? { minWidth: "142px" }
                                          : { minWidth: "100px" }
                                      }
                                      className={"disable-txt-part"}
                                    >
                                      {t.sets.form.already}
                                    </div>
                                  ) : null}
                                </div>
                                // <div className="d-flex align-items-center">
                                //   <div className='d-flex justifu'>
                                //     <span className="id-text-style">
                                //       {row?.name ? row?.name : ''}
                                //     </span>
                                //   </div>
                                // </div>
                              ),
                            },
                          ]}
                          data={getPaginatedData(setsData)}
                          noHeader={true}
                          noTableHead={true}
                          onRowClicked={(row) => {
                            if (!row?.disable) {
                              handleSetRowSelected(
                                row,
                                row?.selectedSet ? false : true,
                                setFieldValue,
                                selectedTableSetData
                              );
                            }
                          }}
                          noDataComponent={<span>{t.sets.no_data}</span>}
                        />
                      </div>

                      <div className="d-flex justify-content-end">
                        <Pagination
                          ofString={t.common.of}
                          total={setsData.length}
                          prevItem={
                            selectedLanguage === "en" ? (
                              <ArrowLeft />
                            ) : (
                              <ArrowRight />
                            )
                          }
                          nextItem={
                            selectedLanguage === "en" ? (
                              <ArrowRight />
                            ) : (
                              <ArrowLeft />
                            )
                          }
                          page={currentPage}
                          limit={pageSize}
                          handleNext={() => {
                            setCurrentPage(currentPage + 1);
                          }}
                          handlePrevious={() => {
                            setCurrentPage(currentPage - 1);
                          }}
                          parts={true}
                        />
                      </div>
                    </>
                  )}
                </Modal.Content>
                {!loading && (
                  <div
                    className={`modal-button-container ${
                      selectedLanguage == "en" ? "mr-8" : "ml-10"
                    }`}
                  >
                    <button
                      className="modal-form-action-button black"
                      onClick={() => {
                        handleCloseSetModal();
                      }}
                    >
                      {t.assets.form.cancel}
                    </button>

                    <button
                      onClick={() => handleAddSetData(setFieldValue)}
                      className="modal-form-action-button light-blue"
                    >
                      {t.common.add}
                    </button>
                  </div>
                )}
              </Modal>
            </div>{" "}
          </FormikForm>
        )}
      </Formik>
      {/* open modal if no part or set added*/}
      <Modal
        size="tiny"
        className="assets-modal"
        open={noPartModal}
        onClose={() => {
          setNoPartModal(false);
          setAddSetModalTitle(false);

          // setToggleStatus(true);
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {addSetModalTitle ? t.sets.add_set : t.parts.create_part}
          </span>

          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                setNoPartModal(false);
                setAddSetModalTitle(false);
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>
        <Modal.Content>
          <span className="assets-label ml-1">
            {addSetModalTitle ? t.sets.no_set : t.sets.no_part}
          </span>

          {/* {selectedPeopleWorkOrder.length ? (
                <div className="d-flex mt-7">
                  <p className="status-modal-popup-message mt-3">
                    {t.people.security_form.re_assign_to}:{' '}
                  </p>
                  <Form.Field style={{ minHeight: '60px' }}>
                    <Dropdown
                      style={{ width: '250px' }}
                      placeholder={t.people.security_form.select_one}
                      noResultsMessage={t.common.no_results_found}
                      selection
                      className="ml-4"
                      fluid
                      maxHeight={50}
                      upward
                      options={activeUsersList}
                      value={selectedUserToReAssign}
                      onChange={(e, value) =>
                        setSelectedUserToReAssign(value.value)
                      }
                    />
                  </Form.Field>
                </div>
              ) : null} */}
        </Modal.Content>

        <div
          className={`modal-button-container mt-3 ${
            selectedLanguage == "en" ? "mr-6" : "ml-10"
          }`}
        >
          <button
            onClick={() => {
              setNoPartModal(false);
              setAddSetModalTitle(false);
            }}
            style={{ color: "black" }}
            className="modal-form-action-button black"
          >
            {t.assets.form.cancel}
          </button>
        </div>
      </Modal>
    </>
  );
};

export default SetForm;
