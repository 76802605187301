export default {
  title: 'الممتلكات',
  title_small: 'الممتلكات',
  edit_asset: 'تعديل ممتلك',
  asset_name_data: 'اسم الممتلك',
  message: 'الرسائل',
  add: 'إضافة ممتلك',
  asset: 'ممتلك',
  deactivate: 'تعطيل',
  activate: 'تفعيل',
  deactivate_asset: 'تعطيل الممتلك',
  imported_asset: 'الممتلكات المحملة',
  imported_part: 'القطع المحملة',
  activate_asset: 'تفعيل الممتلك',
  dispose: 'اتلاف',
  disposed_asset: 'متلف',
  filter: {
    searchByName: 'البحث عن طريق إسم الممتلك',
    searchBySerialNumber: 'البحث عن طريق الرقم التسلسلي',
    area: 'المجال',
    model: 'النموذج',
    wed: 'تاريخ انتهاء الضمان',
    vendors: 'المورد',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    qrcode: 'رمز الاستجابة السريعة',
    barcode: 'الباركود',
    qr_barcode: 'QR Code/ Bar Code ',
  },
  disposed: 'اتلاف ممتلك',
  add_reason: 'أضف سبب الإتلاف',
  added_cost: 'التكلفة المضافة',
  added_cost_placeholder: 'أضف التكلفة',
  asset_info: 'معلومات الممتلك',
  table: {
    sheet_name: 'Asset Name*',
    name: 'الإسم',
    ID: 'الرمز',
    serialNumber: 'الرقم التسلسلي',
    sheet_serialNumber: 'Serial Number *',
    area: 'المجال',
    model: 'النوع',
    barcode: 'الباركود',
    vendor: 'البائع',
    wed: 'تاريخ انتهاء الضمان',
    createdByUser: 'انشأ من قبل',
    createdAt: 'تاريخ الإنشاء',
    updatedAt: 'تم التحديث في',
    status: 'الحالة',
  },
  contains: 'يتضمن',
  search: 'بحث',
  reset: 'إعادة ضبط',
  attach_qr_code: 'إرفق رمز الاستجابة السريعة',
  warranty_options: {
    any_date: 'أي تاريخ',
    today: 'اليوم',
    tomorrow: 'غدا',
    next_7_days: 'الأيام السبعة التالية',
    this_month: 'هذا الشهر',
    this_year: 'هذا العام',
    expired: 'منتهي الصلاحية',
  },
  created_at_options: {
    any_date: 'أي تاريخ',
    today: 'اليوم',
    this_month: 'هذا الشهر',
    this_year: 'هذا العام',
    last_7_days: '٧ ايام الماضية',
    last_month: 'الشهر الماضي',
    last_year: 'العام الماضي',
  },

  sheet: {
    sheet_name: 'اسم الممتلك*',
    sheet_description: 'الوصف',
    sheet_parent_asset: 'اسم الممتلك الرىْيسي',
    sheet_serialNumber: 'الرقم التسلسلي *',
    qr_barcode: 'رمز الاستجابة السريعة / رمز الباركود*',
    modal: 'النوع',
    category: 'الفئة*',
    location: 'اسم الموقع',
    area: 'المنطقة',
    customer: 'اسم العميل',
    vendor: 'اسم المورد',
    teams: 'الفرق',
    people: 'الأفراد',
    p_price: 'سعر الشراء',
    manufacturer: 'المصنّع',
    d_rate: 'معدل الاستهلاك (%)',
    e_life: 'العمر الإنتاجي المقدّر (سنة)',
    w_date: 'تاريخ انتهاء الكفالة',
    placed_date: 'وضع في الخدمة',
    add_date: 'معلومات اضافية',
  },


  sheet2: {
    sheet_name: 'Asset Name*',
    sheet_description: 'Description',
    sheet_parent_asset: 'Parent Asset Name',
    sheet_serialNumber: 'Serial Number *',
    qr_barcode: 'QR Code/ Bar Code*',
    modal: 'Model',
    category: 'Category*',
    location: 'Location Name',
    area: 'Area',
    customer: 'Customer Name',
    vendor: 'Vendor Name',
    teams: 'Teams',
    people: 'People',
    p_price: 'Purchase Price ',
    manufacturer: 'Manufacturer',
    d_rate: 'Depreciation Rate (%)',
    e_life: 'Estimate Useful Life (Yr)',
    w_date: 'Warranty Expiration Date',
    placed_date: 'Placed In Service Date',
    add_date: 'Additional Information',
  },

  work_order_status: {
    dispose: 'متلف',
    inactive: 'غير مفعّل',
    active: 'مفعّل',
  },
  select_people: 'اختر الافراد',
  select_vendor: 'اختر المورد',
  form: {
    activate_asset_statement1: 'هل ترغب في تفعيل الممتلك',
    deactivate_asset_statement1: 'هل ترغب في تعطيل الممتلك',
    child_asset: 'الممتلكات الفرعية',
    sheet_teams: 'Teams',
    add_asset: 'إضافة ممتلك',
    name: 'اسم',
    enter_asset_name: 'أضف الإسم',
    description: 'الوصف',
    describe_asset: 'أضف الوصف',
    serial_number: 'الرقم التسلسلي',
    enter_serial_number: 'أضف أو أنشىء الرقم التسلسلي',
    procedure: 'الإجراء ',
    select_procedure: 'اختر الإجراء ',
    model: 'نموذج',
    enter_asset_model: 'أضف النوع',
    area: 'المنطقة',
    enter_asset_area: 'اختر موقع الممتلك',
    category: 'الفئة',
    sheet_category: 'Category*',
    select_category: 'اختر الفئة',
    image: 'صورة الممتلك',

    vendor: 'المورد',
    sheet_vendor: 'Vendor Name',
    customer: 'العميل',
    sheet_customer: 'Customer Name',
    purchase_price: 'سعر الشراء',
    sheet_purchase_price: 'Purchase Price ',
    add_purchase_price: 'أضف سعر الشراء',
    manufacturer: 'المصنّع',
    add_manufacturer: 'أضف المصنعّ',
    add_depreciation_rate: 'أضف معدل الاستهلاك',
    depreciation_rate: 'معدل الاستهلاك',
    sheet_depreciation_rate: 'Depreciation Rate (%)',
    estimated_life: 'العمر الإنتاجي المقدّر',
    sheet_estimated_life: 'Estimate Useful Life (Yr)',
    add_estimated_life: 'أضف العمر الإنتاجي المقدّر',
    placed_in_service_date: 'وضع في الخدمة',
    sheet_placed_in_service_date: 'Placed In Service Date',
    add_warranty_expiration_date: 'أضف تاريخ انتهاء الكفالة',
    add_placed_in_service_date: 'أضف تاريخ الوضع في الخدمة',
    date_placeholder: 'يوم/شهر/سنة',
    warranty_expiration_date: 'تاريخ انتهاء الكفالة',
    additional_information: 'معلومات إضافية',
    add_additional_information: 'أضف معلومات إضافية',
    warranty_file: 'ملف الضمان',
    add: 'اضافة',
    allowed_type: 'الانواع المسموحة:',
    submit: 'تقديم',
    cancel: 'إلغاء',
    is_there_sub_location: 'هل هناك موقع فرعي؟',
    is_there_area: 'هل هناك منطقة؟',
    sub_locations: 'موقع فرعي',
    select_sub_locations: 'اختر مواقع فرعية',
    add_area: 'إضافة منطقة' ,
    select_category: 'اختر الفئة',
    select_vendor: 'اختر المورد',
    select_customer: 'اختر العميل',
    location: 'موقع',
    sheet_location: 'Location Name',

    select_location: 'اختر الموقع',
    asset_added_success: 'تمت إضافة الأصل بنجاح',
    delete_asset_statement: 'هل أنت متأكد أنك تريد حذف هذا الممتلك؟',
    update_asset_status_statement:
      'هل تريد إلغاء تنشيط الممتلكات الفرعية المرتبطة؟',
    update_asset_status_statement_active: 'هل أنت متأكد أنك تريد تفعيل الممتلك',
    delete_asset_success: 'تم حذف الممتلك بنجاح',
    related_asset_error: 'لا يمكن حذف هذا الممتلك',
    // related_asset_error:
    // 'لا يمكن حذف هذا الممتلك',
    related_status_asset_error: 'لا يمكن تعطيل هذا الممتلك',
    update_status_asset_success: 'تم تفعيل الممتلك بنجاح',
    update_status_asset_success_deactivate: 'تم تعطيل/ اتلاف الممتلك بنجاح',
    parts: 'القطع',
    current_customer: 'العميل الحالي',
    current_location: 'الموقع الحالي',
  },
  upload_part: 'تحميل قائمة القطع',
  bulk_part: 'تحميل قائمة القطع',
  import_part: 'استيراد القطع',
  part_temp: 'تحميل نموذج قائمة القطع',
  parts_temp_xls: 'نموذج القطع.xlsx',
  start_add_asset: 'ابدء في إضافة الممتلكات',
  no_barcode:
    'لا يوجد أي ملف للأصول مرتبط بهذا الرمز الاستجابة السريعة / الرمز الباركود',
  no_assets_available: 'يبدو أنك ليس لديك ممتلكات بعد',
  press_to_add_assets: "اضغط على زر '+' لإضافة أول ممتلك لديك",
  create_asset: 'إضافة ممتلك',
  asset_already_exist: 'الأصل موجود بالفعل بالاسم المحدد',
  part_error:"فشل التحميل: الملف غير مدعوم. يرجى تحميل نموذج قائمة الممتلكات الصحيح.",
  asset_create_success: 'تم إنشاء الممتلك بنجاح',
  upload_excel_asset_file: 'فشل التحميل: نوع الملف غير مدعوم. يرجى تحميل الملف بالصيغة الصحيحة (.xls, .xlsx).',
  asset_error:"فشل التحميل: الملف غير مدعوم. يرجى تحميل نموذج قائمة القطع الصحيح.",
  bulk_assets: 'تحميل قائمة الممتلكات',
  import_assets: 'استيراد الممتلكات',
  category_temp_xls: 'نموذج الفئة.xlsx',
  upload_assets: 'تحميل قائمة الممتلكات',
  browse_btn: 'تصفح',
  assets_temp: 'تحميل نموذج قائمة الممتلكات',
  assets_temp_xls: 'نموذج الممتلكات.xlsx',
  active: 'مُفعل',
  inActive: 'غير مُفعل',
  child_asset: 'هل هذا ممتلك فرعي',
  parent_asset: 'الممتلك الرئيسي',
  parent_asset_place: 'ختر الممتلك الرئيسي',
  hirachy_error_title: 'خطأ: تغيير التسلسل الهرمي للأصول غير مسموح به',
  hirachy_error_desc:
    'لا يمكن نقل الممتلك الذي قمت بتحديده إلى ممتلك فرعي لأنه يحتوي حاليًا على ممتلكات فرعية وثانوية مرتبطة به. يرجى مراجعة وتعديل التسلسل الهرمي قبل إجراء أي تغييرات.',
  review_hirachy: 'مراجعة التسلسل الهرمي',
  no_related_QR_msg: 'لا توجد أصول ذات صلة برمز الاستجابة السريعة هذا',
  how_to_add_assets: 'كيفية إضافة الممتلكات',
  warrenty_file: 'ملف الكفالة',
  attach_file: 'قم بإرفاق ملف لهذا الممتلك',
  asset_dispose_statement: 'معلومات اتلاف الممتلك',
  dispose_reason: 'سبب الاتلاف',
  serial_valid: 'أضف رقمًا تسلسليًا صحيحاً مكونًا من 10 أرقام',
  upload_file_valid: 'يمكنك تحميل الملفات بصيغة .xls أو .xlsx فقط',
  max_length_error:
    'يجب ألا تحتوي الورقة التي تم تحميلها على أكثر من 200 سجل.',
  success_import: 'نجاح التحميل',
  deactivate_label_parent:"ما هي الممتلكات التي ترغب في تعطيلها؟",
  activate_label_parent:"ما هي الممتلكات التي ترغب في تفعيلها؟",
  deactivate_label_option1:"هذا الممتلك فقط.",
  deactivate_label_option2:"هذا الممتلك والممتلكات المرتبطة به.",
  asset_bulk_upload_title:"تحميل قائمة الممتلكات",
  part_bulk_upload_title:"تحميل قائمة القطع",
  failed_import: 'فشل التحميل',
  serial_number_exist: 'الرقم التسلسلي موجود بالفعل.',
  add_cost_reason: 'يرجى إضافة السبب والتكلفة',
  completed_wo: 'اكتمل العمل على هذا الممتلك',

  data_error:"فشل التحميل: الملف فارغ. يرجى تحميل ملف يحتوي على بيانات صحيحة.",

  delete_area: 'لا يمكن حذف هذه المنطقة لأنها مرتبطة ببيانات أخرى في النظام.',
  delete_store: 'غير قادر على إلغاء تحديد المستودع. مرتبط بملف تعريف قطعة.',
  errors: {
    "The max. quantity must be greater than the min. quantity.":"يجب أن يكون الحد الأقصى للكمية أكبر من الحد الأدنى للكمية.",
    'The team entered does not exist in the system.': 'الفريق الذي تم إدخاله غير موجود في النظام.',
    "The people entered do not exist in the system.":"الأفراد الذين تم إدخالهم غير موجودين في النظام.",
    "The date format is incorrect.":"تنسيق التاريخ غير صحيح.",
    "No location was entered for the populated area.":"لم يتم إدخال أي موقع للمنطقة التي تم نشرها.",
    'Name is empty.': 'الاسم فارغ.',
    'Asset name attribute must be unique.': 'يجب أن يكون اسم الممتلك فريدًا.',
    'The parent asset entered does not exist.':
      'الممتلك الرئيسي المحدد غير موجود.',
    'The grand child cannot be set as a parent asset.':
      'لا يمكن تعيين الممتلك الثانوي كممتلك رئيسي.',
    'Serial number is empty.': 'الرقم التسلسلي فارغ.',
    'Serial number contain 1 digit as a minimum and 13 digits as a maximum.':
      'يجب أن يحتوي الرقم التسلسلي على رقم واحد كحد أدنى و 13 رقمًا كحد أقصى.',
    'Serial number attribute must be unique.':
      'يجب أن يكون الرقم التسلسلي فريدًا.',
    'Serial number contains only digits.':
      'يحتوي الرقم التسلسلي على أرقام فقط.',
      "Serial Numbers must contain alphanumeric data from 1 to 13 digits":
      "يجب أن يحتوي الرقم التسلسلي على بيانات حروف او أرقام مكونة من 1 إلى 13 رقمًا.",
    'No QR/Bar Code selected for this asset.':
      'لم يتم تحديد رمز الاستجابة السريعة/الباركود  لهذا الممتلك.',
    'No Category selected for this asset.': 'لم يتم تحديد أي فئة لهذا الممتلك.',
    'Undefined Error.': 'خطأ غير محدد.',

    'Serial number is not unique.': 'الرقم التسلسلي ليس فريدًا.',
    'The asset name is not unique.': 'اسم الممتلك ليس فريدًا.',
    'No QR/Bar Code selected for this asset.':
      'لم يتم تحديد رمز الاستجابة السريعة/الباركود لهذا الممتلك.',
    'The parent asset entered does not exist.':
      'الممتلك الأساسي الذي تم إدخاله غير موجود.',
    'The grandchild cannot be set as a parent asset.':
      'لا يمكن تعيين الممتلك الفرعي الكبير كممتلك أساسي.',
    'No category was entered.': 'لم يتم إدخال أي فئة.',
    'No location was entered for the populated sub-location.':
      'لم يتم إدخال أي موقع للموقع الفرعي الذي تم نشره.',
    'No location was entered for the populated area.':
      'لم يتم إدخال أي موقع للمنطقة التي تم نشرها.',
    'The people entered do not exist in the system.':
      'الأفراد الذين تم إدخالهم غير موجودين في النظام.',
    'The team entered does not exist in the system.':
      'الفريق الذي تم إدخاله غير موجود في النظام.',
    'The data format is incorrect.': 'تنسيق التاريخ غير صحيح.',
  },
};
