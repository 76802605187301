import { filter } from "lodash";
import React, { useEffect, useState, createRef } from "react";
import _ from "lodash";
import {
  Form,
  Table,
  Button,
  Header,
  Pagination,
  Label,
  Icon,
  Input,
  Dropdown,
  Grid,
  DropdownItem,
  DropdownMenu,
  Popup,
} from "semantic-ui-react";
import { NavLink } from "react-router-dom/cjs/react-router-dom.min";

import calendar_icon from "../../../Assets/Icons/calender-icon.svg";

import md5 from "md5";
import { toast } from "react-toastify";

import {
  getAllLocations,
  getUsersByRole,
  getAllTeams,
  humanize,
  toggle,
  IMAGES_URL,
  getAllJobTitles,
  getAllVendros,
} from "../../config/functions";

import moment from "moment";
import { useDispatch, useSelector } from "react-redux";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import { selectTranslations } from "../../config/i18n/slice";
import {
  ActionBtnIcon,
  AdminIcon,
  DownChevron,
  DownloadIcon,
  LimitedAdminIcon,
  PdfIcon,
  SearchIcon,
  ShareIcon,
  TechnicianIcon,
  UploadIcon,
  ViewerIcon,
  WhiteDownChevron,
} from "../../../Assets/Icons/svg";
import { DropdownToggle, UncontrolledDropdown } from "reactstrap";
import PriorityDropdown from "../../components/common/PriorityDropdown";
import WorkOrderSearchDropdown from "../../components/WorkOrder/WorkOrderSearchDropdown";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import DownloadExcelFileWithData from "../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../services/DownloadPdfFileWithData";
import {
  fetchAllPeopleData,
  fetchPeopleWithFilter,
} from "../../../redux/reducers/peopleReducer";
import {
  fetchAllPOData,
  fetchPOWithFilter,
} from "../../../redux/reducers/purchaseOrderReducer";
import ToolTip from "react-power-tooltip";
import DatePicker from "react-multi-date-picker";
import { nanoid } from "nanoid";

const Filters = ({
  data,
  setDataByFilter,
  fileData = [],
  filterOptions = [],
  setFilterOptions,
  setSearchTitle,
  debounceRate,
  searchTitle,
  debounceValue,
  setloader,
  loader,
  sortData,
  selectedDates,
  setSelectedDates,
  dueDates,
  setDueDates,
  role,
  allIds,
}) => {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const statusOptions = [
    {
      value: "requested",
      color: "yellow",
      label: t.po.status.requested,
    },
    {
      value: "approved",
      color: "green",
      label: t.po.status.approved,
    },
    {
      value: "declined",
      color: "red",
      label: t.po.status.declined,
    },
    {
      value: "fulfilled",
      color: "black",
      label: t.po.status.fulfilled,
    },
    {
      value: "partiallyfulfilled",
      color: "blue",
      label: t.po.status.partially_fulfilled,
    },
    {
      value: "cancelled",
      color: "darkgrey",
      label: t.po.status.cancelled,
    },
  ];

  const [showTooltip, setShowTooltip] = useState(false);

  const dispatch = useDispatch();

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,
      icon: <AdminIcon />,
      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,
      icon: <LimitedAdminIcon />,
      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,
      icon: <TechnicianIcon />,
      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,
      icon: <ViewerIcon />,
      description: t.people.add_modal.viewer_description,
    },
    {
      key: 2,
      value: 6,
      text: t.people.add_modal.requester,
      // icon: <ViewerIcon />,
      description: t.people.add_modal.requester_desc,
    },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];
  const [filtersTrigger, setFiltersTrigger] = useState({
    role: {
      options: [
        {
          key: 2,
          value: "",
          text: t.common.all,
        },
        {
          key: 0,
          value: 5,
          text: t.people.add_modal.admin,
        },

        {
          key: 6,
          value: 9,
          text: t.people.add_modal.limited_admin,
        },

        // {
        //   key: 89,
        //   value: 7,
        //   text: 'Supervisor',
        // },
        {
          key: 1,
          value: 8,
          text: t.people.add_modal.technician,
        },

        {
          key: 4,
          value: 3,
          text: t.people.add_modal.viewer,
        },

        // {
        //   key: 2,
        //   value: 6,
        //   text: 'Requester only',
        // },
        // {
        //   key: 3,
        //   value: 3,
        //   text: 'Viewer only',
        // },
      ],
    },
    supervisors: {
      initOptions: [],
      options: [],
    },
    vendors: {
      initOptions: [],
      options: [],
    },

    teams: {
      initOptions: [],
      options: [],
    },
    jobTitle: {
      initOptions: [],
      options: [],
    },

    status: {
      options: [
        {
          key: 0,
          text: t.common.all,
          value: "",
          color: "",
        },
        {
          key: 1,
          text: t.po.status.approved,
          value: "approved",
          color: "green",
        },
        {
          key: 2,
          value: "declined",
          color: "red",
          text: t.po.status.declined,
        },
        {
          key: 3,
          value: "requested",
          color: "yellow",
          text: t.po.status.requested,
        },

        {
          key: 4,
          value: "cancelled",
          color: "darkgrey",
          text: t.po.status.cancelled,
        },
        {
          key: 5,
          value: "fulfilled",
          color: "black",
          text: t.po.status.fulfilled,
        },
        {
          key: 6,
          value: "partiallyfulfilled",
          color: "blue",
          text: t.po.status.partially_fulfilled,
        },
      ],
    },
    joiningDate: {
      options: [
        { key: 0, text: "Any Date", value: "any_day" },
        { key: 1, text: "Today", value: "today" },
        { key: 2, text: "Tomorrow", value: "tomorrow" },
        { key: 3, text: "Next 7 Days", value: "next_7_days" },
        { key: 4, text: "Next 30 Days", value: "next_30_days" },
        { key: 5, text: "This Month", value: "this_month" },
        { key: 6, text: "This Quarter", value: "this_quarter" },
        { key: 7, text: "This Year", value: "this_year" },
        { key: 8, text: "Yesterday", value: "yesterday" },
        { key: 9, text: "Last Week", value: "last_week" },
        { key: 10, text: "Last Month", value: "last_month" },
        { key: 11, text: "Last Quarter", value: "last_quarter" },
        { key: 12, text: "Last Year", value: "last_year" },
        { key: 13, text: "Past Due", value: "past_due" },
        { key: 14, text: "Unscheduled", value: "unscheduled" },
      ],
    },
  });

  const [resetKey, setResetKey] = useState(nanoid());
  const [resetKeyEndDate, setResetKeyEndDate] = useState(nanoid());

  /*
   * The handleDeselect function clears the selected dates array and updates the reset key.
   */
  const handleDeselect = () => {
    // Deselect by setting an empty array
    setSelectedDates([]);
    setResetKey(nanoid());
    // setResetKeyEndDate((pre) => !pre);
  };

  const [filterSearch, setFilterSearch] = useState({
    supervisors: "",
    teams: "",
    vendors: "",
  });

  const [newData, setNewData] = useState(data);

  const [openRole, setOpenRole] = useState(false);
  const [openContractEnd, setOpenContractEnd] = useState(false);
  const [openSupervisor, setOpenSupervisor] = useState(false);
  const [openTeams, setOpenTeams] = useState(false);
  const [openStatus, setOpenStatus] = useState(false);
  const [openJoiningDate, setOpenJoiningDate] = useState(false);
  const [openRate, setOpenRate] = useState(false);

  /*
   * The function `closeAllFilters` closes all filter options by setting their respective states to false.
   */
  const closeAllFilters = () => {
    setOpenRole(false);
    setOpenSupervisor(false);
    setOpenTeams(false);
    setOpenStatus(false);
    setOpenJoiningDate(false);
    setOpenRate(false);
    setOpenContractEnd(false);
  };

  /* The above code is using the `useEffect` hook in React to fetch data asynchronously and populate
options for filtering vendors. It first calls the `getAllVendors` function to fetch vendor data,
then maps over the response to extract necessary information such as vendor name and logo URL. It
then constructs an array of options for vendors with keys, values, texts, and images. Finally, it
updates the state with the fetched vendor options for filtering. The code also includes
commented-out sections that suggest similar operations for fetching customer data, team data,
location data, and user data, but those sections are currently */
  useEffect(() => {
    (async function() {
      // const fetchData = await getAllCustomers(organisationId);

      let the_options_vendor = [];

      const fetchData = await getAllVendros(organisationId);
      // console.log();
      if (fetchData?.response) {
        fetchData.response.map((el) => {
          const the_image = el?.attributes?.logo?.data?.attributes?.url
            ? el?.attributes?.logo?.data?.attributes?.url
            : LetteredAvatarImageUrl(el.attributes.name);

          the_options_vendor.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: {
              avatar: true,
              src: the_image,
              style: { width: "20px", height: "20px" },
            },
          });
        });
      }

      setFiltersTrigger({
        ...filtersTrigger,

        vendors: {
          ...filtersTrigger.vendors,
          initOptions: the_options_vendor,
          options: the_options_vendor,
        },
      });

      // if (fetchData) {
      //   fetchData.forEach((el) => {
      //     the_options_customer.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: el.attributes.logo.data
      //           ? IMAGES_URL +
      //             el.attributes.logo.data.attributes.formats.thumbnail.url
      //           : LetteredAvatarImageUrl(el.attributes.name + ' '),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }

      // const fetchTeamsData = await getAllTeams(organisationId);

      // if (fetchTeamsData) {
      //   fetchTeamsData.map((el) => {
      //     the_options_teams.push({
      //       key: el.id,
      //       value: `${el.id}`,
      //       text: el.attributes.name,
      //       image: {
      //         avatar: true,
      //         src: LetteredAvatarImageUrl(`${el.attributes.name}`),
      //         style: { width: '16px', height: '16px' },
      //       },
      //     });
      //   });
      // }

      // getAllParentLocations,
      // const fetchDataLocation = await getAllParentLocations(organisationId);

      // if (fetchDataLocation) {
      //   fetchDataLocation.forEach((el) => {
      //     the_options_location.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: `${el.attributes.name}`,
      //     });
      //   });
      // }

      // const fetchDataPeople = await getUsersByRole(
      //   [
      //     'user',
      //     'supervisor',
      //     'view_only',
      //     'admin',
      //     'requester',
      //     'super_admin',
      //   ],
      //   organisationId
      // );

      // if (fetchDataPeople) {
      //   fetchDataPeople.map((el) => {
      //     the_options_user.push({
      //       key: `${el.id}`,
      //       value: `${el.id}`,
      //       text: el.firstName + ' ' + el.lastName,
      //       image: {
      //         avatar: true,
      //         src: el?.profileImage?.url
      //           ? IMAGES_URL + el?.profileImage?.url
      //           : LetteredAvatarImageUrl(el.firstName + ' ' + el.lastName),
      //         style: { width: '20px', height: '20px' },
      //       },
      //     });
      //   });
      // }
    })();
  }, []);

  /*
   * The handleChange function converts an array of dates into an array of ISO formatted date strings.
   */
  const handleChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setSelectedDates(isoDates);
    }
  };

  /*
   * The function `handleEndDateDeselect` deselects the end date by setting an empty array and resetting a key.
   */
  const handleEndDateDeselect = () => {
    // Deselect by setting an empty array
    setDueDates([]);
    setResetKeyEndDate(nanoid());
  };

  /*
   * The handleEndDateChange function converts an array of date values to ISO date strings and sets them as due dates.
   */
  const handleEndDateChange = (value) => {
    if (value && value.length) {
      const dateValues = value.map((date) => date.valueOf());

      const isoDates = dateValues.map((timestamp) =>
        timestamp ? new Date(timestamp).toISOString() : ""
      );
      setDueDates(isoDates);
    }
  };

  /* The above code is a React useEffect hook that is triggered whenever the `filterSearch.vendors` value
changes. It creates a regular expression pattern using the `_.escapeRegExp` function from lodash
library based on the value of `filterSearch.vendors`. It then defines a function `isMatch` that
tests if a given `result.text` matches the regular expression pattern. */
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.vendors), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      vendors: {
        ...filtersTrigger.vendors,
        options:
          filterSearch.vendors.length > 0
            ? _.filter(filtersTrigger.vendors.initOptions, isMatch)
            : filtersTrigger.vendors.initOptions,
      },
    });
  }, [filterSearch.vendors]);

  /* The above code is using the `useEffect` hook in React to update the `teams` filter options based on
the `filterSearch.teams` value. It creates a regular expression pattern using `_.escapeRegExp` to
match the search term case-insensitively. It then filters the initial options for the `teams` filter
based on whether they match the search term using the `isMatch` function. If the search term is
empty, it sets the filter options back to the initial options. This code is dynamically updating the
filter options based on the user's search input for teams. */
  useEffect(() => {
    const re = new RegExp(_.escapeRegExp(filterSearch.teams), "i");
    const isMatch = (result) => re.test(result.text);

    setFiltersTrigger({
      ...filtersTrigger,
      teams: {
        ...filtersTrigger.teams,
        options:
          filterSearch.teams.length > 0
            ? _.filter(filtersTrigger.teams.initOptions, isMatch)
            : filtersTrigger.teams.initOptions,
      },
    });
  }, [filterSearch.teams]);

  let _newData = newData;
  useEffect(() => {
    document.body.addEventListener("click", (e) => {
      var notInArray = ["contractEndPopup", "ratePopup"];
      if (!notInArray.includes(e.target.id)) closeAllFilters();
    });
    (async function() {
      setloader(true);

      await dispatch(
        fetchPOWithFilter({
          organisationId,
          searchName: debounceValue,
          page: "1",
          pageSize: "25",
          vendors: filterOptions.vendors,
          status: filterOptions.status,
          totalCost: filterOptions.totalCost,
          submissionDate: selectedDates,
          dueDate: dueDates,
          sort: sortData,
          role,
          allIds,
        })
      );

      setloader(false);
    })();
  }, [
    filterOptions.vendors,
    filterOptions.status,
    debounceRate,
    debounceValue,
    selectedDates,
    dueDates,
  ]);

  /*
   * The function `handleSearchSupervisorChange` updates the `vendors` property in the `filterSearch` state based on the value of the input element.
   */
  const handleSearchSupervisorChange = (e) => {
    setFilterSearch({
      ...filterSearch,
      vendors: e.target.value,
    });
  };

  /* The above code is defining an array of objects called `headers`. Each object in the array represents
a header for a table, with a `label` property for the header text and a `key` property for the
corresponding key in the data object. The `label` values are referencing translations from different
parts of the application using `t` object and specific keys like `assets.table.ID`,
`workOrders.table.title`, `po.table.po_number`, etc. These headers are likely used to display data
in a table with corresponding keys used to access the data for each column. */
  const headers = [
    { label: t.assets.table.ID, key: "id" },
    { label: t.workOrders.table.title, key: "title" },
    { label: t.po.table.po_number, key: "po_number" },
    { label: t.workOrders.table.status, key: "status" },
    { label: t.po.table.vendor, key: "vendor" },
    { label: t.po.table.no_items, key: "no_items" },
    { label: t.po.table.total_qty, key: "total_qty" },
    { label: t.po.table.total_cost, key: "total_cost" },
    { label: t.po.table.due_date, key: "due_date" },
    { label: t.parts.table.created_on, key: "created_on" },
    { label: t.procedures.table.createdByUser, key: "createdByUser" },
    { label: t.po.table.approved_by, key: "approved_by" },
  ];

  /*
   * The `downloadXLS` function fetches purchase order data and generates a CSV file with specific columns for download.
   */
  const downloadXLS = async () => {
    const rateHasValues = Object.values(filterOptions.totalCost).some(
      (value) => value !== null && value !== undefined && value !== ""
    );

    // Check if any array in filterOptions has length greater than 0
    const arraysNotEmpty = Object.values(filterOptions).some(
      (option) => Array.isArray(option) && option.length > 0
    );

    if (
      !rateHasValues &&
      !arraysNotEmpty &&
      searchTitle == "" &&
      selectedDates?.length == 0 &&
      dueDates?.length == 0
    ) {
      let mydata = await dispatch(
        fetchAllPOData({ organisationId, role, allIds })
      );

      if (mydata.payload.response) {
        fileData = mydata.payload.response;
      } else {
        toast.error(t.common.something_wrong);
      }
    }
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let po_data = [];

      po_data.id = fileData[i].id;
      po_data.title = fileData[i]?.attributes?.details?.title
        ? fileData[i]?.attributes?.details?.title
        : t.common.na;

      po_data.po_number = fileData[i]?.attributes?.details?.poNumber
        ? fileData[i]?.attributes?.details?.poNumber
        : t.common.na;

      let status = statusOptions.find(
        (status) => status.value === fileData[i]?.attributes?.status
      );

      po_data.po_status = status?.label || t.common.na;

      po_data.vendor = fileData[i]?.attributes?.details?.vendor?.data
        ?.attributes?.name
        ? fileData[i].attributes?.details?.vendor?.data?.attributes?.name
        : t.common.na;

      po_data.no_items = fileData[i]?.attributes?.totalItems
        ? fileData[i]?.attributes?.totalItems
        : "0";

      po_data.total_qty = fileData[i]?.attributes?.totalQty
        ? fileData[i]?.attributes?.totalQty
        : "0";
      po_data.total_cost = fileData[i]?.attributes?.orderItems?.totalCost
        ? fileData[i]?.attributes?.orderItems?.totalCost
        : "0";
      po_data.due_date = fileData[i]?.attributes?.details?.dueDate
        ? moment(fileData[i]?.attributes?.details?.dueDate).format(
            "DD.MM.YYYY LT"
          )
        : t.common.na;

      po_data.created_date = fileData[i]?.attributes?.createdAt
        ? moment(fileData[i].attributes?.createdAt).format("DD.MM.YYYY LT")
        : t.common.na;

      po_data.createdByUser = fileData[i]?.attributes?.createdByUser?.data
        ?.attributes?.firstName
        ? fileData[i]?.attributes?.createdByUser?.data?.attributes?.firstName +
          " " +
          fileData[i]?.attributes?.createdByUser?.data?.attributes?.lastName
        : t.common.na;
      po_data.approved_by = fileData[i]?.attributes?.approvedByUser?.data
        ?.attributes?.firstName
        ? fileData[i]?.attributes?.approvedByUser?.data?.attributes?.firstName +
          " " +
          fileData[i]?.attributes?.approvedByUser?.data?.attributes?.lastName
        : t.common.na;

      csvData.push([
        po_data.id,
        po_data.title,
        po_data.po_number,
        po_data.po_status,
        po_data.vendor,
        po_data.no_items,
        po_data.total_qty,
        po_data.total_cost,
        po_data.due_date,
        po_data.created_date,
        po_data.createdByUser,
        po_data.approved_by,
      ]);
    }

    let csvColumnWidths = [
      { wch: 5 }, // ID column
      { wch: 25 }, // Title column
      { wch: 25 }, // Description column
      { wch: 25 }, // Category column
      { wch: 25 }, // Procedure Items column
      { wch: 15 }, // Created By column
      { wch: 15 }, // Created At column
      { wch: 15 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 25 }, // Updated At column
      { wch: 30 }, // Updated At column

      { wch: 30 }, // Description column
    ];
    DownloadExcelFileWithData(headers, csvData, csvColumnWidths, t.po.title);
  };

  /*
   * The function `generatePDF` processes data from `fileData` to generate a PDF file with specific columns and formatting.
   */
  const generatePDF = () => {
    let csvData = [];

    for (let i = 0; i < fileData.length; i++) {
      let po_data = [];

      po_data.id = fileData[i].id;
      po_data.name =
        fileData[i]?.firstName && fileData[i]?.lastName
          ? fileData[i].firstName + fileData[i]?.lastName
          : t.common.na;

      po_data.mobile = fileData[i]?.userProfile?.phone
        ? `+${fileData[i]?.userProfile?.phone}`
        : t.common.na;

      po_data.jobtitle = fileData[i]?.userProfile?.job?.title
        ? fileData[i]?.userProfile?.job.title
        : t.common.na;

      let roleData = roles.find((each) => each.value == fileData[i].role.id);

      po_data.role = fileData[i]?.role.id
        ? roleData?.text || fileData[i]?.role?.type
        : t.common.na;

      let assigneePeople = fileData[i].teams;
      let assignString = "";

      if (assigneePeople?.length > 0) {
        assignString = assigneePeople.map((people) => people?.name).join(", ");
      } else {
        assignString = t.common.na;
      }
      po_data.teams = assignString;

      po_data.supervisor = fileData[i]?.supervisor?.firstName
        ? fileData[i]?.supervisor?.firstName +
          " " +
          fileData[i]?.supervisor?.lastName
        : t.common.na;

      po_data.hourRate = fileData[i].userProfile?.hourRate
        ? `${fileData[i].userProfile?.hourRate} ${organisationCurrency}`
        : t.common.na;
      po_data.status =
        fileData[i].confirmed && !fileData[i].blocked
          ? t.assets.active
          : fileData[i].blocked
          ? t.assets.inActive
          : t.people.status_options.status_pending;

      po_data.contract_expiry_date = fileData[i]?.userProfile?.contractEnd
        ? moment(fileData[i]?.userProfile?.contractEnd).format("DD.MM.YYYY")
        : t.common.na;

      csvData.push([
        po_data.id,
        po_data.name,
        po_data.mobile,
        po_data.jobtitle,

        po_data.role,
        po_data.teams,
        po_data.supervisor,
        po_data.hourRate,
        po_data.status,

        po_data.contract_expiry_date,
      ]);
    }
    const columnWidths = [, , , , , , , 20, , , , ,];

    DownloadPdfFileWithData(
      headers,
      csvData,
      t.people.title,
      columnWidths,
      "A3"
    );
  };

  return (
    <>
      {/* <div>
        <div className="text-right mb-5 pb-5">
          <div
            style={{ gap: "23px" }}
            className="table-searchbar d-flex justify-content-end"
          >
            <div className="work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: "search" }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => {
                  setSearchTitle(e.target.value);
                }}
              />
            </div>
          </div>
        </div>
      </div> */}
      {data?.length > 0 ? (
        <div className="filters-wrapper justify-content-between">
          <div className="flexbox table-right-dropdown d-flex ">
            <PriorityDropdown
              title={t.workOrders.table.status}
              options={filtersTrigger.status.options}
              selectedValues={filterOptions.status}
              pill={false}
              onChange={(e) => {
                if (e.target.checked) {
                  setFilterOptions({
                    ...filterOptions,
                    status: e.target.value ? [e.target.value] : [],
                  });
                } else {
                  setFilterOptions({
                    ...filterOptions,
                    status: [],
                  });
                }
              }}
              icon={false}
              image={false}
              label={true}
              PO={true}
            />

            <WorkOrderSearchDropdown
              title={t.po.table.vendor}
              options={filtersTrigger.vendors.options}
              selectedValues={filterOptions.vendors}
              searchValue={filterSearch.vendors}
              handleSearchValue={handleSearchSupervisorChange}
              onClick={(value) => {
                let newArray = toggle(filterOptions.vendors, value.value);

                setFilterOptions({
                  ...filterOptions,
                  vendors: newArray,
                });

                setFilterSearch({
                  ...filterSearch,
                  vendors: "",
                });
              }}
            />

            {/* Rate */}
            <Popup
              key={1}
              on="click"
              pinned
              open={openRate}
              position="bottom right"
              id="ratePopup"
              onClick={(e) => e.stopPropagation()}
              trigger={
                <div
                  basic
                  onClick={() => setOpenRate(!openRate)}
                  className={`popup-filter Category-dropdown popup ${
                    filterOptions.totalCost.from || filterOptions.totalCost.to
                      ? "blue-border"
                      : ""
                  } ${openRate ? "active" : ""}`}
                >
                  {t.po.table.total_cost}
                  <DownChevron />
                  {/* {selectedLanguage === 'ar' ? (
                  <Icon name="dollar sign" color="#878c90" />
                ) : null} */}
                </div>
              }
              className={`${
                selectedLanguage == "ar" ? "asset-filter-rtl" : ""
              }`}
            >
              <div
                className="flexbox"
                style={{ minWidth: "200px" }}
                id="ratePopup"
              >
                <Form.Field id="ratePopup" style={{ width: "100%" }}>
                  <label className="label-item" id="ratePopup">
                    {t.people.filter.from}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.from}
                    value={filterOptions.totalCost.from}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        totalCost: {
                          ...filterOptions.totalCost,
                          from: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
                <Form.Field
                  id="ratePopup"
                  style={{ width: "100%", marginLeft: 2 }}
                >
                  <label id="ratePopup" className="label-item">
                    {t.people.filter.to}
                  </label>
                  <Input
                    id="ratePopup"
                    autoComplete="new-password"
                    fluid
                    type="number"
                    placeholder={t.people.filter.to}
                    value={filterOptions.totalCost.to}
                    onChange={(e) =>
                      setFilterOptions({
                        ...filterOptions,
                        totalCost: {
                          ...filterOptions.totalCost,
                          to: e.target.value,
                        },
                      })
                    }
                  />
                </Form.Field>
              </div>
              <div className="form-button-container mt-0">
                <button
                  id="ratePopup"
                  className="form-action-button blue"
                  onClick={(e) => {
                    setFilterOptions({
                      ...filterOptions,
                      totalCost: {
                        from: "",
                        to: "",
                      },
                    });

                    // console.log(filterOptions);
                  }}
                >
                  {t.common.reset}
                </button>
              </div>
            </Popup>

            <div className="wo-date-picker" key={resetKey}>
              <DatePicker
                style={
                  selectedDates.length <= 1
                    ? { width: "124px" }
                    : { width: "181px" }
                }
                value={selectedDates}
                onChange={handleChange}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.parts.table.created_on}
                className={"active"}
                format="DD/MM/YY"
                // locale={lang == 'ar' ? arabic_ar : ''}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleDeselect}
                  className="btn-datepicker blue"
                >
                  {t.assets.reset}
                </button>
              </DatePicker>
              {/* <img
                className="date-picker-image procedure"
                style={
                  selectedLanguage == "en"
                    ? { left: "-31px", top: "-2px" }
                    : { top: "2px" }
                }
                src={calendar_icon}
              /> */}
            </div>

            <div
              className="wo-date-picker"
              // style={
              //   selectedLanguage == "en"
              //     ? { marginLeft: "-25px" }
              //     : { marginRight: "-25px" }
              // }
              key={resetKeyEndDate}
            >
              <DatePicker
                style={
                  dueDates?.length <= 1
                    ? { width: "124px" }
                    : { width: "181px" }
                }
                value={dueDates}
                onChange={handleEndDateChange}
                range
                showMonthDropdown
                showYearDropdown
                placeholder={t.po.table.due_date}
                className={"active"}
                format="DD/MM/YY"
                // locale={lang == 'ar' ? arabic_ar : ''}
                weekDays={[
                  t.workOrders.details_view.sun,
                  t.workOrders.details_view.mon,
                  t.workOrders.details_view.tue,
                  t.workOrders.details_view.wed,
                  t.workOrders.details_view.thu,
                  t.workOrders.details_view.fri,
                  t.workOrders.details_view.sat,
                ]}
                months={[
                  t.common.january,
                  t.common.february,
                  t.common.march,
                  t.common.april,
                  t.common.may,
                  t.common.june,
                  t.common.july,
                  t.common.august,
                  t.common.september,
                  t.common.october,
                  t.common.november,
                  t.common.december,
                ]}
              >
                <button
                  onClick={handleEndDateDeselect}
                  className="btn-datepicker blue"
                >
                  {t.assets.reset}
                </button>
              </DatePicker>
              {/* <img
                className="date-picker-image procedure"
                style={
                  selectedLanguage == "en"
                    ? { left: "-31px", top: "-2px" }
                    : { top: "2px" }
                }
                src={calendar_icon}
              /> */}
            </div>

            {filterOptions?.vendors?.length > 0 ||
            selectedDates?.length > 0 ||
            filterOptions?.status?.length > 0 ||
            filterOptions?.totalCost?.from !== null ||
              filterOptions?.totalCost?.to !== null ? (
              <div className="form-button-container mt-3">
                <button
                  // content={t.procedures.form.submit.add}
                  // primary
                  onClick={() => {
                    setSelectedDates([]);
                    setDueDates([]);
                    setFilterOptions({
                      vendors: [],
                      totalCost: {
                        from: null,
                        to: null,
                      },
                      status: [],
                    });
                  }}
                  // loading={buttonStatus == 1}
                  style={{ marginTop: "-10px" }}
                  className="form-action-button dashboard"
                >
                  {t.dashboard.cards.clear}
                </button>
              </div>
            ) : null}
          </div>
          <div className="d-flex" style={{ gap: "10px" }}>
            <div className="table-searchbar work-order-upload">
              <span className="search-svg">
                <SearchIcon />
              </span>
              <Input
                icon={{ name: "search" }}
                placeholder={t.workOrders.filter.search}
                value={searchTitle}
                onChange={(e) => setSearchTitle(e.target.value)}
              />
            </div>
            <div className="action-custom-menu-dropdown action-custom-menu-dropdown-2">
              <UncontrolledDropdown className="mr-2" direction="down">
                <DropdownToggle
                  style={{ position: "relative", cursor: "pointer" }}
                  onMouseOver={() => setShowTooltip(true)}
                  onMouseLeave={() => setShowTooltip(false)}
                  color="primary"
                  className="option-toggle"
                >
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "ar" ? "top right" : "top left"
                    }
                    show={showTooltip}
                    textBoxWidth={selectedLanguage == "ar" ? "53px" : "87px"}
                    arrowAlign={selectedLanguage == "ar" ? "center" : "start"}
                    fontSize="12px"
                    fontWeight="400"
                    fontFamily={
                      selectedLanguage == "ar" ? "Noto Kufi Arabic" : "Roboto"
                    }
                    padding="4px 4px 4px 8px"
                    borderRadius="5px"
                    static
                    moveRight="10px"
                    moveUp={selectedLanguage == "ar" ? "-1px" : "4px"}
                  >
                    <span className="work-order-tooltip">
                      {t.common.page_action}
                    </span>
                  </ToolTip>
                  <ActionBtnIcon />{" "}
                </DropdownToggle>
                <DropdownMenu className={selectedLanguage == "ar" ? "rtl" : ""}>
                  {/* {role !== 'view_only' ? (
                <>
                  <DropdownItem>
                    <ShareIcon /> {t.common.share}
                  </DropdownItem>
                  <DropdownItem className="divider" />
                </>
              ) : null} */}
                  <DropdownItem className="divider" />
                  <DropdownItem onClick={() => downloadXLS()}>
                    <DownloadIcon /> {t.common.download_sheet}
                  </DropdownItem>
                  {/* <DropdownItem className="divider" />
                  <DropdownItem onClick={() => generatePDF()}>
                    <PdfIcon /> {t.common.download_pdf}
                  </DropdownItem> */}
                </DropdownMenu>
              </UncontrolledDropdown>
            </div>
          </div>{" "}
        </div>
      ) : null}
    </>
  );
};

export default Filters;
