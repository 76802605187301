import React, { useEffect, useState } from "react";
import { Dimmer, Loader } from "semantic-ui-react";

import { createNewSet, fetchInvLocationsOptions, getAllParentLocations } from "../../config/functions";

import { useDispatch, useSelector } from "react-redux";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import { selectTranslations } from "../../config/i18n/slice";

import { toast } from "react-toastify";

import { CloseIcon } from "../../../Assets/Icons/svg";
import ToolTip from "react-power-tooltip";

import SetForm from "./SetForm";
import { fetchSetsInitData } from "../../../redux/reducers/setsReducer";
import { cloneDeep } from "lodash";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import { Resizable } from "re-resizable";

/**
 * The `Add` function in JavaScript handles the creation of a new set with various data manipulation and state management functionalities.
 */
function Add({ toggled, untoggle, setRefresh, refresh, role, allIds }) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const [dropdownOptions, setDropdownOptions] = useState({
    locations: [],});

  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });

  const { initData } = useSelector((state) => ({
    initData: state.sets.initData,
  }));

  const [partsData, setPartsData] = useState([]);
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [selectedTableSetData, setSelectedTableSetData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [prevSetsData, setPrevSetsData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);
  const [allSetsData, setAllSetsData] = useState([]);
  const [initSetsData, setInitSetsData] = useState([]);
  const [initialData, setInitialData] = useState({
    name: "",
    locations: "",
    parts: [],
    sets: [],
  });

  const [setsData, setSetsData] = useState([]);

  const [initChange, setInitChange] = useState(false);

  const { setCancelModal, CancelModal } = useCancelModal();
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [cancelModal, toggleCancelModal] = useState(false);

  const dispatch = useDispatch();

  const userRole = useSelector((state) => {
    if (state?.auth?.user?.role?.id) {
      return state?.auth?.user?.role?.type;
    } else {
      return null;
    }
  });

  /*
   * The function `handleAddSet` is responsible for adding a new set with specific parts and sets data,
   * handling validation, and displaying appropriate toast messages based on the response status.
   */
  const handleAddSet = async (values, resetForm) => {
    let addedSetsData = cloneDeep(values);
    const numParts = addedSetsData.parts.length;
    const numSets = addedSetsData.sets.length;

    if ((numParts === 1 && numSets === 1) || numSets >= 2 || numParts >= 2) {
      let partsId = [];
      const transformedArray = addedSetsData?.parts?.map((item) => {
        partsId.push(item.id);
        return {
          part: item.id,
          quantity: item.quantity,
          totalCost: item.totalCost,
        };
      });

      const transformedSetArray = addedSetsData?.sets?.map((item) => ({
        set: item.id,
        quantity: item.quantity,
        totalCost: item.totalCost,
      }));

      const response = await createNewSet(
        addedSetsData?.name,
        transformedArray,
        transformedSetArray,
        organisationId,
        currentUserId,
        partsId,
        addedSetsData?.locations,

      );

      if (response.status == 200) {
        setRefresh(!refresh);
        setButtonStatus(0);
        untoggle();
        toast.success(t.sets.add_success);
        resetForm();

        if (initData?.length == 0) {
          const fetchData = await dispatch(
            fetchSetsInitData({ organisationId, role, allIds })
          );
        }

        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(t.sets.unique);
          // untoggle();
        } else {
          toast.error(t.sets.unique);
          untoggle();
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.sets.add_valiadtion);
    }
  };

  const fetchLocations = async () => {
    const fetchData = await fetchInvLocationsOptions(
      organisationId,
    );
    if (fetchData?.length > 0) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        };
      });
      setDropdownOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The `cancelHandler` function resets certain state values and closes a modal window.
   */
  const cancelHandler = () => {
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setInitialData({
      name: "",
      locations: "",
      parts: [],
      sets: [],
    });

    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };

  return (
    <>
      <div
        style={{ maxWidth: `${modalWidth}px` }}
        clearing
        className={
          toggled ? "aside-modal active procedure" : "aside-modal procedure"
        }
      >
        <Resizable
          size={{ width: modalWidth, height: "auto" }}
          minWidth={"514px"}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">{t.sets.create_set}</div>
              <div>
                <span
                  className="c-pointer"
                  style={{ position: "relative" }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == "en"
                        ? "bottom center"
                        : "bottom center"
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>

          {buttonStatus == 1 ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            ""
          )}
          {initialData && (
            <SetForm
              initialFormValues={initialData}
              allIds={allIds}
              setDropdownOptions={setDropdownOptions}
              dropdownOptions={dropdownOptions}
              role={role}
              fetchLocations={fetchLocations}
              initChange={initChange}
              setInitChange={setInitChange}
              buttonStatus={buttonStatus}
              cancelHandler={cancelHandler}
              setNewCancelModal={setNewCancelModal}
              handleAddSet={handleAddSet}
              setPartsData={setPartsData}
              partsData={partsData}
              setInitPartsData={setInitPartsData}
              setAllPartsData={setAllPartsData}
              allPartsData={allPartsData}
              initPartsData={initPartsData}
              setInitSetsData={setInitSetsData}
              initSetsData={initSetsData}
              setSetsData={setSetsData}
              setsData={setsData}
              setSelectedTableSetData={setSelectedTableSetData}
              setSelectedTablePartData={setSelectedTablePartData}
              selectedTableSetData={selectedTableSetData}
              selectedTablePartData={selectedTablePartData}
              setPrevPartsData={setPrevPartsData}
              setPrevSetsData={setPrevSetsData}
              prevSetsData={prevSetsData}
              prevPartsData={prevPartsData}
              setAllSetsData={setAllSetsData}
              allSetsData={allSetsData}
            />
          )}
        </Resizable>
      </div>

      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>
      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default Add;
