import React, { useState, useEffect, createRef } from 'react';
import {
  List,
  TextArea,
  Header,
  Modal,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Popup,
  Image,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { PhoneInput } from 'react-international-phone';
import * as auth from "../../modules/Auth/_redux/authRedux";
import { PhoneNumberUtil } from 'google-libphonenumber';
import { toAbsoluteUrl } from '../../../_metronic/_helpers';
import Asterisk from '../../components/common/Asterisk';
import location_icon from "../../../Assets/Icons/location_on.jpg";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import {
  updateVendor,
  currencies,
  country_codes,
  uploadFile,
  urlToFile,
  validateEmail,
  IMAGES_URL,
  getAllLocations,
  handleKeyDown,
  getUserData,
  getAllParentLocations,
} from '../../config/functions';
import { selectTranslations } from '../../config/i18n/slice';

import { Country, State, City } from 'country-state-city';
import { drop } from 'lodash';
import { toast } from 'react-toastify';
import { useSelector,connect, useDispatch } from 'react-redux';
import { scrollToError } from '../../../utilFunctions/scrollToError';
import calendar_icon from '../../../Assets/Icons/calender-icon.svg';
import { usePermissionsSimplified } from '../../../hooks/usePermissionsSimplified';
import AddLocation from '../Locations/add';
import {
  AddIcon,
  AddIconWithCircle,
  AddImage,
  BillingInfoIcon,
  CloseIcon,
  ContactInfoIcon,
  DeleteIcon,
  EditWhiteIcon,
  TextSnippet,
} from '../../../Assets/Icons/svg';
import { useDropzone } from 'react-dropzone';
import { format } from 'date-fns';
import DatePicker from 'react-multi-date-picker';
import useCancelModal from '../../../hooks/Messages/useCancelModal';
import ToolTip from 'react-power-tooltip';
import { Resizable } from 're-resizable';
import useNewCancelModal from '../../../hooks/Messages/useNewCncelModal';
const phoneUtil = PhoneNumberUtil.getInstance();

const isPhoneValid = (phone) => {
  try {
    return phoneUtil.isValidNumber(phoneUtil.parseAndKeepRawInput(phone));
  } catch (error) {
    return false;
  }
};

const FileUpload = ({
  onFileChange,
  name = '',
  attachments = {},
  setPersonalImgId,
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === 'image' ? (
        attachments?.image?.name ? (
          <div style={{ position: 'relative' }}>
            <Image
              avatar
              src={`${IMAGES_URL}${attachments?.image?.url}`}
              style={{ width: '80px', height: '80px' }}
            ></Image>
            <span
              className={`people-circle-image ${
                selectedLanguage == 'en' ? '' : 'rtl-people'
              }`}
            >
              <span
                className={`edit-container ${
                  selectedLanguage == 'en' ? '' : 'rtl-edit'
                }`}
              >
                <EditWhiteIcon /> {t.common.edit}
              </span>
            </span>

            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setPersonalImgId(null);
                setAttachments({
                  ...attachments,
                  image: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </div>
        ) : (
          <div className="image-dropzone text-center mb-5 mt-7">
            <div>
              <p className="p1">
                <span className="mr-2 ml-2">
                  <AddImage />
                </span>
                <span className="mt-1">{t.workOrders.drag_img}</span>
                <span className="mt-1" style={{ color: '#0C66E4' }}>
                  {' '}
                  {t.workOrders.upload_a_file}
                </span>{' '}
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.name ? (
        <div className="warrenty-file-dropzone text-center">
          <label>
            {attachments?.warrantyFile?.name}
            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  warrantyFile: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </label>
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <TextSnippet />
            <div className="pl-4 pr-4">
              <p className="p1 m-0">Warranty file</p>
              <p className="p2 m-0">Attach a file to this asset</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};
function Edit(props) {
  const {
    toggled,
    untoggle,
    refresh,
    setRefresh,
    targetedData,
    setFilterOptions,
    setSearchTitle,
    setToggleEditModal,
    role,allIds,    setAllIds,
  } = props;
  const t = useSelector(selectTranslations);
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const dispatch = useDispatch();
  const [initChange, setInitChange] = useState(false);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });

  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return 'en';
    }
  });

  const currentUserId = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.id;
    } else {
      return null;
    }
  });


  const user = useSelector((state) => {
    //console.log(state);
    if (state?.auth?.user) {
      return state.auth.user;
    } else {
      return null;
    }
  });
  const [toggleAsideModal, setToggleAsideModal] = useState(false);
  const [personalImgId, setPersonalImgId] = useState();
  const { userPermissions } = usePermissionsSimplified();
  const [phone, setPhone] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [activePane, setActivePane] = useState(0);
  const [logo, setLogo] = useState(null);
  const [selectedPhoneCountry, setSelectedPhoneCountry] = useState('');

  const [cancelModal, toggleCancelModal] = useState(false);
  const [successModal, toggleSuccessModal] = useState(false);

  const logoRef = createRef();

  const [responseData, setResponseData] = useState({});
  const [modalWidth, setModalWidth] = useState(
    Math.max(window.innerWidth / 2, 514)
  );
  const [fetchingCountries, setFetchingCountries] = useState(true);
  const [fetchingCities, setFetchingCities] = useState(true);
  const [showCancleTooltip, setCancleTooltip] = useState(false);
  const [attachments, setAttachments] = useState({
    image: null,
  });

  const [options, setOptions] = useState({
    locations: [],
    countries: [],
    cities: [],
    preferences:[
      {
        key: '1',
        value: 'English',
        text: 'English',
      },
      {
        key: '2',
        value: 'العربية',
        text: "العربية",
      },
    ],
    Industry: [
      {
        key: 'industry1',
        value: 'Commercial Real Estate Companies',
        text: t.customers.industry.commercial,
      },
      {
        key: 'industry2',
        value: 'Corporate Offices',
        text: t.customers.industry.corporate,
      },
      {
        key: 'industry3',
        value: 'Manufacturing Companies',
        text: t.customers.industry.manufacturing,
      },
      {
        key: 'industry4',
        value: 'Healthcare Organizations',
        text: t.customers.industry.healthcare,
      },
      {
        key: 'industry5',
        value: 'Educational Institutions',
        text: t.customers.industry.educational,
      },
      {
        key: 'industry6',
        value: 'Government and Public Sector Organizations',
        text: t.customers.industry.government,
      },
      {
        key: 'industry7',
        value: 'Hospitality and Entertainment Companies',
        text: t.customers.industry.hospitality,
      },
      {
        key: 'industry8',
        value: 'Data Centers',
        text: t.customers.industry.data_centers,
      },
      {
        key: 'industry9',
        value: 'Retail Companies',
        text: t.customers.industry.retails,
      },
      {
        key: 'industry10',
        value: 'Sports and Recreation Facilities',
        text: t.customers.industry.sports,
      },
    ],
    Mode: [
      {
        key: '1',
        value: 'cash',
        text: t.vendors.mode.cash,
      },
      {
        key: '2',
        value: 'credit card',
        text: t.vendors.mode.credit_card,
      },
      {
        key: '3',
        value: 'bank transfer',
        text: t.vendors.mode.bank,
      },
      {
        key: '4',
        value: 'procurement card',
        text: t.vendors.mode.procurement_card,
      },
      {
        key: '5',
        value: 'check',
        text: t.vendors.mode.check,
      },
      {
        key: '6',
        value: 'cliq',
        text: t.vendors.mode.cliq,
      },
    ],
    accountType: [
      {
        key: '0',
        value: 'salaries account',
        text: t.vendors.account.salary,
      },
      {
        key: '1',
        value: 'basic bank account',
        text: t.vendors.account.basic,
      },
      {
        key: '2',
        value: 'time deposit account',
        text: t.vendors.account.time_deposit,
      },
      {
        key: '3',
        value: 'current account',
        text: t.vendors.account.current,
      },
      {
        key: '4',
        value: 'time deposit with prizes',
        text: t.vendors.account.time_deposit_price,
      },
      {
        key: '5',
        value: 'POS current account',
        text: t.vendors.account.pos,
      },
    ],
  });
  const [data, setData] = useState({
    name: '',
    isSame: false,
    preferences:"English",
    address: '',
    countryCode: '962',
    phone: '',
    website: '',
    email: '',
    serviceType: '',
    description: '',
    country: '',
    city: '',
    billing_name: '',
    billing_postal_code: '',
    countries_options: [],
    cities_options: [],
    isSame: false,
    billing_address: '',
    billing_addressLine2: '',
    billing_addressLine3: '',
    billing_rate: 0,
    billing_currency: 'JOD',
    Industry: '',
    contact_name: '',
    contact_phone: '',
    contact_email: '',
    startDate: '',
    endDate: '',
    credit_days: '',
    pre_process_days: '',
    location: '',
    mode: '',
    bank: '',
    account_no: '',
    account_type: '',
    IBAN: '',
    disbursement_currency: 'JOD',
  });
  const [validation, setValidation] = useState({
    name: {
      error: true,
      pattern: (name) => name != '',
      skipped: false,
      message: t.common.required_field,
    },
    email: {
      error: true,
      pattern: (email) => email == '' || validateEmail(email),
      skipped: false,
      message: t.common.invalid_email,
    },
    phone: {
      error: false,
      pattern: (phone) => phone === '' || isPhoneValid(phone),
      skipped: false,
      message: t.common.required_field,
    },
    contact_email: {
      error: true,
      pattern: (email) => email === '' || validateEmail(email),
      skipped: false,
      message: t.common.invalid_email,
    },
    contact_phone: {
      error: false,
      pattern: (phone) => phone === '' || isPhoneValid(phone),
      skipped: false,
      message: t.common.required_field,
    },
  });
  // const [customFields, setCustomData] = useState([]);

  const resetForm = () => {
    setData({
      name: '',
      isSame: false,
      preferences:"English",
      address: '',
      countryCode: '962',
      phone: '',
      website: '',
      email: '',
      serviceType: '',
      description: '',
      country: '',
      city: '',
      billing_name: '',
      billing_postal_code: '',
      countries_options: [],
      cities_options: [],
      isSame: false,
      billing_address: '',
      billing_addressLine2: '',
      billing_addressLine3: '',
      billing_rate: 0,
      billing_currency: 'JOD',
      Industry: '',
      contact_name: '',
      contact_phone: '',
      contact_email: '',
      startDate: '',
      endDate: '',
      credit_days: '',
      pre_process_days: '',
      location: '',
      mode: '',
      bank: '',
      account_no: '',
      account_type: '',
      IBAN: '',
      disbursement_currency: 'JOD',
    });

    setModalWidth(Math.max(window.innerWidth / 2, 514));
    setValidation({
      name: {
        error: true,
        pattern: (name) => name != '',
        skipped: false,
        message: t.common.required_field,
      },
      email: {
        error: true,
        pattern: (email) => email == '' || validateEmail(email),
        skipped: false,
        message: t.common.invalid_email,
      },
      contact_email: {
        error: true,
        pattern: (email) => email === '' || validateEmail(email),
        skipped: false,
        message: t.common.invalid_email,
      },
      contact_phone: {
        error: false,
        pattern: (phone) => phone === '' || isPhoneValid(phone),
        skipped: false,
        message: t.common.required_field,
      },
      phone: {
        error: false,
        pattern: (phone) => phone === '' || isPhoneValid(phone),
        skipped: false,
        message: t.common.required_field,
      },
    });
    setFetchingCountries(true);
    setInitChange(false);
    setFetchingCities(true);
    setActivePane(0);
  };

  useEffect(() => {
    if (toggled) {
      fetchOptions();
      fetchLocationsOptions();
      if (targetedData) {
        setData({
          ...data,
          name: targetedData?.attributes?.name,
          isSame: false,
          preferences: targetedData?.attributes?.preferences ? targetedData?.attributes?.preferences : "English",
          address: targetedData?.attributes?.address,
          // countryCode: targetedData?.attributes?.phone.slice(0, 3),
          phone: targetedData?.attributes?.phone || '',
          website: targetedData?.attributes?.website,
          email: targetedData?.attributes?.email
            ? targetedData?.attributes?.email
            : '',
          serviceType: targetedData?.attributes?.serviceType,
          description: targetedData?.attributes?.description,
          country: targetedData?.attributes?.billingInformation?.country,
          city: targetedData?.attributes?.billingInformation?.city,
          billing_name: targetedData?.attributes?.billingInformation?.name,
          billing_postal_code:
            targetedData?.attributes?.billingInformation?.postalCode,
          billing_address:
            targetedData?.attributes?.billingInformation?.address,
          billing_addressLine2:
            targetedData?.attributes?.billingInformation?.addressLine2,
          billing_addressLine3:
            targetedData?.attributes?.billingInformation?.addressLine3,
          billing_rate: targetedData?.attributes?.billingInformation?.rate
            ? targetedData?.attributes?.billingInformation?.rate
            : '',
          billing_currency:
            targetedData?.attributes?.billingInformation?.currency,

          startDate: targetedData?.attributes?.startDate
            ? format(new Date(targetedData.attributes.startDate), 'yyyy-MM-dd')
            : null,
          endDate: targetedData?.attributes?.endDate
            ? format(new Date(targetedData.attributes.endDate), 'yyyy-MM-dd')
            : null,
          Industry: targetedData?.attributes?.Industry || '',
          pre_process_days:
            targetedData?.attributes?.billingInformation?.preProcessDays || '',
          location: targetedData?.attributes?.location?.data?.id,
          contact_email:
            targetedData?.attributes?.contactInformation?.email || '',
          contact_phone:
            targetedData?.attributes?.contactInformation?.phone || '',
          contact_name:
            targetedData?.attributes?.contactInformation?.name || '',
          credit_days: targetedData?.attributes?.creditDays || '',
          mode:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.mode || '',
          bank:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.bank || '',
          account_type:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.accountType || '',
          account_no:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.accountNo || '',

          IBAN:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.IBAN || '',
          disbursement_currency:
            targetedData?.attributes?.billingInformation?.paymentDetails
              ?.disbursementCurrency || 'JOD',
        });

        setAttachments({
          ...attachments,
          image: targetedData?.attributes?.logo?.data
            ? {
                id: targetedData?.attributes?.logo?.data.id,
                file: urlToFile(
                  targetedData?.attributes?.logo?.data.attributes.url,
                  targetedData?.attributes?.logo?.data.attributes.name
                ),
                url: targetedData?.attributes?.logo?.data.attributes.url,
                name: targetedData?.attributes?.logo?.data.attributes.name,
                type: targetedData?.attributes?.logo?.data.attributes.mime,
                cancelPopup: false,
                isChanged: false,
              }
            : null,
        });
      }
    }
  }, [targetedData, toggled]);

  const fetchLocationsOptions = async (updatedAllIds = []) => {
    setOptions({
      ...options,
      locations: [],
    });

    let finalIds = [...allIds];

    if (updatedAllIds?.length > 0) {
      finalIds = [...updatedAllIds];
    }
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(role === "super_admin" || role === "admin")) {
          // Check if the element's ID is included in allIds
          if (finalIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
 className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });
      
            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (finalIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some((subEl) =>
              finalIds?.includes(subEl.id)
            );
      
            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
 className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });
      
              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&   el.attributes.subLocations.data.forEach((subEl) => {
                if (finalIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            image: { src: location_icon },
 className: "main-location-dropdown-option",
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  const fetchOptions = async () => {
    const [countries, cities] = await Promise.all([
      Country.getAllCountries(),
      State.getStatesOfCountry(
        targetedData?.attributes?.billingInformation?.country
      ),
    ]);

    let countries_options = [];
    if (countries) {
      countries_options = countries.map((el) => {
        return {
          key: el?.isoCode?.toLowerCase(),
          value: el?.isoCode,
          text: el.name,
          flag: el?.isoCode?.toLowerCase(),
        };
      });
      setFetchingCountries(false);
    }

    let cities_options = [];
    if (cities) {
      cities_options = cities.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setFetchingCities(false);
    }

    setOptions((pre) => ({
      ...pre,
      countries: countries?.length > 0 ? countries_options : [],
      cities: cities?.length > 0 ? cities_options : [],
    }));
  };

  const onFileChange = async (files, name) => {
    // console.log('show', e.target.files[0]);
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    let file;
    if (name == 'image') {
      const personalPictureResponse = await uploadFile([files[0]]);
      if (personalPictureResponse?.status == 200) {
        file = personalPictureResponse.data[0].url;
        setPersonalImgId(personalPictureResponse.data[0].id);
      }
    }
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
        url: file ? file : null,
      },
    });
  };

  const changeTab = async (tab, errors, setFieldTouched, values) => {
    let errorsInData = validationHandler();

    if (!errorsInData) {
      setActivePane(tab);
      return;
    } else {
      scrollToError();
    }
  };
  const fetchCountriesOptions = () => {
    const countries = Country.getAllCountries();
    if (countries) {
      const _g_options = countries.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setOptions({
        ...options,
        countries: _g_options,
      });

      setFetchingCountries(false);
    }
  };

  useEffect(() => {
    if (options.countries.length > 0 && !fetchingCountries) {
      setData({
        ...data,
        country: targetedData?.attributes?.billingInformation?.country,
      });

      fetchCitiesOptions(targetedData?.attributes?.billingInformation?.country);
    }
  }, [fetchingCountries]);

  const fetchCitiesOptions = (code) => {
    const fetchData = State.getStatesOfCountry(code);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setOptions({
        ...options,
        cities: the_options,
      });

      setFetchingCities(false);
    }
  };

  useEffect(() => {
    if (options.cities.length > 0 && !fetchingCities) {
      setData({
        ...data,
        city: targetedData?.attributes?.billingInformation?.city,
      });
    }
  }, [fetchingCities]);

  const fetchCities = async () => {
    const fetchData = State.getStatesOfCountry(data.country);

    if (fetchData.length > 0) {
      // console.log(fetchData);
      let the_options = fetchData.map((el) => {
        return {
          key: el.isoCode,
          value: el.isoCode,
          text: el.name,
        };
      });
      setOptions({
        ...options,
        cities: the_options,
      });
    } else {
      setOptions({
        ...options,
        cities: [],
      });
    }
  };

  useEffect(() => {
    fetchCities();
  }, [data.country]);

  const onValuesChange = (name) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = '';
    const dropdown_fields = [
      'billing_currency',
      'countryCode',
      'country',
      'preferences',
      'city',
      'location',
      'Industry',
      'mode',
      'account_type',
      'disbursement_currency'
    ];

    if (!dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      if (
        name !== 'name' ||
        name !== 'email' ||
        name !== 'phone' ||
        !data.isSame
      ) {
        setData({
          ...data,
          [name]: event.target.value,
        });
      }

      if (data.isSame) {
        if (name === 'name') {
          setData({
            ...data,
            ['contact_name']: event.target.value,
            ['name']: event.target.value,
          });
        } else if (name === 'email') {
          setData({
            ...data,
            ['contact_email']: event.target.value,
            ['email']: event.target.value,
          });
        } else if (name === 'phone') {
          setData({
            ...data,
            ['contact_phone']: event.target.value,
            ['phone']: event.target.value,
          });
        }
      }
    }
    if (dropdown_fields.includes(name)) {
      currentFieldValue = value.value;
      if (name == 'country') {
        if (name === 'country') {
          setData({
            ...data,
            [name]: value.value,
            city: '',
          });
        } else {
          setData({
            ...data,
            [name]: value.value,
          });
        }
      } else {
        setData({
          ...data,
          [name]: value.value,
        });
      }
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };
      // console.log('current', currentValidation);
      setValidation(currentValidation);
    }
    // let fieldValidation = validation[name];
    // if (fieldValidation && name=="email") {
    //   let currentValidation = {
    //     ...validation,
    //     [name]: {
    //       ...fieldValidation,
    //       error: fieldValidation.pattern(currentFieldValue) ? false : true,
    //       message:currentFieldValue == "" ? t.common.required_field : t.common.invalid_email
    //     },
    //   };
    //   // console.log('current', currentValidation);
    //   setValidation(currentValidation);
    // }else if(fieldValidation){
    //   let currentValidation = {
    //     ...validation,
    //     [name]: {
    //       ...fieldValidation,
    //       error: fieldValidation.pattern(currentFieldValue) ? false : true,
    //     },
    //   };
    //   // console.log('current', currentValidation);
    //   setValidation(currentValidation);
    // }
  };
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      name: {
        ...validation.name,
        error: validation.name.pattern(data.name) ? false : true,
        skipped: true,
      },
      email: {
        ...validation.email,
        error: validation.email.pattern(data.email) ? false : true,
        skipped: true,
      },
      contact_email: {
        ...validation.contact_email,
        error: validation.contact_email.pattern(data.contact_email)
          ? false
          : true,
        skipped: true,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  const updateCustomerHandler = async () => {
    let errorsInData = validationHandler();
    if (!errorsInData) {
      setButtonStatus(1);
      const logoId = personalImgId ? personalImgId : null;

      const billingInformation = {
        name: data.billing_name,
        country: data?.country ? data.country : null,
        city: data?.city ? data.city : null,
        postalCode: data.billing_postal_code,
        address: data.billing_address,
        addressLine2: data.billing_addressLine2,
        addressLine3: data.billing_addressLine3,
        rate: data.billing_rate ? data.billing_rate : null,
        currency: data.billing_currency || null,
        preProcessDays: data.pre_process_days
          ? Number(data.pre_process_days)
          : null,
        paymentDetails: {
          mode: data?.mode ? data?.mode : null,
          bank: data?.bank,
          accountType: data?.account_type ? data?.account_type : null,
          accountNo: data?.account_no ? Number(data?.account_no) : null,
          IBAN: data?.IBAN ? Number(data?.IBAN) : null,
          disbursementCurrency: data?.disbursement_currency,
        },
      };

      const contactInformation = {
        name: data.contact_name,
        phone: data?.contact_phone ? data?.contact_phone : null,
        email: data?.contact_email ? data.contact_email : null,

        // isSame: data.isSame,
      };

      const response = await updateVendor(
        Number(targetedData?.id),
        String(data.name),
        String(data.address),
        data.phone ? data.phone : null,
        data.website,
        data.email ? data.email : null,
        data.serviceType,
        data.description,
        billingInformation,
        logoId,
        data.location ? data.location : null,
        data.Industry ? data.Industry : null,
        contactInformation,
        data?.startDate ? data.startDate : null,
        data?.endDate ? data.endDate : null,
        data?.credit_days ? Number(data?.credit_days) : null,
        organisationId,
        data?.preferences ? data?.preferences : "English",

        // customFields
      );
      // console.log(response);

      if (response.status == 200) {
        setButtonStatus(0);
        untoggle();
        resetForm();
        setFilterOptions({
        
          locations: [],
          cities: [],
        
        });
        setSearchTitle('');

        setResponseData({
          name: response.data.name,
        });

        toast.success(t.vendors.form.vendor_updated_success);

        // toggleSuccessModal(true);
      } else if (response.status == 400) {
        if (response.error && response.error.message) {
          toast.error(response.error.message);
          // untoggle();
        } else {
          toast.error(t.common.something_wrong);
          untoggle();
          resetForm();
        }
      }
      setButtonStatus(0);
    } else {
      scrollToError();
    }
  };

  const cancelHandler = () => {
    resetForm();
    setModalWidth(Math.max(window.innerWidth / 2, 514));
    untoggle();
    setNewCancelModal(false);
    setInitChange(false);
  };
  return (
    <>
      <div
        clearing
        style={{ maxWidth: `${modalWidth}px` }}
        className={
          toggled
            ? 'aside-modal preview active procedure'
            : 'aside-modal preview procedure'
        }
      >
        <Resizable
          size={{ width: modalWidth, height: 'auto' }}
          minWidth={'514px'}
          onResize={(e, direction, ref, d) => {
            setModalWidth(ref.offsetWidth);
          }}
        >
          <div className="modal-header">
            <div className="flexbox justify-between">
              <div className="name">
                {t.vendors.form.edit_vendor} #{targetedData?.attributes?.countId}
              </div>

              <div className="flexbox align-center workorder-header-buttons">
                <span
                  className="procedure-header-button c-pointer"
                  style={{ position: 'relative' }}
                  onMouseOver={() => setCancleTooltip(true)}
                  onMouseLeave={() => setCancleTooltip(false)}
                  onClick={() =>
                    initChange ? setNewCancelModal(true) : cancelHandler()
                  }
                >
                  <CloseIcon />
                  <ToolTip
                    color="#fff"
                    backgroundColor="#172B4D"
                    position={
                      selectedLanguage == 'en'
                        ? 'bottom center'
                        : 'bottom center'
                    }
                    arrowAlign="center"
                    show={showCancleTooltip}
                    textBoxWidth="36px"
                    padding="5px 5px 5px 7px"
                    borderRadius="5px"
                    static
                  >
                    <span className="work-order-preview-tooltip">
                      {t.common.close}
                    </span>
                  </ToolTip>
                </span>
              </div>
            </div>
          </div>
          <div
            style={{
              height: '90vh',
              overflowX: 'hidden',
              overflowY: 'scroll',
            }}
            className="body p-6"
          >
            {buttonStatus == 1 ? (
              <Dimmer active inverted>
                <Loader inverted content={t.common.loading} />
              </Dimmer>
            ) : (
              ''
            )}
            <Button.Group
              className="add-people-btn mt-5"
              basic
              fluid
              style={{ width: '71%' }}
              size="medium"
            >
              <Button
                active={activePane == 0}
                onClick={() => changeTab(0)}
                type="button"
              >
                {t.vendors.table.vendor_info}
              </Button>

              <Button
                className="add-people-tab"
                active={activePane == 1}
                onClick={() => changeTab(1)}
                type="button"
              >
                {t.customers.form.billing_information}
              </Button>

              {/* <Button
              active={activePane == 2}
              onClick={() => {
                // setFieldValue('tab', 2).then((errors) => {
                //   changeTab(2, errors, setFieldTouched, values);
                //   if (errors) {
                //     scrollToError();
                //   }
                // });
              }}
              type="button"
            >
              {t.people.attachments_form.attachment_title}
            </Button> */}
            </Button.Group>
            <Divider className="mt-0" style={{ width: '70%' }} />

            <div>
              <div style={{ display: activePane == 0 ? 'block' : 'none' }}>
                <Form.Field>
                  <FileUpload
                    onFileChange={onFileChange}
                    name="image"
                    attachments={attachments}
                    setAttachments={setAttachments}
                    setPersonalImgId={setPersonalImgId}
                  />
                </Form.Field>
                <Form.Field>
                  <label className="label-item">
                    {t.vendors.table.name}
                    <Asterisk color="#172B4D" />
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_name}
                    value={data.name}
                    onChange={onValuesChange('name')}
                    error={validation.name.error && validation.name.skipped}
                    onBlur={(e) => {
                      if (validation.name.pattern(data.name)) {
                        setValidation({
                          ...validation,
                          name: {
                            ...validation.name,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          name: {
                            ...validation.name,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.name.error && validation.name.skipped ? (
                    <div className="label-error">{validation.name.message}</div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form style={{ marginBottom: '20px' }}>
                  <Form.Field>
                    <label className="label-item">
                      {' '}
                      {t.customers.form.description}
                    </label>
                    <TextArea
                      autoComplete="new-password"
                      value={data.description}
                      onChange={onValuesChange('description')}
                      rows={4}
                      fluid
                      placeholder={t.customers.form.add_description}
                    />
                  </Form.Field>
                </Form>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.customers.form.location}
                    {/* <Icon name="asterisk" color="red" size="small" /> */}
                  </label>
                  <div className="flexbox">
                  <Dropdown
                    placeholder={t.customers.form.add_location}
                    fluid
                      className="loc-dropdown"
                    selection
                    //allowAdditions
                    // multiple
                    search
                    // onAddItem={handleAddLocation}
                    // disabled={!data.customer}
                    options={options.locations}
                    value={data.location}
                    onChange={onValuesChange('location')}
                  />
                   {userPermissions?.location?.create &&
                    userPermissions?.location?.view ? (
                      <span
                        className="ml-2 mr-2 p-1 d-flex align-items-center justify-content-center c-pointer"
                        onClick={() => {
                          setToggleAsideModal(true);
                          setToggleEditModal(false);
                        }}
                        style={{ backgroundColor: "#E9F2FF" }}
                      >
                        <AddIcon />
                      </span>
                    ) : null}
                   </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.form.phone}
                  </label>
                  <PhoneInput
                    defaultCountry="jo"
                    // forceDialCode={true}
                    value={data.phone}
                    placeholder={t.customers.form.add_number}
                    onChange={(value) => {
                      // console.log('values', value);
                      if (!phone) {
                        setPhone(true);
                        return;
                      }
                      setData({
                        ...data,
                        phone: value || '',
                      });
                      if (data.isSame) {
                        setData({
                          ...data,
                          phone: value || '',
                          contact_phone: value || '',
                        });
                      }
                      if (!value) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: false,
                            skipped: false,
                            message: t.common.required_field,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      } else if (value && isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: false,
                            skipped: true,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      } else if (value && !isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          phone: {
                            ...validation.phone,
                            error: true,
                            skipped: true,
                            message: t.customers.form.invalid_phone,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      }
                    }}
                    error={
                      validation?.phone?.error && validation?.phone?.skipped
                    }
                    // onBlur={(e) => {
                    //   if (validation.phone.pattern(customerData.phone)) {
                    //     setValidation({
                    //       ...validation,
                    //       phone: {
                    //         ...validation.phone,
                    //         error: false,
                    //         skipped: true,
                    //       },
                    //     });
                    //   } else {
                    //     setValidation({
                    //       ...validation,
                    //       phone: {
                    //         ...validation.phone,
                    //         error: true,
                    //         skipped: true,
                    //       },
                    //     });
                    //   }
                    // }}
                  />
                  {validation?.phone?.error && validation?.phone?.skipped ? (
                    <div className="label-error">
                      {validation?.phone?.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>
                <Form.Field>
                  <label className="label-item">
                    {' '}
                    {t.customers.form.email}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_email}
                    value={data.email}
                    onChange={onValuesChange('email')}
                    error={validation.email.error && validation.email.skipped}
                    onBlur={(e) => {
                      if (validation.email.pattern(data.email)) {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        setValidation({
                          ...validation,
                          email: {
                            ...validation.email,
                            error: true,
                            skipped: true,
                          },
                        });
                      }
                    }}
                  />
                  {validation.email.error && validation.email.skipped ? (
                    <div className="label-error">
                      {validation.email.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                {/* <Form.Field>
                <label className="label-item">{t.vendors.form.address}</label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.vendors.form.address}
                  value={data.address}
                  onChange={onValuesChange('address')}
                />
              </Form.Field> */}
                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.website}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_website}
                    value={data.website}
                    onChange={onValuesChange('website')}
                  />
                </Form.Field>

                {/* <Form.Field>
                <label className="label-item">
                  {t.vendors.form.phone}
                  <Icon name="asterisk" color="red" size="small" />
                </label>
                <PhoneInput
                  defaultCountry="jo"
                
                  value={data.phone}
                  placeholder={t.vendors.form.phone}
                  onChange={(value) => {
                 
                    if (!phone) {
                      setPhone(true);
                      return;
                    }
                    setData({
                      ...data,
                      phone: value || '',
                    });
                    if (!value) {
                      setValidation({
                        ...validation,
                        phone: {
                          ...validation.phone,
                          error: true,
                          skipped: true,
                          message: t.common.required_field,
                          pattern: (phone) =>
                            phone === '' || isPhoneValid(phone),
                        },
                      });
                    } else if (value && isPhoneValid(value)) {
                      setValidation({
                        ...validation,
                        phone: {
                          ...validation.phone,
                          error: false,
                          skipped: true,
                          pattern: (phone) =>
                            phone === '' || isPhoneValid(phone),
                        },
                      });
                    } else if (value && !isPhoneValid(value)) {
                      setValidation({
                        ...validation,
                        phone: {
                          ...validation.phone,
                          error: true,
                          skipped: true,
                          message: t.vendors.form.invalid_phone,
                          pattern: (phone) =>
                            phone === '' || isPhoneValid(phone),
                        },
                      });
                    }
                  }}
                  error={validation?.phone?.error && validation?.phone?.skipped}
                  
                />
                {validation?.phone?.error && validation?.phone?.skipped ? (
                  <div className="label-error">
                    {validation?.phone?.message}
                  </div>
                ) : (
                  ''
                )}
                
              </Form.Field> */}

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.service_type}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_service_type}
                    value={data.serviceType}
                    onChange={onValuesChange('serviceType')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">{t.common.industry}</label>
                  <Dropdown
                    placeholder={t.customers.form.add_industry}
                    fluid
                    selection
                    search
                    options={options.Industry}
                    value={data.Industry}
                    onChange={onValuesChange('Industry')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.credit_days}
                  </label>
                  <div className="estimation-time-div">
                    <Input
                      type="number"
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      min="0"
                      fluid
                      placeholder={t.vendors.form.add_credit_days}
                      value={data.credit_days}
                      onChange={onValuesChange('credit_days')}
                    ></Input>
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.vendors.form.start_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: '100%' }}
                      value={data.startDate ? new Date(data.startDate) : null}
                      onChange={(value) => {
                        if (value && value.valueOf()) {
                          setData({
                            ...data,
                            startDate: format(value.valueOf(), 'yyyy-MM-dd'),
                            endDate: null,
                          });
                        } else {
                          setData({
                            ...data,
                            startDate: null,
                            endDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.vendors.form.add_start_date}
                      className={'active'}
                      // locale={lang == 'ar' ? arabic_ar : ''}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD.MM.YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == 'en'
                          ? { top: '8px', right: '7px' }
                          : { top: '8px', left: '7px' }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>
                <Form.Field style={{ minHeight: '71px' }}>
                  <label className="label-item">
                    {t.vendors.form.end_date}
                  </label>
                  <div className="change-date-time-inll position-relative">
                    <DatePicker
                      style={{ width: '100%' }}
                      value={
                        data.endDate && data.startDate
                          ? new Date(data.endDate)
                          : null
                      }
                      minDate={new Date(data?.startDate)}
                      disabled={!data?.startDate}
                      onChange={(value) => {
                        if (value && data.startDate && value.valueOf()) {
                          setData({
                            ...data,
                            endDate: format(value.valueOf(), 'yyyy-MM-dd'),
                          });
                        } else {
                          setData({
                            ...data,
                            endDate: null,
                          });
                        }
                      }}
                      showMonthDropdown
                      showYearDropdown
                      placeholder={t.vendors.form.add_end_date}
                      className={'active'}
                      // locale={lang == 'ar' ? arabic_ar : ''}
                      weekDays={[
                        t.workOrders.details_view.sun,
                        t.workOrders.details_view.mon,
                        t.workOrders.details_view.tue,
                        t.workOrders.details_view.wed,
                        t.workOrders.details_view.thu,
                        t.workOrders.details_view.fri,
                        t.workOrders.details_view.sat,
                      ]}
                      months={[
                        t.common.january,
                        t.common.february,
                        t.common.march,
                        t.common.april,
                        t.common.may,
                        t.common.june,
                        t.common.july,
                        t.common.august,
                        t.common.september,
                        t.common.october,
                        t.common.november,
                        t.common.december,
                      ]}
                      format="DD.MM.YYYY"
                    ></DatePicker>

                    <img
                      className="position-absolute"
                      style={
                        selectedLanguage == 'en'
                          ? { top: '8px', right: '7px' }
                          : { top: '8px', left: '7px' }
                      }
                      src={calendar_icon}
                    />
                  </div>
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.customers.form.preferences}
                    {/* <Icon name="asterisk" color="red" size="small" /> */}
                  </label>
                  <Dropdown
                    placeholder={t.customers.form.add_preferences}
                    fluid
                    selection
                    noResultsMessage={t.common.no_results_found}
                    
                    //allowAdditions
                    // multiple
                    search
                    // onAddItem={handleAddLocation}
                    // disabled={!data.customer}
                    options={options.preferences}
                    value={data.preferences}
                    onChange={onValuesChange('preferences')}
                  />
                </Form.Field>
                <Divider className="mt-5" />

                <div className="billing-info mt-7  d-flex">
                  <ContactInfoIcon />
                  <p
                    style={{ fontSize: '16px' }}
                    className={` mt-1 required-text ${
                      selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                    }`}
                  >
                    {t.customers.form.contact_information}
                  </p>
                </div>

                <Form.Field>
                  <label className="label-item mt-8">
                    {t.customers.contact_name}
                  </label>
                  <Input
                    // disabled={customerData.isSame}
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_contact_name}
                    value={data.contact_name}
                    onChange={onValuesChange('contact_name')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.customers.contact_phone}
                  </label>
                  <PhoneInput
                    // disabled={customerData.isSame}
                    // disableDialCodePrefill={!customerData.isSame ? true : false}
                    defaultCountry="jo"
                    // forceDialCode={true}
                    value={data.contact_phone}
                    placeholder={t.customers.form.add_number}
                    onChange={(value) => {
                      // console.log('values', value);
                      if (!phone) {
                        setPhone(true);
                        return;
                      }
                      setData({
                        ...data,
                        contact_phone: value || '',
                      });
                      if (!value) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: false,
                            skipped: false,
                            message: t.common.required_field,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      } else if (value && isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: false,
                            skipped: true,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      } else if (value && !isPhoneValid(value)) {
                        setValidation({
                          ...validation,
                          contact_phone: {
                            ...validation.contact_phone,
                            error: true,
                            skipped: true,
                            message: t.customers.form.invalid_phone,
                            pattern: (phone) =>
                              phone === '' || isPhoneValid(phone),
                          },
                        });
                      }
                    }}
                    error={
                      validation?.contact_phone?.error &&
                      validation?.contact_phone?.skipped
                    }
                  />
                  {validation?.contact_phone?.error &&
                  validation?.contact_phone?.skipped ? (
                    <div className="label-error">
                      {validation?.contact_phone?.message}
                    </div>
                  ) : (
                    ''
                  )}
                </Form.Field>

                <Form.Field style={{ minHeight: '71px' }}>
                  <label className="label-item mt-1">
                    {t.customers.contact_email}
                  </label>
                  <Input
                    // disabled={customerData.isSame}
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_contact_email}
                    value={data.contact_email}
                    onChange={onValuesChange('contact_email')}
                    className="email-password-ltr"
                    error={
                      validation.contact_email.error &&
                      // !customerData.isSame
                      //  &&
                      validation.contact_email.skipped
                    }
                    onBlur={(e) => {
                      if (
                        validation.contact_email.pattern(data.contact_email)
                      ) {
                        setValidation({
                          ...validation,
                          contact_email: {
                            ...validation.contact_email,
                            error: false,
                            skipped: true,
                          },
                        });
                      } else {
                        if (!data.isSame && data.contact_email !== '') {
                          setValidation({
                            ...validation,
                            contact_email: {
                              ...validation.contact_email,
                              error: true,
                              skipped: true,
                            },
                          });
                        } else {
                          setValidation({
                            ...validation,
                            contact_email: {
                              ...validation.contact_email,
                              error: false,
                              skipped: false,
                            },
                          });
                        }
                      }
                    }}
                  />
                  {validation.contact_email.error &&
                  // !customerData.isSame

                  validation.contact_email.skipped ? (
                    <div className="label-error">
                      {validation.contact_email.message}
                    </div>
                  ) : (
                    ''
                  )}
                  {/* <div className="mt-4 mb-4">
                  <Checkbox
                    checked={customerData.isSame}
                    label={t.customers.same_info}
                    onChange={(e, value) => {
                      if (value.checked) {
                        setCustomerData({
                          ...customerData,
                          isSame: true,
                          contact_email: customerData.email,
                          contact_phone: customerData.phone,
                          contact_name: customerData.name,
                        });
                      } else {
                        setCustomerData({
                          ...customerData,
                          isSame: false,
                          contact_email: '',
                          contact_phone: '',
                          contact_name: '',
                        });
                      }
                    }}
                  />
                </div> */}
                </Form.Field>

                {/* <Form.Field>
                <label className="label-item">{t.vendors.form.logo}</label>
                {logo ? (
                  <List divided relaxed>
                    <Popup
                      key={logo?.id}
                      content={t.common.remove_file}
                      on="click"
                      pinned
                      open={logo?.cancelPopup}
                      trigger={
                        <List.Item
                          style={{ cursor: 'pointer' }}
                          onClick={() => {
                            let currentFile = logo;
                            currentFile.cancelPopup = !currentFile.cancelPopup;
                            setLogo(currentFile);
                          }}
                        >
                          <List.Icon
                            name={
                              logo?.type.match('image.*')
                                ? 'image'
                                : logo?.type.match('pdf')
                                ? 'file pdf'
                                : 'file'
                            }
                            size="large"
                            color="black"
                            verticalAlign="middle"
                          />
                          <List.Content>
                            <List.Header>{logo?.name}</List.Header>
                          </List.Content>
                        </List.Item>
                      }
                    >
                      <Header as="h4">{t.common.remove_file}</Header>
                      <Button
                        color="black"
                        onClick={(e) => {
                          e.stopPropagation();
                          let currentFile = logo;
                          currentFile.cancelPopup = false;
                          setLogo(currentFile);
                        }}
                      >
                        {t.common.keep}
                      </Button>
                      <Button
                        color="red"
                        onClick={(e) => {
                          e.stopPropagation();
                          setLogo(null);
                        }}
                      >
                        {t.common.yes_remove}
                      </Button>
                    </Popup>
                  </List>
                ) : (
                  <div>
                    <input
                      ref={logoRef}
                      type="file"
                      accept="image/*"
                      hidden
                      onChange={(e) => onFileChange(e)}
                    />
                    <div
                      className="buttun-type-link"
                      onClick={() => logoRef.current.click()}
                    >
                      <Icon name="add" />
                      {t.common.add}
                    </div>
                    <p
                      style={{
                        fontSize: '12px',
                        marginTop: '5px',
                        color: '#7e7e7e',
                      }}
                    >
                      {t.common.allowed_type} jpg, jpeg, png
                    </p>
                  </div>
                )}
              </Form.Field> */}
              </div>

              {/* <Divider horizontal>{t.vendors.form.billing_information}</Divider> */}
              <div style={{ display: activePane == 1 ? 'block' : 'none' }}>
                <Form.Field>
                  <label className="label-item">{t.vendors.form.name}</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_name}
                    value={data.billing_name}
                    onChange={onValuesChange('billing_name')}
                  />
                </Form.Field>

                <Form.Field autoComplete="off">
                  <label className="label-item">
                    {t.customers.form.country}
                  </label>
                  <Dropdown
                    placeholder={t.locations.form.country_add}
                    fluid
                    search
                    selection
                    options={options.countries}
                    value={data.country}
                    onChange={onValuesChange('country')}
                  />
                </Form.Field>

                <Form.Field autoComplete="off">
                  <label className="label-item">{t.customers.form.city}</label>
                  <Dropdown
                    placeholder={t.locations.form.city_add}
                    fluid
                    search
                    selection
                    disabled={options.cities.length < 1}
                    options={options.cities}
                    value={data.city}
                    onChange={onValuesChange('city')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.postal_code}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_postal}
                    value={data.billing_postal_code}
                    onChange={onValuesChange('billing_postal_code')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={data.billing_address}
                    onChange={onValuesChange('billing_address')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address_line_2}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={data.billing_addressLine2}
                    onChange={onValuesChange('billing_addressLine2')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {t.customers.form.address_line_3}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.customers.form.add_address}
                    value={data.billing_addressLine3}
                    onChange={onValuesChange('billing_addressLine3')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">{t.customers.form.rate}</label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    placeholder={t.customers.form.add_rate}
                    value={data.billing_rate}
                    onChange={onValuesChange('billing_rate')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                    {' '}
                    {t.customers.form.currency}
                  </label>
                  <Dropdown
                    placeholder={t.customers.form.add_currency}
                    fluid
                    search
                    selection
                    clearable
                    options={currencies}
                    value={data.billing_currency}
                    onChange={onValuesChange('billing_currency')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.pre_days}
                  </label>
                  <div className="estimation-time-div">
                    <Input
                      type="number"
                      onKeyDown={(evt) => handleKeyDown(evt)}
                      min="0"
                      fluid
                      placeholder={t.vendors.form.add_pre_days}
                      value={data.pre_process_days}
                      onChange={onValuesChange('pre_process_days')}
                    ></Input>
                  </div>
                </Form.Field>

                <Divider className="m-0" />

                <div className="billing-info mt-7  d-flex">
                  <BillingInfoIcon />
                  <p
                    style={{ fontSize: '16px' }}
                    className={` mt-1 required-text ${
                      selectedLanguage == 'en' ? 'ml-2' : 'mr-2'
                    }`}
                  >
                    {t.vendors.form.payment_info}
                  </p>
                </div>

                <Form.Field>
                  <label className="label-item mt-7">
                    {t.vendors.form.mode}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_mode}
                    fluid
                    clearable
                    selection
                    search
                    options={options.Mode}
                    value={data.mode}
                    onChange={onValuesChange('mode')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.bank}
                  </label>
                  <Input
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.add_bank}
                    value={data.bank}
                    onChange={onValuesChange('bank')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.account}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_account}
                    fluid
                    clearable
                    selection
                    search
                    options={options.accountType}
                    value={data.account_type}
                    onChange={onValuesChange('account_type')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.account_no}
                  </label>
                  <Input
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.add_account_no}
                    value={data.account_no}
                    onChange={onValuesChange('account_no')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item mt-1">
                    {t.vendors.form.IBAN}
                  </label>
                  <Input
                    type="number"
                    onKeyDown={(evt) => handleKeyDown(evt)}
                    min="0"
                    autoComplete="new-password"
                    fluid
                    placeholder={t.vendors.form.IBAN_placeholder}
                    value={data.IBAN}
                    onChange={onValuesChange('IBAN')}
                  />
                </Form.Field>

                <Form.Field>
                  <label className="label-item">
                  {t.vendors.form.disbursement_currency}
                  </label>
                  <Dropdown
                    placeholder={t.vendors.form.add_disbursement_currency}
                    noResultsMessage={t.common.no_results_found}
                    fluid
                    search
                    selection
                    clearable
                  
                    options={currencies}
                    value={data?.disbursement_currency}
                    onChange={onValuesChange("disbursement_currency")}
                  />
                </Form.Field>
              </div>
            </div>

            {/* {generatedCustomDataFields()} */}
            {/* <div className="buttun-type-link" basic onClick={addCustomData}>
            <Icon name="add" />
            Custom Data
          </div>

          <Divider /> */}

            <div
              className={`form-button-container ${
                activePane == 1 ? 'mt-8' : 'mt-8'
              }`}
            >
              <button
                content={t.common.cancel}
                className="form-action-button"
                basic
                type="button"
                onClick={() =>
                  initChange ? setNewCancelModal(true) : cancelHandler()
                }
              >
                {t.common.cancel}
              </button>
              {activePane == 1 ? (
                <button
                  primary
                  type="button"
                  onClick={updateCustomerHandler}
                  className="form-action-button blue"
                >
                  {t.common.submit}
                </button>
              ) : (
                <button
                  className="form-action-button blue"
                  type="button"
                  primary
                  onClick={() => {
                    // setFieldValue('tab', activePane + 1).then((errors) => {
                    changeTab(activePane + 1);
                    //   if (errors) {
                    //     scrollToError();
                    //   }
                    // });
                  }}
                >
                  {t.common.next}
                </button>
              )}
            </div>

            {/* <div>
            <Button
              content={t.common.update}
              primary
              onClick={updateCustomerHandler}
              loading={buttonStatus == 1}
            />

            <Button
              content={t.common.cancel}
              basic
              onClick={() =>
                initChange ? toggleCancelModal(true) : cancelHandler()
              }
            />
          </div> */}
          </div>
        </Resizable>
      </div>
      <div
        className={
          toggled ? 'aside-modal-background active' : 'aside-modal-background'
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}
      {/* <Modal
        size="tiny"
        open={successModal}
        onClose={() => {
          toggleSuccessModal(false);
          setRefresh(!refresh);
        }}
      >
        <Modal.Content>
          <div style={{ textAlign: 'center' }}>
            <div className="checkmark-wrapper">
              <Icon name="checkmark" color="white" size="huge" />
            </div>
            <p className="status-modal-popup-message">
              {t.vendors.form.vendor_updated_success}
            </p>
          </div>
        </Modal.Content>
        <Modal.Actions>
          <Button
            color="black"
            onClick={() => {
              toggleSuccessModal(false);
              setRefresh(!refresh);
            }}
          >
            {t.common.ok}
          </Button>
        </Modal.Actions>
      </Modal> */}
{toggleAsideModal &&
<AddLocation
        toggled={toggleAsideModal}
        allIds={allIds}
        role={role}
        currentUserId={currentUserId}
        untoggle={() => {
          setToggleAsideModal(false);
          setToggleEditModal(true);
          setModalWidth(Math.max(window.innerWidth / 2, 514));
          // setSelectedParentLocation(null);
        }}
        setRefresh={setRefresh}
        refresh={refresh}
        parentLocation={null}
        fromCustomer={true}
        handleSuccess={async (res) => {
          if (!(role === "super_admin" || role === "admin")) {
            setData({
              ...data,
              location: res?.data?.id,
            });

            let updatedAllIds = [...allIds];
            const [userData] = await Promise.all([getUserData(user.id)]);

            dispatch(props.fulfillUser(userData));
            userData?.locations?.length &&
              userData.locations.forEach((location) => {
                if (!updatedAllIds.some((inv) => inv == location?.id)) {
                  updatedAllIds.push(location?.id);
                }

                location.subLocations.forEach((subLocation) => {
                  if (!updatedAllIds.some((inv) => inv == subLocation?.id)) {
                    updatedAllIds.push(subLocation?.id);
                  }
                });
              });

            setAllIds(updatedAllIds);
            await fetchLocationsOptions(updatedAllIds);
          } else {
            setToggleAsideModal(false);
            setToggleEditModal(true);

            setData({
              ...data,
              location: res?.data?.id,
            });
          }
        }}
      />}

<NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: '24px' }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>
    </>
  );
}

export default connect(null, auth.actions)(Edit);
