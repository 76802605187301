import React, { useState, useEffect, createRef } from "react";
import {
  Popup,
  Header,
  Modal,
  List,
  Dropdown,
  Grid,
  Input,
  Form,
  Button,
  Divider,
  Icon,
  Checkbox,
  TextArea,
  Label,
  Radio,
  Dimmer,
  Loader,
} from "semantic-ui-react";
import ReactDOMServer from "react-dom/server";
import { format, setDate } from "date-fns";
import calendar_icon from "../../../Assets/Icons/calender-icon.svg";
import clock_icon from "../../../Assets/Icons/clock.svg";
import { useCancelModal } from "../../../hooks/Messages/useCancelModal";
import location_icon from "../../../Assets/Icons/location_on.jpg";
import parent_icon from "../../../Assets/Icons/parentAsset.png";
import child_icon from "../../../Assets/Icons/childAsset.png";
import gchild_icon from "../../../Assets/Icons/grandChild.png";
import sub_location_icon from "../../../Assets/Icons/pin_drop.png";
import moment from "moment";
import {
  getActiveUsersByRole,
  getAllAssetsByCustomer,
  getAllTeams,
  uploadFile,
  IMAGES_URL,
  getAllLocations,
  createNewWorkOrder,
  createNewSchedule,
  updateWorkRequestStatus,
  getAllCustomers,
  getAllProcedures,
  getAllPartsByAssets,
  toggle,
  handleKeyDown,
  getAllAssets,
  getAllAreaByLocationId,
  getAllParts,
  getAllSets,
  getAllParentLocations,
  getAllAssetsOption,
  currencyDecimalPlaces,
  getAllWOPartsData,
  fetchInvLocationsOptions,
} from "../../config/functions";

import md5 from "md5";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { selectTranslations } from "../../config/i18n/slice";

import AddCustomerModal from "./popups/add-customer";
import AddLocationModal from "./popups/add-location";
import AddPeopleModal from "./popups/add-people";
import AddProcedureModal from "../Procedures/add";
import AddAssetModal from "./popups/add-asset";
import AddPartModal from "./popups/add-part";
import AddTeamModal from "./popups/add-team";
import { LetteredAvatarImageUrl } from "../../components/common/userProfilePlaceholder";
import ar from "date-fns/locale/ar-SA";
import enGB from "date-fns/locale/en-GB/index.js";
import "react-datepicker/dist/react-datepicker.css";
import { toast } from "react-toastify";
import { useDropzone } from "react-dropzone";
import { useSuccessModal } from "../../../hooks/Messages/useSuccessModal";
import QRCode from "react-qr-code";
import {
  AddIconWithCircle,
  AddImage,
  DeleteIcon,
  Success,
  TextSnippet,
  CategoryInspection,
  CategoryMechanical,
  CloseIcon,
  CopyIcon,
  Damage,
  Electrical,
  HeadingIcon,
  OperationProcedure,
  Preventive,
  Project,
  Refrigeration,
  Safety,
  AddFile,
  ClockIcon,
  PriceIcon,
  CalenderGreyIcon,
  CatgoryPreventive,
  CorrectiveIcon,
  PlannedIcon,
  UnPlannedIcon,
  LocationDropDownIcon,
  SubLocationDropDownIcon,
  QRcodeBlueIcon,
  CustomerPlusIcon,
  SearchIcon,
  ArrowLeft,
  ArrowRight,
} from "../../../Assets/Icons/svg";
import Asterisk from "../../components/common/Asterisk";
import DatePicker from "react-multi-date-picker";
import { scrollToError } from "../../../utilFunctions/scrollToError";
import ToolTip from "react-power-tooltip";
import { fetchWOInitData } from "../../../redux/reducers/workOrderReducer";
import CategoryDropdown from "../../components/common/CategoryDropdown";
import PartsDropdown from "../../components/common/PartsDropdown";
import { TrendingUp } from "@material-ui/icons";
import { Resizable } from "re-resizable";
import useNewCancelModal from "../../../hooks/Messages/useNewCncelModal";
import _ from "lodash";
import { usePermissionsSimplified } from "../../../hooks/usePermissionsSimplified";
import DataTable from "react-data-table-component";
import Pagination from "../../components/common/CommonPagination";
import useDisplayFormattedText from "../../../hooks/useDisplayFormattedText";

/*
 * The FileUpload component in JavaScript allows users to upload files with customizable drag and drop functionality.
 */
const FileUpload = ({
  onFileChange,
  name = "",
  attachments = {},
  setAttachments = () => {},
}) => {
  const { getRootProps, getInputProps } = useDropzone({
    onDrop: (acceptedFiles) => {
      onFileChange(acceptedFiles, name);
    },
  });
  const t = useSelector(selectTranslations);
  //TO DO : Customize and Style this Drag and Drop to Upload box as you want🧑‍💻😊
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {name === "image" ? (
        attachments?.image?.name ? (
          <div className="image-dropzone text-center mb-5">
            <label>
              {attachments?.image?.name}
              <span
                className="ml-3 mr-3 c-pointer"
                onClick={(e) => {
                  e.stopPropagation();
                  setAttachments({
                    ...attachments,
                    image: null,
                  });
                }}
              >
                <DeleteIcon />
              </span>
            </label>
          </div>
        ) : (
          <div className="image-dropzone text-center mb-5">
            <div>
              <p className="p1">
                <span className="mr-2 ml-2">
                  <AddImage />
                </span>
                <span className="mt-1">{t.workOrders.drag_img}</span>
                <span className="mt-1" style={{ color: "#0C66E4" }}>
                  {" "}
                  {t.workOrders.upload_a_file}
                </span>{" "}
              </p>
            </div>
          </div>
        )
      ) : attachments?.warrantyFile?.name ? (
        <div className="warrenty-file-dropzone text-center">
          <label>
            {attachments?.warrantyFile?.name}
            <span
              className="ml-3 mr-3 c-pointer"
              onClick={(e) => {
                e.stopPropagation();
                setAttachments({
                  ...attachments,
                  warrantyFile: null,
                });
              }}
            >
              <DeleteIcon />
            </span>
          </label>
        </div>
      ) : (
        <div className="warrenty-file-dropzone d-flex align-items-center justify-content-between">
          <div className="d-flex ml-4 mr-4">
            <TextSnippet />
            <div className="pl-4 pr-4">
              <p className="p1 m-0">{t.workOrders.form.file}</p>
              <p className="p2 m-0">{t.workOrders.form.attach_file}</p>
            </div>
          </div>
          <div>
            <AddIconWithCircle />
          </div>
        </div>
      )}
    </div>
  );
};

function Add({
  toggled,
  untoggle,
  fromWR = false,
  dueDateParam,
  request,
  setRefresh,
  refresh,
  setFilterOptions,
  setSelectedDates,
  setSearchTitle,
  WRtitle,
  WRdescription,
  setWRdata,
  WRordertype,
  WRID,
  WRpriority,
  WRasset,
  WRlocation,
  WRstatus,
  WRFile,
  modalPrevWidth,
  setmodalPrevWidth,
  allIds,
  roleData,
  currentUserId,

  handleSuccess = () => {},
}) {
  const t = useSelector(selectTranslations);
  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });
  const [partsData, setPartsData] = useState([]);
  const [partSearchTitle, setPartSearchTitle] = useState("");
  const {
    userPermissions,
    checkForLinkPermission,
  } = usePermissionsSimplified();
  const [areAllSelected, setAreAllSelected] = useState(false);
  const [attachments, setAttachments] = useState({
    image: null,
    warrantyFile: null,
  });

  useEffect(() => {
    const fetchFile = async () => {
      if (WRFile && WRFile.file instanceof Promise) {
        const resolvedFile = await WRFile.file;
        setAttachments((prevAttachments) => ({
          ...prevAttachments,
          image: {
            ...WRFile,
            file: resolvedFile,
          },
        }));
      } else if (WRFile) {
        setAttachments((prevAttachments) => ({
          ...prevAttachments,
          image: WRFile,
        }));
      }
    };

    fetchFile();
  }, [WRFile]);
  const customStyles = {
    headCells: {
      style: {
        backgroundColor: "#E9F2FF",
        paddingTop: "8px", // override the cell padding for head cells
      },
    },
    cells: {
      style: {
        paddingTop: "20px",
        paddingBottom: "8px",
      },
    },
  };
  const dispatch = useDispatch();

  const organisationCurrency = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.currency;
    } else {
      return "JOD";
    }
  });

  const handleRowSelected = (row, checked, selectedPartsData = []) => {
    let allSelectedRows = [...selectedPartsData];
    let selectedRows = [...prevPartsData];

    if (checked) {
      allSelectedRows.push({ ...row, selectedPart: true, selectedPart: true });
      selectedRows.push({ ...row, selectedPart: true });
    } else {
      allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
      selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
    }

    const updatedData = [...partsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart: allSelectedRows.some((el) => el.id === item.id),
    }));

    // Check if all rows are selected to update "Select All" checkbox
    const allRowsSelected = updatedData.every((item) => item.selectedPart);

    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRows);
    setPrevPartsData(selectedRows);
    setAreAllSelected(allRowsSelected);
  };
  // const handleRowSelected = (row, checked, selectedPartsData = []) => {

  //   let allSelectedRows = [...selectedPartsData];
  //   console.log('allSelectedRows: ', allSelectedRows);
  //   let selectedRows = [...prevPartsData];

  //   if (checked) {
  //     console.log('checked: ', checked);
  //     allSelectedRows.push({ ...row, selectedPart: true });
  //     selectedRows.push({ ...row, selectedPart: true });
  //   } else {
  //     allSelectedRows = allSelectedRows.filter((el) => el?.id !== row?.id);
  //     selectedRows = selectedRows.filter((el) => el?.id !== row?.id);
  //   }

  //   const updatedData = [...partsData];
  //   const updatedInitData = [...initPartsData];

  //   const selectedRowIds = allSelectedRows.map((row) => row.id);

  //   updatedData.forEach((item) => {
  //     if (selectedRowIds?.length === 0) {
  //       // If selectedRowIds is empty, set selectedPart to false for all items
  //       item.selectedPart = false;
  //     } else {
  //       // Otherwise, set selectedPart to true if the item is in selectedRowIds array
  //       item.selectedPart = selectedRowIds.includes(item.id);
  //     } // Set selectedPart to true if the item is in selectedRowIds array
  //   });

  //   updatedInitData.forEach((item) => {
  //     if (selectedRowIds?.length === 0) {
  //       // If selectedRowIds is empty, set selectedPart to false for all items
  //       item.selectedPart = false;
  //     } else {
  //       // Otherwise, set selectedPart to true if the item is in selectedRowIds array
  //       item.selectedPart = selectedRowIds.includes(item.id);
  //     } // Set selectedPart to true if the item is in selectedRowIds array
  //   });
  //   // Move selected rows to the top
  //   selectedRowIds.forEach((selectedRowId, index) => {
  //     const selectedIndex = updatedData.findIndex(
  //       (item) => item.id === selectedRowId
  //     );
  //     // if (selectedIndex !== -1 && selectedIndex !== index) {
  //     //   const [selectedRow] = updatedData.splice(selectedIndex, 1); // Remove selected row
  //     //   updatedData.splice(index, 0, selectedRow); // Insert selected row at the top
  //     // }

  //     const selectedPartIndex = updatedData.findIndex(
  //       (item) => item.id === selectedRowId
  //     );
  //     if (selectedPartIndex !== -1) {
  //       updatedData[selectedPartIndex].selectedPart = true; // Update selected
  //     }

  //     const selectedInitIndex = updatedInitData.findIndex(
  //       (item) => item.id === selectedRowId
  //     );
  //     // if (selectedInitIndex !== -1 && selectedInitIndex !== index) {
  //     //   const [selectedRow] = updatedInitData.splice(selectedInitIndex, 1); // Remove selected row
  //     //   updatedInitData.splice(index, 0, selectedRow); // Insert selected row at the top
  //     // }
  //     const selectedInitPartIndex = updatedInitData.findIndex(
  //       (item) => item.id === selectedRowId
  //     );
  //     if (selectedInitPartIndex !== -1) {
  //       updatedInitData[selectedInitPartIndex].selectedPart = true; // Update selected
  //     }
  //   });

  //   console.log("allSelectedRows: ", allSelectedRows);
  //   // Update state with the modified data
  //   setPartsData(updatedData);
  //   setInitPartsData(updatedInitData);
  //   setSelectedTablePartData(allSelectedRows);
  //   setPrevPartsData(selectedRows);

  //   // setPrevPartsData(selectedRows)
  //   // setFieldValue('parts', allSelectedRows);
  // };

  const handleSelectAll = (checked) => {
    let updatedData = [...partsData];
    let selectedRows = [...prevPartsData];
    let allSelectedRowsData = [...selectedTablePartData]; // Clone the existing selected data
    console.log('allSelectedRowsData: ', allSelectedRowsData);

    if (checked) {
      // If checked, mark all rows as selected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: true }
      );

      // Add the selected parts to allSelectedRowsData, avoiding duplicates
      updatedData.forEach((item) => {
        if (!item.disable) {
          const alreadySelected = allSelectedRowsData.some(
            (selectedItem) => selectedItem.id === item.id
          );

          if (!alreadySelected) {
            allSelectedRowsData.push({ ...item, selectedPart: true });
            selectedRows.push({ ...item, selectedPart: true });
          }
        }
      });
    } else {
      // If unchecked, mark all rows as deselected, but skip if disabled
      updatedData = updatedData.map((item) =>
        item.disable
          ? item // Skip if disabled
          : { ...item, selectedPart: false }
      );

      // Remove the deselected parts from allSelectedRowsData
      allSelectedRowsData = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );

      selectedRows = allSelectedRowsData.filter(
        (selectedItem) =>
          !updatedData.some(
            (updatedItem) =>
              updatedItem.id === selectedItem.id && !updatedItem.disable
          )
      );
    }

    const updatedInitData = [...initPartsData].map((item) => ({
      ...item,
      selectedPart:
        !item.disable && allSelectedRowsData.some((el) => el.id === item.id),
    }));

    // Update state
    setPartsData(updatedData);
    setInitPartsData(updatedInitData);
    setSelectedTablePartData(allSelectedRowsData); // Update selected rows
    setPrevPartsData(selectedRows); // Update previous state
    setAreAllSelected(checked); // Update the "Select All" state
  };

  const handleAddPartData = async (setFieldValue) => {
    setAddPartModal(false);
    setPrevPartsData([]);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1)
    setSelectedFinalPartData(selectedTablePartData);
    // setFieldValue('orderItems.items', selectedTablePartData);
  };
  const { DisplayFormattedText } = useDisplayFormattedText();
  const [initChange, setInitChange] = useState(false);
  const [cancelModal, toggleCancelModal] = useState(false);
  const [buttonStatus, setButtonStatus] = useState(0);
  const [successModal, toggleSuccessModal] = useState(false);
  const [responseData, setResponseData] = useState({});
  const { setNewCancelModal, NewCancelModal } = useNewCancelModal();
  const { setSuccessModal, SuccessModal } = useSuccessModal();

  const [addCustomerModal, toggleAddCustomerModal] = useState(false);
  const [addLocationModal, toggleAddLocationModal] = useState(false);
  const [addPeopleModal, toggleAddPeopleModal] = useState(false);
  const [addTeamModal, toggleAddTeamModal] = useState(false);
  const [dayFlag, setDayFlag] = useState(false);
  const [addProcedureModal, toggleAddProcedureModal] = useState(false);
  const [addAssetModal, toggleAddAssetModal] = useState(false);
  const [loading, setLoading] = useState(false);

  const [selectedTablePartData, setSelectedTablePartData] = useState([]);
  const [selectedFinalPartData, setSelectedFinalPartData] = useState([]);
  const [allPartsData, setAllPartsData] = useState([]);
  const [prevPartsData, setPrevPartsData] = useState([]);
  const [initPartsData, setInitPartsData] = useState([]);
  const [selectedInvLoc, setSelectedInvLoc] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(50);
  // const [addPartModal, toggleAddPartModal] = useState(false);
  const [addPartModal, setAddPartModal] = useState(false);
  const [customerText, setCustomerText] = useState("");
  const [locationText, setLocationText] = useState("");
  const [peopleText, setPeopleText] = useState("");
  const [teamText, setTeamText] = useState("");
  const [procedureText, setProcedureText] = useState("");
  const [assetText, setAssetText] = useState("");
  const [partText, setPartText] = useState("");

  const [options, setOptions] = useState({
    customers: [],
    inv_location: [],
    locations: [],
    area: [],
    partsData: [],
    sets: [],
    priority: [
      {
        key: 0,
        value: "low",
        text: t.workOrders.form.priority_options[1],
      },

      {
        key: 1,
        value: "medium",
        text: t.workOrders.form.priority_options[2],
      },
      {
        key: 2,
        value: "high",
        text: t.workOrders.form.priority_options[3],
      },
    ],

    repeatEvery: [
      {
        key: 0,
        value: "daily",
        text: t.workOrders.details_view.daily,
      },

      {
        key: 1,
        value: "weekly",
        text: t.workOrders.details_view.weekly,
      },
      {
        key: 2,
        value: "monthly",
        text: t.workOrders.details_view.monthly,
      },
      {
        key: 1,
        value: "yearly",
        text: t.workOrders.details_view.yearly,
      },
    ],
    people: [],
    teams: [],
    assets: [],
    parts: [],
    procedures: [],
    weeks: [],
    months: [],
    monthsDays: [],
    years: [],
    orderTypes: [
      {
        key: 11,
        value: "",
        text: t.workOrders.form.order_type,
      },
      {
        key: 0,
        value: "reactive",
        text: t.workOrders.form.order_type_1,
      },
      {
        key: 1,
        value: "scheduled",
        text: t.workOrders.form.order_type_2,
      },
    ],
    categories: [
      {
        key: 0,
        value: "damage",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Damage />
            </span>
            {t.workOrders.form.category_options[0]}
          </span>
        ),
      },
      {
        key: 1,
        value: "corrective action Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CorrectiveIcon />
            </span>
            {t.workOrders.form.category_options[1]}
          </span>
        ),
      },
      {
        key: 2,
        value: "planned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <PlannedIcon />
            </span>
            {t.workOrders.form.category_options[2]}
          </span>
        ),
      },
      {
        key: 3,
        value: "unplanned Downtime",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <UnPlannedIcon />
            </span>
            {t.workOrders.form.category_options[3]}
          </span>
        ),
      },
      {
        key: 4,
        value: "inspection",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryInspection />
            </span>
            {t.workOrders.form.category_options[4]}
          </span>
        ),
      },
      {
        key: 5,
        value: "electrical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Electrical />
            </span>
            {t.workOrders.form.category_options[5]}
          </span>
        ),
      },

      {
        key: 6,
        value: "mechanical",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CategoryMechanical />
            </span>
            {t.workOrders.form.category_options[6]}
          </span>
        ),
      },

      {
        key: 7,
        value: "HVAC",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Refrigeration />
            </span>
            {t.workOrders.work_order_category.HVAC}
          </span>
        ),
      },
      {
        key: 8,
        value: "preventive maintenance",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <CatgoryPreventive />
            </span>
            {t.workOrders.form.category_options[8]}
          </span>
        ),
      },
      {
        key: 9,
        value: "project",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Project />
            </span>
            {t.workOrders.form.category_options[9]}
          </span>
        ),
      },
      {
        key: 10,
        value: "safety",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <Safety />
            </span>
            {t.workOrders.form.category_options[10]}
          </span>
        ),
      },

      {
        key: 11,
        value: "sop",
        text: (
          <span>
            <span className="ml-2 mr-2">
              <OperationProcedure />
            </span>
            {t.workOrders.form.category_options[11]}
          </span>
        ),
      },
    ],
  });
  const getPaginatedData = (dataToPaginate) => {
    const startIndex = (currentPage - 1) * pageSize;
    const endIndex = startIndex + pageSize;
    return dataToPaginate.slice(startIndex, endIndex);
  };

  const handleDecimalPoints = (value) => {
    const decimalPlaces = currencyDecimalPlaces[organisationCurrency] ?? 2; // Default to 2 if currency not found
    const factor = Math.pow(10, decimalPlaces);
    const roundedValue = Math.round(value * factor) / factor;
    const formattedValue = roundedValue.toFixed(decimalPlaces);

    // Remove trailing zeros after decimal point
    if (formattedValue.indexOf(".") !== -1) {
      return formattedValue.replace(/\.?0+$/, "");
    }

    return Number(formattedValue);
  };

  const addParts = async (selectedPartsData = [], fetchdata = false) => {
    setAreAllSelected(false);
    setInitChange(true);
    setAddPartModal(true);
    if (partsData?.length <= 0 || fetchdata) {
      setLoading(true);
      const fetchData = await getAllWOPartsData(organisationId);
    

      if (fetchData) {
        if (fetchData?.length == 0) {
          setLoading(false);
          // setNoPartModal(true);
        } else {
          let allPartData = fetchData?.map((part) => {
            return {
              id: `${part.countId}-${part?.inventoryLocation?.id}`,
              part: part?.id,
              type: part.type,
              countId: part?.countId,
              name: part?.name,
              totalCost:part?.inventoryLocation?.unitCost
                ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                : 0,
              quantity: 1,
              unitCost: part?.inventoryLocation?.unitCost
                ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                : 0,
              unitType: part?.measuringUnit,
              selectedPart: false,
              location: part?.inventoryLocation?.id,
              location_name: part?.inventoryLocation?.name,
              asset: part?.relatedAsset,
              status: part?.inventoryLocation?.status,
              disable:
                part?.inventoryLocation?.status == "outOfStock" ? true : false,
              availableQty: part?.inventoryLocation?.availableQuantity
                ? part?.inventoryLocation?.availableQuantity
                : 0,
            };
          });
          const filteredParts = fetchData
            ?.map((part) => {
              // let selectedPart = selectedPartsData.find(
              //   (el) => el?.id == part?.id
              // );

              const isSelected = selectedPartsData.some(
                (selectedPart) => selectedPart.id === part.id
              );

              if (isSelected) {
                return null; // Skip this part
              } else {
                return {
                  id: `${part.countId}-${part?.inventoryLocation?.id}`,
                  type: part.type,
                  part: part?.id,
                  countId: part?.countId,
                  name: part?.name,
                  // totalCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                    totalCost: part?.inventoryLocation?.unitCost
                      ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                      : 0,
                  quantity: 1,
                  unitCost: part?.inventoryLocation?.unitCost
                    ? handleDecimalPoints(part?.inventoryLocation?.unitCost)
                    : 0,
                  // unitCost: part?.unitCost
                  //   ? handleDecimalPoints(part?.unitCost)
                  //   : 0,
                  unitType: part?.measuringUnit,
                  selectedPart: false,
                  location: part?.inventoryLocation?.id,
                  location_name: part?.inventoryLocation?.name,
                  asset: part?.relatedAsset,
                  status: part?.inventoryLocation?.status,
                  disable:
                    part?.inventoryLocation?.status == "outOfStock"
                      ? true
                      : false,
                  availableQty: part?.inventoryLocation?.availableQuantity
                    ? part?.inventoryLocation?.availableQuantity
                    : 0,
                };
              }
            })
            .filter((part) => part !== null);

          setPartsData(filteredParts);
          setInitPartsData(filteredParts);
          setAllPartsData(allPartData);
          setLoading(false);
          if (!fetchdata) {
            setAddPartModal(true);
          }
        }
      } else {
        toast.error(t.common.something_wrong);
        setLoading(false);
        if (!fetchdata) {
          setAddPartModal(true);
        }
      }
      setLoading(false);
    } else {
      setLoading(true);
      // setAddPartModal(true);
      const filteredParts = allPartsData
        ?.map((part) => {
          const isSelected = selectedPartsData.some(
            (selectedPart) => selectedPart.id === part.id
          );
          if (isSelected) {
            return null; // Skip this part
          } else {
            return {
              id: part?.id,
              type: part?.type,
              countId: part?.countId,
              part: part?.part,
              name: part?.name,
              totalCost: part.totalCost,
              quantity: part?.quantity ? part?.quantity : null,
              unitCost: part?.unitCost,
              unitType: part?.unitType,
              selectedPart: false,
              location: part?.location,
              location_name: part?.location_name,
              asset: part?.asset,
              status: part?.status,
              disable: part?.disable,
              availableQty: part?.availableQty ? part.availableQty : 0,

              // selectedPart: false,
              // id: part.id,
              // countId: part.countId,
              // name: part.name,
              // totalCost: part.totalCost,
              // quantity: part?.quantity ? part?.quantity : null,
              // unitCost: part.unitCost,
              // unitType: part.unitType,
              // availableQty: part.availableQty,
              // orderedQuantity: part.orderedQuantity,
              // fillMax: part?.fillMax,
            };
          }
        })
        .filter((part) => part !== null);
      setPartsData(filteredParts);
      setInitPartsData(filteredParts);
      setLoading(false);
    }
  };

  const removeFromSelectedParts = (index, selectedPartsData = []) => {
    const updatedSelectedPartsData = [...selectedPartsData];
    updatedSelectedPartsData[index].selectedPart = false;
    updatedSelectedPartsData.splice(index, 1);

    setSelectedFinalPartData(updatedSelectedPartsData);
    handleRowSelected(null, false, updatedSelectedPartsData); // Update the state
  };

  const { initData } = useSelector((state) => ({
    initData: state.asset.assetInitData,
  }));

  const [data, setData] = useState({
    title: WRtitle ? WRtitle : "",
    description: WRdescription ? WRdescription : "",
    customer: "",
    location: WRlocation ? WRlocation : "",
    area: "",
    partsData: [],
    sets: [],
    orderType: WRordertype ? WRordertype : "",
    dueDate: dueDateParam
      ? format(new Date(dueDateParam), "yyyy-MM-dd")
      : format(new Date(), "yyyy-MM-dd"),
    includeDueTime: false,
    dueTime: "",
    repeatEvery: "daily",
    daysRepeat: {
      sat: true,
      sun: true,
      mon: true,
      tue: true,
      wed: true,
      thu: true,
      fri: true,
    },
    repeatEveryWeek: 1,
    repeatEveryMonth: 1,
    repeatMonthDay: "d1",
    repeatEveryYear: 1,
    endRepeat: 10,
    estimationTime: 0,
    laborCost: 0,
    priority: WRpriority ? WRpriority : "low",

    category: "",
    people: [],
    teams: [],
    assets: WRasset ? WRasset : [],
    procedure: "",
    endDate: format(new Date(), "yyyy-MM-dd"),
    endType: "on",
    qrcode: "",
  });

  let allQtyOptions = [
    {
      key: 0,
      value: "pieces",
      text: t.parts.qty_unit.piece,
    },
    {
      key: 1,
      value: "items",
      text: t.parts.qty_unit.items,
    },
    {
      key: 2,
      value: "units",
      text: t.parts.qty_unit.unit,
    },
    {
      key: 3,
      value: "liters (L)",
      text: selectedLanguage == "en" ? "l" : "لتر",
    },
    {
      key: 4,
      value: "gallons (gal)",
      text: selectedLanguage == "en" ? "gal" : "جالون",
    },
    {
      key: 5,
      value: "cubic meters (m³)",
      text: selectedLanguage == "en" ? "m³" : "م³",
    },
    {
      key: 6,
      value: "cubic feet (ft³)",
      text: selectedLanguage == "en" ? "ft³" : "ق³",
    },
    {
      key: 7,
      value: "barrels",
      text: t.parts.vol_unit.barrels,
    },
    {
      key: 8,
      value: "kilograms (kg)",
      text: selectedLanguage == "en" ? "kg" : "كجم",
    },
    {
      key: 9,
      value: "pounds (lb)",
      text: selectedLanguage == "en" ? "lb" : "رطل",
    },

    {
      key: 10,
      value: "tons",
      text: t.parts.mass_unit.tons,
    },
    {
      key: 11,
      value: "metric tonnes",
      text: t.parts.mass_unit.m_tons,
    },
  ];

  let _newData = initPartsData;


  useEffect(() => {
    // Clone the data to apply filters without mutating the original data
    let filteredData = [..._newData];
    setCurrentPage(1)
    // Apply partSearchTitle filter
    if (partSearchTitle.length > 0) {
        const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
        const isMatch = (result) => re.test(result?.name);
        filteredData = _.filter(filteredData, isMatch);
    }

    // Apply selectedInvLoc filter
    if (selectedInvLoc) {
        filteredData = filteredData.filter((part) => part?.location === selectedInvLoc);
    }

    // Check if all parts are selected and set state
    if (filteredData.length > 0) {


      const allSelectedDisable = filteredData
      .every((item) => item.disable === true);

      if(!allSelectedDisable){
        const allSelected = filteredData
        .filter((item) => item.disable === false) // Only include non-disabled items
        .every((item) => item.selectedPart === true);
        setPartsData(filteredData);
  
        setAreAllSelected(allSelected);
      }else{
        setPartsData(filteredData);
        setAreAllSelected(false);
      }
        // const allSelected = filteredData.every((item) => item?.selectedPart === true);
       
    } else {
        setPartsData([]);
    }
}, [partSearchTitle, selectedInvLoc]);
  // useEffect(() => {
  //   if (partSearchTitle.length > 0) {
  //     console.log("inside sss")
  //     const re = new RegExp(_.escapeRegExp(partSearchTitle), "i");
  //     const isMatch = (result) => re.test(result?.name);
  //     _newData =
  //       partSearchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
  //   }
  //   if (_newData?.length > 0) {
  //     let checkdata = _newData?.every((item) => item?.selectedPart == true);
  //     setPartsData(_newData);
  //     setAreAllSelected(checkdata);
  //   } else if(partSearchTitle) {
  //     setPartsData([]);
  //   }
  // }, [partSearchTitle]);

  // useEffect(() => {
  //   if (selectedInvLoc) {
  //     console.log("selectedInvLoc: ", selectedInvLoc);

  //     _newData = _newData?.filter((part) => part?.location == selectedInvLoc);
  //     console.log("_newData: ", _newData);
  //     // const re = new RegExp(_.escapeRegExp(partSearchTitle), 'i');
  //     // const isMatch = (result) => re.test(result?.name);
  //     // _newData =
  //     // partSearchTitle.length > 0 ? _.filter(_newData, isMatch) : _newData;
  //   }
  //   if (_newData?.length > 0) {
  //     let checkdata = _newData?.every((item) => item?.selectedPart == true);
  //     setPartsData(_newData);
  //     setAreAllSelected(checkdata);
  //   } else {
  //     setPartsData([]);
  //   }
  // }, [selectedInvLoc]);

  const HandleUnitType = (data) => {
    let unitType;
    if (data) {
      unitType = allQtyOptions.find((el) => el.value == data);
    }
    return data ? unitType?.text : null;
  };

  const caseSensitiveSearch = (options, query) => {
    const re = new RegExp(_.escapeRegExp(query));
    return options.filter((opt) => re.test(opt.text));
  };

  const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
    (day) => day
  );

  const [validation, setValidation] = useState({
    title: {
      error: true,
      pattern: (title) => title != "",
      skipped: false,
      message: t.common.required_field,
    },
    // customer: {
    //   error: false,
    //   pattern: (customer) => customer != '',
    //   skipped: false,
    //   message: 'Field is required',
    // },
    category: {
      error: true,
      pattern: (category) => category != "",
      skipped: false,
      message: t.common.required_field,
    },

    location: {
      error: true,
      pattern: (location) => location != "",
      skipped: false,
      message: t.common.required_field,
    },
    endRepeat: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "after" &&
        data?.endRepeat == "",
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    endDate: {
      error:
        data?.orderType === "scheduled" &&
        data?.endType === "on" &&
        data?.endDate == null,
      pattern: (endRepeat) => endRepeat !== "",
      skipped: false,
      message: t.common.required_field,
    },
    orderType: {
      error: true,
      pattern: (orderType) => orderType != "",
      skipped: false,
      message: t.common.required_field,
    },
    daysRepeat: {
      // pattern: (customer) => customer != '',
      skipped: false,
      message: t.common.required_field,
      error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
    },

    procedure: {
      error: false,
      pattern: (procedure) => true,
      skipped: false,
      message: t.common.required_field,
    },
  });

  const [validForm, setValidForm] = useState(false);
  const [showCancleTooltip, setCancleTooltip] = useState(false);

  const resetForm = () => {
    setData({
      title: "",
      description: "",
      customer: "",
      location: "",
      orderType: "",
      area: "",
      partsData: [],
      sets: [],
      dueDate: dueDateParam
        ? format(new Date(dueDateParam), "yyyy-MM-dd")
        : format(new Date(), "yyyy-MM-dd"),
      includeDueTime: false,
      dueTime: "",
      repeatEvery: "daily",
      daysRepeat: {
        sat: true,
        sun: true,
        mon: true,
        tue: true,
        wed: true,
        thu: true,
        fri: true,
      },
      repeatEveryWeek: 1,
      repeatEveryMonth: 1,
      repeatMonthDay: "d1",
      repeatEveryYear: 1,
      endRepeat: 10,
      estimationTime: 0,
      laborCost: 0,
      priority: "low",

      category: "",
      people: [],
      teams: [],
      assets: [],
      procedure: "",
      endDate: format(new Date(), "yyyy-MM-dd"),
      endType: "on",
      qrcode: "",
    });
    setPartSearchTitle("");
    setSelectedTablePartData([]);
    setSelectedFinalPartData([]);
    setAllPartsData([]);
    setPrevPartsData([]);
    setAttachments([]);
    setInitPartsData([]);
    setSelectedInvLoc("");
    setPartsData([]);
    setCurrentPage(1);

    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: t.workOrders.form.validation.required_field,
      // },

      daysRepeat: {
        // pattern: (customer) => customer != '',
        skipped: false,
        message: t.common.required_field,
        error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
      },
      orderType: {
        error: true,
        pattern: (orderType) => orderType != "",
        skipped: false,
        message: t.common.required_field,
      },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },

      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },

      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
    });

    setOptions({
      ...options,
      area: [],
      partsData: [],
      sets: [],
      inv_location: [],
    });
    if (fromWR) {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
      setmodalPrevWidth(Math.max(window.innerWidth / 2, 514));
    } else {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }

    setInitChange(false);
  };

  const fetchArea = async (id) => {
    const response = await getAllAreaByLocationId(organisationId, id);

    let the_options_area = [];
    if (response.length > 0) {
      response.forEach((el) => {
        the_options_area.push({
          key: `${el.id}`,
          value: el.id,
          text: `${el.attributes.name}`,
        });
      });
    }

    setOptions((prev) => {
      return {
        ...prev,
        partsData: [],
        sets: [],
        area: the_options_area,
      };
    });
  };

  const handleClosePartModal = () => {
    let newAddedData = [...prevPartsData];

    let parts = [...selectedTablePartData];


    parts = parts?.filter(
      (part) => !newAddedData.some((addedPart) => addedPart.id == part.id)
    );

    setAddPartModal(false);
    setPartSearchTitle("");
    setSelectedInvLoc("");
    setCurrentPage(1)
    setSelectedTablePartData(parts);
    setSelectedFinalPartData(parts);
  };

  // const filterInventoryData = (response, locationId, areaId) => {
  //   let filteredInventory = [];

  //   response.forEach((item) => {
  //     let matchCount = 0;

  //     // Iterate through each part's inventory
  //     item.attributes.parts.forEach((part) => {
  //       // Check each inventory item for the selected location match
  //       part?.part?.data?.attributes?.inventory &&
  //         part.part.data.attributes.inventory.forEach((inv) => {
  //           if (
  //             inv.location.data.id === locationId &&
  //             inv.area.data.id === areaId
  //           ) {
  //             matchCount++;
  //           }
  //         });
  //     });

  //     // If both parts' inventories match the selected location, push to filterArray
  //     if (matchCount >= 2) {
  //       filteredInventory.push({
  //         key: `${item.id}`,
  //         value: `${item.id}`,
  //         text: item?.attributes?.name,
  //         description: '',
  //         disabled: false,
  //       });
  //     } else {
  //       filteredInventory.push({
  //         key: `${item.id}`,
  //         value: `${item.id}`,
  //         text: item?.attributes?.name,
  //         description: t.parts.status.out_of_stock,
  //         disabled: true,
  //       });
  //     }
  //   });

  //   return filteredInventory;
  // };

  //New Inventory data structure

  const getInvLocations = async () =>{
    const fetchData = await fetchInvLocationsOptions(
      organisationId,
    );

    if (fetchData.length > 0) {
      let the_options = fetchData.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.name,
          image: { src: location_icon },
          className: "main-location-dropdown-option",
        };
      });
      setOptions({
        ...options,
        inv_location: the_options,
      });

      return the_options;
    }
   
  }

  const filterInventoryData = (response, locationId, areaId) => {
    let filteredInventory = [];

    response.forEach((item) => {
      let matchCount = 0;
      let setMatchCount = 0;

      // Iterate through each part's inventory
      item?.attributes?.parts?.length > 0 &&
        item.attributes.parts.forEach((part) => {
          // Check each inventory item for the selected location match
          part?.part?.data?.attributes?.inventory &&
            part.part.data.attributes.inventory.forEach((inv) => {
              if (
                inv.location.data.id === locationId &&
                inv.area.data.id === areaId &&
                inv.quantity !== 0
              ) {
                matchCount++;
              }
            });
        });

      // If both parts' inventories match the selected location, push to filteredInventory
      if (item?.attributes?.parts?.length !== 0) {
        if (
          matchCount >= 2 &&
          !filteredInventory.some((inv) => inv.key == item.id)
        ) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: "",
            disabled: false,
          });
        } else if (!filteredInventory.some((inv) => inv.key == item.id)) {
          filteredInventory.push({
            key: `${item.id}`,
            value: `${item.id}`,
            text: item?.attributes?.name,
            description: t?.parts?.status.out_of_stock,
            disabled: true,
          });
        }
      }

      item?.attributes?.sets?.length > 0 &&
        item.attributes.sets.forEach((set) => {
          set?.set?.data?.attributes?.parts &&
            set.set.data.attributes.parts.forEach((part_single) => {
              // Check each inventory item for the selected location match
              part_single?.part?.data?.attributes?.inventory &&
                part_single.part.data.attributes.inventory.forEach((inv) => {
                  if (
                    inv.location.data.id === locationId &&
                    inv.area.data.id === areaId &&
                    inv.quantity !== 0
                  ) {
                    setMatchCount++;
                  }
                });
            });
          if (setMatchCount >= 2) {
            if (
              item?.attributes?.parts?.length == 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: "",
                disabled: false,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: "",
                disabled: false,
              });
            }
          } else {
            if (
              item?.attributes?.parts?.length === 0 &&
              !filteredInventory.some((inv) => inv.key == item.id)
            ) {
              filteredInventory.push({
                key: `${item.id}`,
                value: `${item.id}`,
                text: item?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }

            if (
              !filteredInventory.some((inv) => inv.key == set?.set?.data?.id)
            ) {
              filteredInventory.push({
                key: `${set?.set?.data?.id}`,
                value: `${set?.set?.data?.id}`,
                text: set?.set?.data?.attributes?.name,
                description: t.parts.status.out_of_stock,
                disabled: true,
              });
            }
          }
        });
    });

    return filteredInventory;
  };
  const fetchParts = async (id, areaId) => {
    let the_options_parts = [];
    let the_options_sets = [];
    const response = await getAllParts(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      [],
      [id],
      [areaId]
    );

    if (response?.response?.length > 0) {
      response.response.forEach((el) => {
        // Find the inventory entry that matches the id and areaId
        const matchedInventory = el.attributes.inventory.find(
          (inv) => inv.location.data.id == id && inv.area.data.id == areaId
        );

        // Use the matched inventory's quantity or default to 0 if no match is found
        const quantity = matchedInventory ? matchedInventory.quantity : 0;

        the_options_parts.push({
          key: `${el.id}`,
          value: `${el.id}`,
          text: `${el.attributes.name}`,
          description: `${
            quantity !== 0
              ? `${t.parts.table.qty_in_stock}: ${quantity} ${HandleUnitType(
                  el?.attributes?.measuringUnit
                )}`
              : t.parts.status.out_of_stock
          }`,
          disabled: quantity == 0 ? true : false,
        });
      });
    }

    const setResponse = await getAllSets(
      organisationId,
      "",
      1,
      99999,
      [],
      id,
      areaId
    );

    const inventoryData = filterInventoryData(
      setResponse?.response,
      id,
      areaId
    );
    setOptions((prev) => {
      return {
        ...prev,

        partsData: the_options_parts,
        sets: inventoryData,
      };
    });
  };

  const fetchSets = async () => {
    const setResponse = await getAllSets(organisationId);

    const inventoryData = filterInventoryData(
      setResponse?.response
      // id,
      // areaId
    );
    setOptions((prev) => {
      return {
        ...prev,
        sets: inventoryData,
      };
    });
  };

  const handleRemovePart = (partValue) => {
    const updatedParts = data?.parts.filter((value) => value !== partValue);
    setData({
      ...data,
      parts: updatedParts,
    });
  };

  // useEffect(() => {
  //   if (data.customer) {
  //     setData({
  //       ...data,
  //       assets: [],
  //     });
  //     setOptions({
  //       ...options,
  //       assets: [],
  //     });

  //     fetchLocationsOptions(data.customer).then((resp_data) =>
  //       fetchAssetsOptions(data.customer, resp_data)
  //     );
  //   }
  // }, [data.customer]);

  useEffect(() => {
    const areAnyDaysSelected = Object?.values(data?.daysRepeat).some(
      (day) => day
    );

    if (!areAnyDaysSelected && data?.orderType === "scheduled") {
      setDayFlag(true);
    } else {
      setDayFlag(false);
    }
  }, [data.daysRepeat]);

  useEffect(() => {
    if (toggled) {
      // fetchAssetsOptions();
      // fetchSets();
      fetchLocationsOptions();
      getInvLocations();
    }
  }, [toggled]);

  // useEffect(() => {

  // }, []);

  useEffect(() => {
    if (dueDateParam)
      setData({
        ...data,
        dueDate: dueDateParam
          ? format(new Date(dueDateParam), "yyyy-MM-dd")
          : format(new Date(), "yyyy-MM-dd"),
      });
  }, [dueDateParam]);

  useEffect(() => {
    if (data?.location) {
      setData({
        ...data,
        area: "",
        partsData: [],
        sets: [],
      });

      fetchArea(data?.location);
    }
  }, [data?.location]);

  useEffect(() => {
    if (data?.location && data?.area) {
      setData({
        ...data,
        partsData: [],
        sets: [],
      });

      setOptions((prev) => {
        return {
          ...prev,
          partsData: [],
          sets: [],
        };
      });
      fetchParts(data?.location, data?.area);
    }
  }, [data?.area]);

  /*
   * The `onValuesChange` function in JavaScript handles changes in form field values, updating state based on the type of field (boolean or dropdown) and performing validation.
   */
  const onValuesChange = (name, actualValue) => (event, value) => {
    setInitChange(true);
    let currentFieldValue = "";
    const boolean_fields = ["includeDueTime"];
    const dropdown_fields = [
      "customer",
      "location",
      "area",
      "orderType",
      "category",
      "code",
      "people",
      "teams",
      "assets",
      "parts",
      "procedure",
      "repeatEveryWeek",
      "repeatEveryMonth",
      "repeatMonthDay",
      "repeatEveryYear",
      "endType",
    ];

    if (!boolean_fields.includes(name) || !dropdown_fields.includes(name)) {
      currentFieldValue = event.target.value;
      setData({
        ...data,
        [name]: event.target.value,
      });
    }

    if (boolean_fields.includes(name)) {
      currentFieldValue = value.checked;

      setData({
        ...data,
        [name]: value.checked,
      });
    }

    if (dropdown_fields.includes(name)) {
      currentFieldValue = actualValue || value.value;
      let resulteNewData;
      if (name == "location") {
        resulteNewData = {
          ...data,
          [name]: actualValue || value.value,
          customer: "",
          people: [],
          teams: [],
          assets: [],
        };

        setOptions({
          ...options,
          customers: [],
          people: [],
          teams: [],
          assets: [],
        });
      } else {
        resulteNewData = {
          ...data,
          [name]: actualValue || value.value,
        };
      }

      // if (name == 'location') {
      //   let selectedOption = value.options.find(
      //     (el) => el.value == value.value
      //   );
      //   resulteNewData.people = selectedOption.people;
      //   resulteNewData.teams = selectedOption.teams;
      // }
      setData(resulteNewData);
    }
    let fieldValidation = validation[name];
    if (fieldValidation) {
      let currentValidation = {
        ...validation,
        [name]: {
          ...fieldValidation,
          error: fieldValidation.pattern(currentFieldValue) ? false : true,
        },
      };

      setValidation(currentValidation);
    }
  };

  /*
   * The fetchCustomerOptions function retrieves customer data, processes it, and returns an array of options with key, value, text, image, and locationId properties.
   */
  const fetchCustomerOptions = async () => {
    const fetchData = await await getAllCustomers(
      organisationId,
      "",
      1,
      99999,
      !(roleData === "super_admin" || roleData === "admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = el.attributes.logo.data
          ? IMAGES_URL + el.attributes.logo.data.attributes.url
          : LetteredAvatarImageUrl(el.attributes.name);
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
          locationId: el?.attributes?.location?.data?.id || "",
        };
      });
      setOptions({
        ...options,
        customers: the_options,
      });

      return the_options;
    }
  };

  const svgToDataUrl = (svgComponent) => {
    const svgString = ReactDOMServer.renderToStaticMarkup(svgComponent);
    return `data:image/svg+xml;base64,${btoa(svgString)}`;
  };

  /*
   * The function fetches location options for a specific customer ID by retrieving data and mapping it to create an array of options.
   */
  const fetchLocationsOptions = async (customerID) => {
    const fetchData = await getAllParentLocations(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      ["name:asc"]
    );
    let the_options = [];
    let parentOptions = [];
    let subOptions = [];
    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        if (!(roleData === "super_admin" || roleData === "admin")) {
          // Check if the element's ID is included in allIds
          if (allIds?.includes(el.id)) {
            // Add parent option normally
            parentOptions.push({
              key: el.id,
              value: el.id,
              text: el.attributes.name,
              image: { src: location_icon },
              className: "main-location-dropdown-option",
              disabled: false, // Parent option enabled
            });

            // Collect sub-options
            if (el?.attributes?.subLocations?.data?.length > 0) {
              el.attributes.subLocations.data.forEach((subEl) => {
                if (allIds?.includes(subEl.id)) {
                  subOptions.push({
                    key: subEl.id,
                    value: subEl.id,
                    text: subEl.attributes.name,
                    image: { src: sub_location_icon },
                    className: "sub-location-dropdown-option",
                    parentId: el.id, // Keep track of the parent
                  });
                }
              });
            }
          } else {
            // If the element's ID is not in allIds, check sub-locations
            const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
              (subEl) => allIds?.includes(subEl.id)
            );

            if (hasMatchingSubLocation) {
              // Add parent option as disabled
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                image: { src: location_icon },
                className: "main-location-dropdown-option",
                disabled: true, // Disable the parent option
              });

              // Collect matching sub-options
              el?.attributes?.subLocations?.data?.length > 0 &&
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
            }
          }
        } else {
          // Add parent option
          parentOptions.push({
            key: el.id,
            value: el.id,
            text: el.attributes.name,
            className: "main-location-dropdown-option",
            image: { src: location_icon },
          });

          // Collect sub-options
          if (el?.attributes?.subLocations?.data?.length > 0) {
            el.attributes.subLocations.data.forEach((subEl) => {
              subOptions.push({
                key: subEl.id,
                value: subEl.id,
                text: subEl.attributes.name,
                image: { src: sub_location_icon },
                className: "sub-location-dropdown-option",
                parentId: el.id, // Keep track of the parent
              });
            });
          }
        }
      });

      // Sort sub-options alphabetically
      subOptions.sort((a, b) => a.text.localeCompare(b.text));

      // Merge parent options with sorted sub-options
      parentOptions.forEach((parentOption) => {
        the_options.push(parentOption);
        // Add sorted sub-options belonging to this parent
        subOptions.forEach((subOption) => {
          if (subOption.parentId === parentOption.key) {
            the_options.push(subOption);
          }
        });
      });

      // Update the options state
      setOptions((prev) => ({
        ...prev,
        locations: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetchAssetsOptions fetches assets data for a specific customer and maps it to an array of options.
   */
  // const fetchAssetsOptions = async (customerID, locations) => {
  //   const fetchData = await getAllAssetsOption(
  //     organisationId,
  //     "all_asset",
  //     1,
  //     9999,
  //     !(roleData === "super_admin" || roleData === "admin")
  //       ? data?.location
  //         ? [data?.location]
  //         : allIds
  //       : data?.location
  //       ? [data?.location]
  //       : [],
  //     [],
  //     "",
  //     "",
  //     [],
  //     ["name:asc"]
  //   );

  //   let assetOptions = [];
  //   let childOptions = [];
  //   let grandChildOptions = [];

  //   // Map to store parent-to-child relationships
  //   let parentToChildMap = new Map();
  //   // Map to store child-to-grandchild relationships
  //   let childToGrandChildMap = new Map();

  //   if (fetchData?.response?.length > 0) {
  //     fetchData.response.forEach((el) => {
  //       if (!(roleData === "super_admin" || roleData === "admin")) {
  //         if (allIds?.includes(el?.attributes?.location?.data?.id)) {
  //           const parentAsset = {
  //             key: el.id,
  //             value: el.id,
  //             text: el.attributes.name,
  //             image: { src: parent_icon }, // Use a location icon for the main asset
  //             className: "main-location-dropdown-option",
  //             disabled: false,
  //           };

  //           parentToChildMap.set(el.id, []);
  //           assetOptions.push(parentAsset);

  //           // Collect child assets
  //           if (el?.attributes?.storedAssets?.length > 0) {
  //             el.attributes.storedAssets.forEach((childEl) => {
  //               if (childEl?.childAsset?.data) {
  //                 const childAsset = childEl.childAsset.data;
  //                 const childOption = {
  //                   key: childAsset.id,
  //                   value: childAsset.id,
  //                   text: `${childAsset.attributes.name}`, // Indent the child option with "--"
  //                   image: { src: child_icon }, // Use a sub-location icon for child assets
  //                   className: "sub-location-dropdown-option",
  //                   parentId: el.id, // Track parent asset
  //                 };

  //                 // Add the child option to its parent in the map
  //                 parentToChildMap.get(el.id).push(childOption);
  //                 childOptions.push(childOption);

  //                 // Initialize a child-to-grandchild mapping for this child
  //                 childToGrandChildMap.set(childAsset.id, []);

  //                 // Collect grandchild assets under this child
  //                 if (childEl?.grandChildAsset?.data?.length > 0) {
  //                   childEl.grandChildAsset.data.forEach((grandChildEl) => {
  //                     const grandChildOption = {
  //                       key: grandChildEl.id,
  //                       value: grandChildEl.id,
  //                       text: `${grandChildEl.attributes.name}`, // Indent the grandchild option with "----"
  //                       image: { src: gchild_icon }, // Optionally, use a different icon for grandchild
  //                       className: "grandchild-asset-dropdown-option",
  //                       parentId: el.id, // Track the top-level parent asset
  //                       childParentId: childAsset.id, // Track the immediate child parent ID
  //                     };

  //                     // Add the grandchild to its child in the map
  //                     childToGrandChildMap.get(childAsset.id).push(grandChildOption);
  //                     grandChildOptions.push(grandChildOption);
  //                   });
  //                 }
  //               }
  //             });
  //           }
  //         }

  //       }else{
  //         const parentAsset = {
  //           key: el.id,
  //           value: el.id,
  //           text: el.attributes.name,
  //           image: { src: parent_icon }, // Use a location icon for the main asset
  //           className: "main-location-dropdown-option",
  //           disabled: false,
  //         };

  //         parentToChildMap.set(el.id, []);
  //         assetOptions.push(parentAsset);

  //         // Collect child assets
  //         if (el?.attributes?.storedAssets?.length > 0) {
  //           el.attributes.storedAssets.forEach((childEl) => {
  //             if (childEl?.childAsset?.data) {
  //               const childAsset = childEl.childAsset.data;
  //               const childOption = {
  //                 key: childAsset.id,
  //                 value: childAsset.id,
  //                 text: `${childAsset.attributes.name}`, // Indent the child option with "--"
  //                 image: { src: child_icon }, // Use a sub-location icon for child assets
  //                 className: "sub-location-dropdown-option",
  //                 parentId: el.id, // Track parent asset
  //               };

  //               // Add the child option to its parent in the map
  //               parentToChildMap.get(el.id).push(childOption);
  //               childOptions.push(childOption);

  //               // Initialize a child-to-grandchild mapping for this child
  //               childToGrandChildMap.set(childAsset.id, []);

  //               // Collect grandchild assets under this child
  //               if (childEl?.grandChildAsset?.data?.length > 0) {
  //                 childEl.grandChildAsset.data.forEach((grandChildEl) => {
  //                   const grandChildOption = {
  //                     key: grandChildEl.id,
  //                     value: grandChildEl.id,
  //                     text: `${grandChildEl.attributes.name}`, // Indent the grandchild option with "----"
  //                     image: { src: gchild_icon }, // Optionally, use a different icon for grandchild
  //                     className: "grandchild-asset-dropdown-option",
  //                     parentId: el.id, // Track the top-level parent asset
  //                     childParentId: childAsset.id, // Track the immediate child parent ID
  //                   };

  //                   // Add the grandchild to its child in the map
  //                   childToGrandChildMap.get(childAsset.id).push(grandChildOption);
  //                   grandChildOptions.push(grandChildOption);
  //                 });
  //               }
  //             }
  //           });
  //         }
  //       }
  //       // Create the parent asset option

  //     });

  //     // Sort child and grandchild options alphabetically within each parent and child context
  //     parentToChildMap.forEach((children, parentId) => {
  //       children.sort((a, b) => a.text.localeCompare(b.text));
  //     });

  //     childToGrandChildMap.forEach((grandChildren, childId) => {
  //       grandChildren.sort((a, b) => a.text.localeCompare(b.text));
  //     });

  //     // Combine all the options into a hierarchical structure
  //     let hierarchicalOptions = [];

  //     // Traverse each parent option and append its children and grandchildren
  //     assetOptions.forEach((parentOption) => {
  //       hierarchicalOptions.push(parentOption);

  //       // Add children under this parent
  //       const children = parentToChildMap.get(parentOption.key) || [];
  //       children.forEach((childOption) => {
  //         hierarchicalOptions.push(childOption);

  //         // Add grandchildren under this child
  //         const grandChildren = childToGrandChildMap.get(childOption.key) || [];
  //         grandChildren.forEach((grandChildOption) => {
  //           hierarchicalOptions.push(grandChildOption);
  //         });
  //       });
  //     });

  //     setOptions((prev) => {
  //       return {
  //         ...prev,
  //         assets: hierarchicalOptions,
  //       };
  //     });

  //     return hierarchicalOptions;
  //   }
  // };
  const fetchAssetsOptions = async (customerID, locations) => {

    const fetchData = await getAllAssetsOption(
      organisationId,
      "all_asset",
      1,
      9999,
      data?.location
        ? [data?.location]
        : !(roleData === "super_admin" || roleData === "admin")
        ? data?.location
          ? [data?.location]
          : []
        : [],
      [],
      "",
      "",
      [],
      ["name:asc"]
    );

    let assetOptions = [];
    let parentToChildMap = new Map();
    let childToGrandChildMap = new Map();
    let hierarchicalOptions = [];

    if (fetchData?.response?.length > 0) {
      fetchData.response.forEach((el) => {
        const isDisposed = el?.attributes?.status === "dispose";
    
        // Access rule: Non-admin users can't access certain assets
        const isActive = !isDisposed && (!(roleData === "super_admin" || roleData === "admin")
          ? (data?.location
            ? data?.location == el?.attributes?.location?.data?.id
            : allIds?.includes(el?.attributes?.location?.data?.id))
          : true);
    
        // Filter out disposed or inactive assets with no descendants
        if (isDisposed || (!isActive && !el?.attributes?.storedAssets?.length)) {
          return;
        }
    
        // Create parent asset option
        const parentAsset = {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { src: parent_icon }, // Parent asset icon
          className: "main-location-dropdown-option",
          disabled: !isActive, // Disable only for non-admin roles
        };
    
        let hasAccessibleChildren = false;
        let hasAccessibleGrandchildren = false;
    
        parentToChildMap.set(el.id, []);
        assetOptions.push(parentAsset);
    
        // Collect child assets
        if (el?.attributes?.storedAssets?.length > 0) {
          el.attributes.storedAssets.forEach((childEl) => {
            if (childEl?.childAsset?.data) {
              const childAsset = childEl.childAsset.data;
              const childIsDisposed = childAsset?.attributes?.status === "dispose";
    
              const childIsActive = !childIsDisposed && (!(roleData === "super_admin" || roleData === "admin")
                ? (data?.location
                  ? data?.location== childAsset?.attributes?.location?.data?.id
                  : allIds?.includes(childAsset?.attributes?.location?.data?.id))
                : true);
    
              // Filter out disposed or inactive child assets with no grandchildren
              if (childIsDisposed || (!childIsActive && !childEl?.grandChildAsset?.data?.length)) {
                return;
              }
    
              // Track whether there are any accessible children
              if (childIsActive) hasAccessibleChildren = true;
    
              const childOption = {
                key: childAsset.id,
                value: childAsset.id,
                text: `${childAsset.attributes.name}`,
                image: { src: child_icon }, // Child asset icon
                className: "sub-location-dropdown-option",
                parentId: el.id,
                disabled: !childIsActive, // Disable child based on role
              };
    
              parentToChildMap.get(el.id).push(childOption);
              childToGrandChildMap.set(childAsset.id, []);
    
              // Collect grandchild assets
              if (childEl?.grandChildAsset?.data?.length > 0) {
                childEl.grandChildAsset.data.forEach((grandChildEl) => {
                  const grandChildIsDisposed = grandChildEl?.attributes?.status === "dispose";
    
                  const grandChildIsActive = !grandChildIsDisposed && (!(roleData === "super_admin" || roleData === "admin")
                    ? (data?.location
                      ? data?.location == grandChildEl?.attributes?.location?.data?.id
                      : allIds?.includes(grandChildEl?.attributes?.location?.data?.id))
                    : true);
    
                  // Filter out disposed or inactive grandchild assets
                  if (grandChildIsDisposed || !grandChildIsActive) {
                    return;
                  }
    
                  // Track whether there are any accessible grandchildren
                  if (grandChildIsActive) hasAccessibleGrandchildren = true;
    
                  const grandChildOption = {
                    key: grandChildEl.id,
                    value: grandChildEl.id,
                    text: `${grandChildEl.attributes.name}`,
                    image: { src: gchild_icon }, // Grandchild asset icon
                    className: "grandchild-asset-dropdown-option",
                    parentId: el.id,
                    childParentId: childAsset.id,
                    disabled: !grandChildIsActive, // Disable based on role
                  };
    
                  childToGrandChildMap.get(childAsset.id).push(grandChildOption);
                });
              }
            }
          });
        }
    
        // Ensure the parent asset is displayed if it has no accessible children or grandchildren
        if (!hasAccessibleChildren && !hasAccessibleGrandchildren && isActive) {
          // Clear the child options and only show the parent
          parentToChildMap.set(el.id, []);
        }
      });
    
      // Sort child and grandchild options alphabetically within each parent and child context
      parentToChildMap.forEach((children) => {
        children.sort((a, b) => a.text.localeCompare(b.text));
      });
    
      childToGrandChildMap.forEach((grandChildren) => {
        grandChildren.sort((a, b) => a.text.localeCompare(b.text));
      });
    
      // Combine all the options into a hierarchical structure
      assetOptions.forEach((parentOption) => {
        hierarchicalOptions.push(parentOption);
    
        const children = parentToChildMap.get(parentOption.key) || [];
        children.forEach((childOption) => {
          hierarchicalOptions.push(childOption);
    
          const grandChildren = childToGrandChildMap.get(childOption.key) || [];
          grandChildren.forEach((grandChildOption) => {
            hierarchicalOptions.push(grandChildOption);
          });
        });
      });
      setOptions((prev) => {
            return {
              ...prev,
              assets: hierarchicalOptions,
            };
          });
    
          return hierarchicalOptions;

    } 
  };

  /*
   * The fetchPartsOptions function retrieves parts data based on assets ID and organizes it into options for selection.
   */
  const fetchPartsOptions = async (assetsID) => {
    const fetchData = await getAllPartsByAssets(assetsID, organisationId);

    if (fetchData) {
      let the_options = fetchData.map((el) => {
        const quantity = el.attributes.quantity ? el.attributes.quantity : 0;
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name + "(" + quantity + ")",
          description: el.attributes.asset.data?.attributes.name,
        };
      });

      setOptions({
        ...options,
        parts: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function fetches active users by role and organizes their data into options for a dropdown menu.
   */
  const fetchPeopleOptions = async () => {
    const fetchData = await getActiveUsersByRole(
      ["user", "supervisor", "limited_admin", "admin", "requester"],
      organisationId,
      !(roleData === "super_admin" || roleData === "admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );
    if (fetchData?.data) {
      let the_options = fetchData.data.map((el) => {
        const the_image = el.profileImage?.url
          ? IMAGES_URL + el.profileImage?.url
          : LetteredAvatarImageUrl(el?.firstName + " " + el.lastName);
        let teams_string_array = el?.teams?.map((one) => one.name);
        return {
          key: el.id,
          value: el.id,
          text:
            el.firstName +
            " " +
            el.lastName +
            " (" +
            t.people.roles[el.role.name] +
            ")",
          description: `${teams_string_array.join(" , ")}`,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        people: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches team options data from an API and formats it for display.
   */
  const fetchTeamsOptions = async () => {
    const fetchData = await getAllTeams(
      organisationId,
      "",
      1,
      99999,
      [],
      [],
      [],
      !(roleData === "super_admin" || roleData === "admin")
        ? data?.location
          ? [data?.location]
          : allIds
        : data?.location
        ? [data?.location]
        : []
    );

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        const the_image = LetteredAvatarImageUrl(el.attributes.name);

        return {
          key: el.id,
          value: el.id,
          text: el.attributes.name,
          image: { avatar: true, src: the_image, size: "tiny" },
        };
      });
      setOptions((prevOptions) => ({
        ...prevOptions,
        teams: the_options,
      }));

      return the_options;
    }
  };

  /*
   * The function fetches procedure options from an API and maps the response data to a specific format
   */
  const fetchProceduresOptions = async () => {
    const fetchData = await getAllProcedures(false, organisationId);

    if (fetchData.response) {
      let the_options = fetchData.response.map((el) => {
        return {
          key: el.id,
          value: el.id,
          text: el.attributes.title,
        };
      });

      setOptions({
        ...options,
        procedures: the_options,
      });

      return the_options;
    }
  };

  /*
   * The function `fetchWeekOptions` generates an array of objects representing week options
   */
  const fetchWeekOptions = () => {
    let weeks = [];

    for (let i = 1; i < 21; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      weeks.push(obj);
    }

    setOptions({
      ...options,
      weeks: weeks,
    });
  };

  /*
   * The function `fetchMonthOptions` generates an array of objects representing months from 1 to 12.
   */
  const fetchMonthOptions = () => {
    let months = [];

    for (let i = 1; i < 25; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      months.push(obj);
    }

    setOptions({
      ...options,
      months: months,
    });
  };

  /*
   * The function `fetchMonthDaysOptions` generates an array of objects representing options for selecting days in a month, with special handling for the suffixes 'st', 'nd', 'rd', and 'th'.
   */
  const fetchMonthDaysOptions = () => {
    let monthsDays = [];

    for (let i = 1; i < 32; i++) {
      let day_th = "th";
      if (i == 1) day_th = "st";
      if (i == 2) day_th = "nd";
      if (i == 3) day_th = "rd";

      const obj = {
        key: i,
        value: "d" + i,
        text: i > 28 ? i + day_th + " (or last)" : i + day_th,
      };

      monthsDays.push(obj);
    }

    setOptions({
      ...options,
      monthsDays: monthsDays,
    });
  };


  /*
   * The function `fetchYearsOptions` generates an array of objects representing years
   */
  const fetchYearsOptions = () => {
    let years = [];

    for (let i = 1; i < 11; i++) {
      const obj = {
        key: i,
        value: i,
        text: i,
      };

      years.push(obj);
    }

    setOptions({
      ...options,
      years: years,
    });
  };

  /*
   * The `cancelHandler` function resets form fields, hides modals, and updates validation status for various form fields.
   */
  const cancelHandler = () => {
    if (fromWR) {
      setmodalPrevWidth(Math.max(window.innerWidth / 2, 514));
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    } else {
      setModalWidth(Math.max(window.innerWidth / 2, 514));
    }
    setButtonStatus(0);
    if (WRtitle?.length) {
      setWRdata();
    }

    resetForm();

    untoggle();
    setNewCancelModal(false);
    toggleCancelModal(false);
    setInitChange(false);
    setValidation({
      title: {
        error: false,
        pattern: (title) => title != "",
        skipped: false,
        message: t.common.required_field,
      },
      category: {
        error: false,
        pattern: (category) => category != "",
        skipped: false,
        message: t.common.required_field,
      },
      endRepeat: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },
      endDate: {
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        pattern: (endRepeat) => endRepeat !== "",
        skipped: false,
        message: t.common.required_field,
      },

      // customer: {
      //   error: false,
      //   pattern: (customer) => customer != '',
      //   skipped: false,
      //   message: 'Field is required',
      // },
      orderType: {
        error: true,
        pattern: (orderType) => orderType != "",
        skipped: false,
        message: t.common.required_field,
      },
      location: {
        error: false,
        pattern: (location) => location != "",
        skipped: false,
        message: t.common.required_field,
      },
      // dueDate: {
      //   error: false,
      //   pattern: (date) =>
      //     moment(date).format('YYYY-MM-DD') ===
      //     moment(date).format('YYYY-MM-DD'),
      //   skipped: false,
      //   message: 'Invalid date',
      // },
      // dueTime: {
      //   error: false,
      //   pattern: (time) =>
      //     moment(time).format('HH:mm') === moment(time).format('HH:mm') &&
      //     time != '',
      //   skipped: false,
      //   message: 'Invalid time',
      // },

      procedure: {
        error: false,
        pattern: (procedure) => true,
        skipped: false,
        message: t.common.required_field,
      },
    });
  };

  const uploadAttachmentsHandler = async () => {
    let attachmentsResponse = [];
    let theFiles = attachments
      .filter((el) => el.isChanged)
      .map((el) => el.file);

    if (theFiles.length > 0) {
      let reponse = await uploadFile(theFiles);
      return reponse;
    } else {
      return false;
    }
  };

  /*
   * The `approveOrder` function asynchronously updates the status of a work request to 'approved' using the `updateWorkRequestStatus` function.
   */
  const approveOrder = async (orderID) => {
    const approveOrderResponse = await updateWorkRequestStatus(
      request.id,
      "approved",
      orderID,
      ""
    );

    return approveOrderResponse;
  };
  /*
   * The `onFileChange` function sets a new file attachment with random ID and other details when a file is selected.
   */

  /*
   * The `onFileChange` function sets a new file attachment with random ID and file details when a file is selected.
   */
  const onFileChange = (files, name) => {
    setInitChange(true);
    var randomnumber = Math.floor(Math.random() * (0 - 100 + 1)) + 100;
    setAttachments({
      ...attachments,
      [name]: {
        file: files[0],
        id: randomnumber,
        name: files[0].name,
        type: files[0].type,
        cancelPopup: false,
        isChanged: true,
      },
    });
  };
  /*
   * The `validationHandler` function updates validation properties based on data inputs and checks for any errors in the data.
   */
  const validationHandler = () => {
    let currentValidation = {
      ...validation,
      title: {
        ...validation.title,
        error: validation.title.pattern(data.title) ? false : true,
        skipped: true,
      },
      procedure: {
        ...validation.procedure,
        error: validation.procedure.pattern(data.procedure) ? false : true,
        skipped: true,
      },
      category: {
        ...validation.category,
        error: validation.category.pattern(data.category) ? false : true,
        skipped: true,
      },

      location: {
        ...validation.location,
        error: validation.location.pattern(data.location) ? false : true,
        skipped: true,
      },
      orderType: {
        ...validation.orderType,
        error: validation.orderType.pattern(data.orderType) ? false : true,
        skipped: true,
      },
      endRepeat: {
        ...validation.endRepeat,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "after" &&
          data?.endRepeat === "",
        skipped: true,
      },

      endDate: {
        ...validation.endDate,
        error:
          data?.orderType === "scheduled" &&
          data?.endType === "on" &&
          data?.endDate == null,
        skipped: true,
      },
      daysRepeat: {
        ...validation.daysRepeat,
        error: data?.orderType === "scheduled" ? !areAnyDaysSelected : false,
      },
    };
    setValidation(currentValidation);
    const validationToArray = Object.entries(currentValidation);
    const errorsInData = validationToArray.some((el) => el[1].error);
    return errorsInData;
  };

  const transformDataSet = (dataArray) => {
    return dataArray?.map(data => ({
        set: data?.part,              // Set the `set` field to `part` value
        location: data?.location ? data?.location : null,  // Use `asset` if available, otherwise default to 58
        unitCost:data?.unitCost !==null ?  Number(data?.unitCost) : null, // Use unitCost if positive, else 100
        quantity: data?.quantity !== null ?  data?.quantity : 1,   // Use quantity if positive, else 5
        totalCost:data?.totalCost !==null ?  Number(data?.totalCost) : null// Calculate or default
    }));
}

const transformDataPart =(dataArray)=> {
  return dataArray?.map(data => ({
      part: data?.part,              // Set the `set` field to `part` value
      location: data?.location ? data?.location : null,  // Use `asset` if available, otherwise default to 58
      unitCost:data?.unitCost !==null ?  Number(data?.unitCost) : null, // Use unitCost if positive, else 100
      quantity: data?.quantity !== null ?  data?.quantity : 1,   // Use quantity if positive, else 5
      totalCost:data?.totalCost !==null ?  Number(data?.totalCost) : null// Calculate or default
  }));
}
  /*
   * The function `createNewWorkOrderHandler` handles the creation of a new work order by validating data, uploading attachments, and making API calls to create the work order.
   */
  const createNewWorkOrderHandler = async () => {

    const errorsInData = validationHandler();




    if (!errorsInData) {
      const parts = selectedFinalPartData.filter(item => item.type === 'Part');
      const sets = selectedFinalPartData.filter(item => item.type === 'Set')
      
      let setData = transformDataSet(sets)
      let partData = transformDataPart(parts)
      
      setButtonStatus(1);
      const assetImageReponse =
        attachments.image && attachments.image.isChanged
          ? await uploadFile([attachments.image.file])
          : null;

      const workOrderFile =
        attachments.warrantyFile && attachments.warrantyFile.isChanged
          ? await uploadFile([attachments.warrantyFile.file])
          : null;

      let sendedDueTime = data?.dueTime
        ? format(new Date(data?.dueDate + "T" + data?.dueTime), "kk:mm:ss.SSS")
        : null;

      await createNewAsset2Handler(
        assetImageReponse?.status == 200 ? assetImageReponse.data[0].id : null,
        workOrderFile?.status == 200 ? workOrderFile.data[0].id : null,
        sendedDueTime,
        partData,
        setData
      );
    } else {
      scrollToError();
    }
  };

  /*
   * The function `createNewAsset2Handler` handles the creation of new assets based on certain conditions and performs additional actions based on the response.
   */
  const createNewAsset2Handler = async (image, file, sendedDueTime,partData,setData) => {
    const response =
      data.orderType == "scheduled"
        ? await createNewSchedule(
            data.title,
            data.description,
            data.customer ? data.customer : null,
            data.location,
            data.orderType,
            format(new Date(data.dueDate), "yyyy-MM-dd"),
            data.includeDueTime,
            data?.includeDueTime
              ? sendedDueTime
                ? sendedDueTime
                : "23:59:00.000"
              : "23:59:00.000",
            data.repeatEvery,
            data.daysRepeat,
            data.repeatEveryWeek,
            data.repeatEveryMonth,
            data.repeatMonthDay,
            data.repeatEveryYear,
            data.endRepeat,
            Number(data.estimationTime),
            Number(data.laborCost),
            data.priority,
            data.category,
            data.people,
            data.teams,
            data.assets,
            partData,
            data.procedure ? data.procedure : null,
            image,
            file,
            organisationId,
            currentUserId,
            data.endType === "on"
              ? data.endDate
                ? format(new Date(data.endDate), "yyyy-MM-dd")
                : null
              : null,
            data.endType,
            data.qrcode ? true : false,
            // data.code,
            WRID ? WRID : null,
            setData,
            data?.area ? Number(data.area) : null
          )
        : await createNewWorkOrder(
            data.title,
            data.description,
            data.customer ? data.customer : null,
            data.location,
            data.orderType,
            format(new Date(data.dueDate), "yyyy-MM-dd"),
            data.includeDueTime,
            data?.includeDueTime
              ? sendedDueTime
                ? sendedDueTime
                : "23:59:00.000"
              : "23:59:00.000",
            data.repeatEvery,

            data.daysRepeat,
            data.repeatEveryWeek,
            data.repeatEveryMonth,
            data.repeatMonthDay,
            data.repeatEveryYear,
            data.endRepeat,
            Number(data.estimationTime),
            Number(data.laborCost),
            data.priority,
            data.category,
            data.people,
            data.teams,
            data.assets,
            partData,
            data.procedure ? data.procedure : null,
            image,
            file,
            organisationId,
            currentUserId,
            data.qrcode ? true : false,
            // data.code,
            WRID ? WRID : null,
            setData,
            data?.area ? Number(data.area) : null
          );

    if (response && response.status == 200) {
      if (request) {
        const approveResponse = await approveOrder(response.data.id);
        if (approveResponse.status == 200) {
          setButtonStatus(0);
          untoggle();
          resetForm();
          toggleSuccessModal(true);
          if (WRID) {
            handleSuccess(true);
          } else {
            toast.success(t.workOrders.form.success.msg);
            const currentPathname = window.location.pathname;
            setRefresh(!refresh);
            if (currentPathname == "/work-orders") {
              setSearchTitle("");
              setSelectedDates([]);
              setFilterOptions({
                serialNumber: "",
                assignees: {
                  people: [],
                  teams: [],
                },
                people: [],
                teams: [],
                customers: [],
                locations: [],
                createdByUser: [],
                dueDate: {
                  value: 0,
                  text: "Any Date",
                },
                status: [],
                priority: [],
                categories: [],
              });

              if (initData?.length == 0) {
                const fetchData = await dispatch(
                  fetchWOInitData({
                    organisationId,
                    role: roleData,
                    allIds,
                    currentUserId,
                  })
                );
              }
            }
          }
        }
      } else {
        untoggle();
        resetForm();
        toggleSuccessModal(true);
        if (WRID) {
          untoggle();
          handleSuccess(true);
        } else {
          toast.success(t.workOrders.form.success.msg);
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",
              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],
              status: [],
              priority: [],
              categories: [],
            });

            if (initData?.length == 0) {
              const fetchData = await dispatch(
                fetchWOInitData({
                  organisationId,
                  role: roleData,
                  allIds,
                  currentUserId,
                })
              );
            }
          }
        }
      }
    } else if (response.status == 400) {
      if (response.error && response.error.message) {
        if (
          response.error.message ==
          "Insufficient quantity available for the part added in this scheduled WO."
        ) {
          toast.error(t.workOrders.part_error);
        } else if (
          response.error.message ==
          "Insufficient quantity available for the sets added in this scheduled WO."
        ) {
          toast.error(t.workOrders.set_error);
        } else {
          toast.error(response.error.message);
        }
      } else {
        toast.error(t.common.something_wrong);
      }
    } else {
      toast.error(t.common.something_wrong);
      untoggle();
    }
    setButtonStatus(0);
  };

  // add popups
  const handleAddCustomer = (e, { value }) => {
    setCustomerText(value);

    toggleAddCustomerModal(true);
  };

  const returnAddCustomer = (id) => {
    fetchCustomerOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          customer: id,
        });
      }
    });
  };

  const handleAddProcedure = (e, { value }) => {
    setProcedureText(value);
    toggleAddProcedureModal(true);
  };

  const returnAddProcedure = (id) => {
    fetchProceduresOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          procedure: id,
        });
      }
    });
  };

  const handleAddLocation = (e, { value }) => {
    setLocationText(value);
    toggleAddLocationModal(true);
  };

  const returnAddLocation = (id) => {
    fetchLocationsOptions(data.customer).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        setData({
          ...data,
          location: id,
        });
      }
    });
  };

  const handleAddTeam = (e, { value }) => {
    setTeamText(value);
    toggleAddTeamModal(true);
  };

  const returnAddTeam = (id) => {
    fetchTeamsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.teams;

        picked_before.push(id);
        setData({
          ...data,
          teams: picked_before,
        });
      }
    });
  };

  const handleAddPeople = (e, { value }) => {
    setPeopleText(value);
    toggleAddPeopleModal(true);
  };

  const returnAddPeople = (id) => {
    fetchPeopleOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.people;

        picked_before.push(id);
        setData({
          ...data,
          people: picked_before,
        });
      }
    });
  };

  const handleAddAsset = (e, { value }) => {
    setAssetText(value);
    toggleAddAssetModal(true);
  };

  const returnAddAsset = (id) => {
    fetchAssetsOptions().then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.assets;

        picked_before.push(id);
        setData({
          ...data,
          assets: picked_before,
        });
      }
    });
  };

  // const handleAddPart = (e, { value }) => {
  //   setPartText(value);
  //   toggleAddPartModal(true);
  // };

  const returnAddPart = (id) => {
    fetchPartsOptions(data.assets).then((resp_data) => {
      if (resp_data.map((option) => option.value).includes(id)) {
        const picked_before = data.parts;

        picked_before.push(id);
        setData({
          ...data,
          parts: picked_before,
        });
      }
    });
  };

  const [modalWidth, setModalWidth] = useState(
    fromWR ? modalPrevWidth : Math.max(window.innerWidth / 2, 514)
  );

  return (
    <>
      <div
        style={{ maxWidth: fromWR ? `${modalPrevWidth}px` : `${modalWidth}px` }}
        clearing={true}
        className={
          toggled
            ? "aside-modal po active procedure"
            : "aside-modal po procedure"
        }
      >
        <>
          {" "}
          <Resizable
            size={{
              width: fromWR ? modalPrevWidth : modalWidth,
              height: "auto",
            }}
            minWidth={"514px"}
            onResize={(e, direction, ref, d) => {
              if (fromWR) {
                setmodalPrevWidth(ref.offsetWidth);
                setModalWidth(ref.offsetWidth);
              } else {
                setModalWidth(ref.offsetWidth);
              }
            }}
          >
            <div className="modal-header">
              <div className="flexbox justify-between">
                <div className="name">{t.workOrders.create_WO}</div>
                <div>
                  <span
                    className="c-pointer"
                    style={{ position: "relative" }}
                    onMouseOver={() => setCancleTooltip(true)}
                    onMouseLeave={() => setCancleTooltip(false)}
                    onClick={() =>
                      initChange ? setNewCancelModal(true) : cancelHandler()
                    }
                  >
                    <CloseIcon />
                    <ToolTip
                      color="#fff"
                      backgroundColor="#172B4D"
                      position={
                        selectedLanguage == "en"
                          ? "bottom center"
                          : "bottom center"
                      }
                      show={showCancleTooltip}
                      textBoxWidth="36px"
                      arrowAlign="center"
                      padding="5px 5px 5px 7px"
                      borderRadius="5px"
                      static
                    >
                      <span className="work-order-preview-tooltip">
                        {t.common.close}
                      </span>
                    </ToolTip>
                  </span>
                </div>
              </div>
            </div>
            <div style={{ overflowX: "hidden" }} className="body p-6">
              {buttonStatus == 1 ? (
                <Dimmer active inverted>
                  <Loader inverted content="Loading" />
                </Dimmer>
              ) : (
                ""
              )}
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.title}
                  <Asterisk color="#172B4D" />
                </label>
                <Input
                  autoComplete="new-password"
                  fluid
                  placeholder={t.workOrders.form.title_name}
                  disabled={request?.title ? true : false}
                  value={data.title}
                  onChange={onValuesChange("title")}
                  error={validation.title.error && validation.title.skipped}
                  onBlur={(e) => {
                    if (validation.title.pattern(data.title)) {
                      setValidation({
                        ...validation,
                        title: {
                          ...validation.title,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        title: {
                          ...validation.title,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.title.error && validation.title.skipped ? (
                  <div className="label-error">{validation.title.message}</div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <FileUpload
                  onFileChange={onFileChange}
                  name="image"
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </Form.Field>

              <Form style={{ marginBottom: "20px" }}>
                <Form.Field>
                  <label className="label-item">
                    {t.workOrders.form.description}
                  </label>
                  <TextArea
                    autoComplete="new-password"
                    value={data.description}
                    onChange={onValuesChange("description")}
                    rows={3}
                    fluid
                    placeholder={t.workOrders.form.description_text}
                  />
                </Form.Field>
              </Form>

              <Form.Field>
                <label className="label-item mt-5">
                  {" "}
                  <label className="label-item">
                    {t.workOrders.table.priority}
                  </label>
                </label>
                <Dropdown
                  placeholder={t.workOrders.table.add_priority}
                  fluid
                  selection
                  noResultsMessage={t.common.no_results_found}
                  options={options.priority}
                  value={data.priority}
                  onChange={(e, value) => {
                    let findPriority = value.options.find(
                      (one) => one.value == value.value
                    );
                    if (findPriority) {
                      setData({
                        ...data,
                        priority: value.value,
                      });
                    }
                  }}
                />
              </Form.Field>
              {/* {userPermissions?.procedure?.view ?    */}
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.procedure}

                  {/* <Icon name="asterisk" color="red" size="small" /> */}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.procedure_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  clearable
                  onAddItem={handleAddProcedure}
                  options={options.procedures}
                  value={data.procedure}
                  onChange={onValuesChange("procedure")}
                  onOpen={fetchProceduresOptions}
                  error={
                    validation.procedure.error && validation.procedure.skipped
                  }
                  onBlur={(e) => {
                    if (validation.procedure.pattern(data.procedure)) {
                      setValidation({
                        ...validation,
                        procedure: {
                          ...validation.procedure,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        procedure: {
                          ...validation.procedure,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.procedure.error && validation.procedure.skipped ? (
                  <div className="label-error">
                    {validation.procedure.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>
              {/* : null} */}

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.category} <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.assets.form.select_category}
                  fluid
                  selection
                  search
                  // search={caseSensitiveSearch}
                  options={options.categories}
                  noResultsMessage={t.common.no_results_found}
                  error={
                    validation.category.error && validation.category.skipped
                  }
                  value={data.category}
                  onChange={onValuesChange("category")}
                  onBlur={(e) => {
                    if (validation.category.pattern(data.category)) {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        category: {
                          ...validation.category,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.category.error && validation.category.skipped ? (
                  <div className="label-error">
                    {validation.category.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.location}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.location_placeholder}
                  fluid
                  className="loc-dropdown"
                  selection
                  noResultsMessage={t.common.no_results_found}
                  search={(options, query) => {
                    const filteredOptions = [];
                    const addedKeys = new Set(); // To track and avoid duplicate entries
                  
                    options.forEach((option) => {
                      const isParent = !option.parentId;
                      const matches = option.text.toLowerCase().includes(query.toLowerCase());
                  
                      // If the parent matches, add it and all related sub-locations
                      if (isParent && matches) {
                        if (!addedKeys.has(option.key)) {
                          filteredOptions.push(option);
                          addedKeys.add(option.key);
                        }
                        options.forEach((subOption) => {
                          if (subOption.parentId === option.key && !addedKeys.has(subOption.key)) {
                            filteredOptions.push(subOption);
                            addedKeys.add(subOption.key);
                          }
                        });
                      }
                      // If a sub-location matches, add both the sub-location and its parent
                      else if (!isParent && matches) {
                        const parentOption = options.find((opt) => opt.key === option.parentId);
                        if (parentOption && !addedKeys.has(parentOption.key)) {
                          filteredOptions.push(parentOption);
                          addedKeys.add(parentOption.key);
                        }
                        if (!addedKeys.has(option.key)) {
                          filteredOptions.push(option);
                          addedKeys.add(option.key);
                        }
                      }
                    });
                  
                    return filteredOptions;
                  }}
                  onAddItem={handleAddLocation}
                  options={options.locations}
                  // onOpen={fetchLocationsOptions}
                  value={data.location}
                  onChange={onValuesChange("location")}
                  error={
                    validation.location.error &&
                    validation.location.skipped &&
                    data.customer != ""
                  }
                  onBlur={(e) => {
                    if (validation.location.pattern(data.location)) {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        location: {
                          ...validation.location,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.location.error && validation.location.skipped ? (
                  <div className="label-error">
                    {validation.location.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.parts.filter.area}</label>
                <Dropdown
                  placeholder={t.parts.form.add_area}
                  fluid
                  selection
                  noResultsMessage={t.common.no_results_found}
                  search
                  disabled={
                    !data.location &&
                    !(roleData === "super_admin" || roleData === "admin")
                  }
                  // onAddItem={handleAddLocation}
                  options={options.area}
                  // onOpen={fetchLocationsOptions}
                  value={data.area}
                  onChange={onValuesChange("area")}
                  // error={
                  //   validation.location.error &&
                  //   validation.location.skipped &&
                  //   data.customer != ''
                  // }
                  // onBlur={(e) => {
                  //   if (validation.location.pattern(data.location)) {
                  //     setValidation({
                  //       ...validation,
                  //       location: {
                  //         ...validation.location,
                  //         error: false,
                  //         skipped: true,
                  //       },
                  //     });
                  //   } else {
                  //     setValidation({
                  //       ...validation,
                  //       location: {
                  //         ...validation.location,
                  //         error: true,
                  //         skipped: true,
                  //       },
                  //     });
                  //   }
                  // }}
                />
                {/* {validation.location.error && validation.location.skipped ? (
              <div className="label-error">{validation.location.message}</div>
            ) : (
              ''
            )} */}
              </Form.Field>
              {/* {userPermissions?.customer?.view ?  */}
              <Form.Field>
                <label className="label-item">
                  {t.workOrders.table.customer}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.customer_placeholder}
                  search
                  fluid
                  // disabled={
                  //   !data.location &&
                  //   !(roleData === "super_admin" || roleData === "admin")
                  // }
                  noResultsMessage={t.common.no_results_found}
                  selection
                  onAddItem={handleAddCustomer}
                  options={options.customers}
                  value={data.customer}
                  onChange={(e, value) => {
                    let findCustomer = value.options.find(
                      (one) => one.value == value.value
                    );
                    if (findCustomer) {
                      setData({
                        ...data,
                        customer: value.value,
                      });
                    }
                  }}
                  onOpen={fetchCustomerOptions}
                />
              </Form.Field>
              {/* : null} */}
              {/* {userPermissions?.asset?.view ?  */}
              <Form.Field>
                <label className="label-item">{t.workOrders.form.assets}</label>
                <Dropdown
                  placeholder={t.workOrders.form.assets_placeholder}
                  fluid
                  selection
                  // disabled={
                  //   !data.location &&
                  //   !(roleData === "super_admin" || roleData === "admin")
                  // }
                  noResultsMessage={t.common.no_results_found}
                  multiple
                  className="people-dropdown"
                  search
                  onOpen={fetchAssetsOptions}
                  options={options.assets}
                  value={data.assets}
                  // onChange={onValuesChange("assets")}
                  onChange={(e, value) => {
                    // Find the currently selected options
                    const selectedOptions = options.assets.filter((option) =>
                      value?.value.includes(option.value)
                    );

                    let newSelectedValues = [...value?.value];

                    selectedOptions.forEach((option) => {
                      // When selecting a parent asset
                      if (
                        !option?.disabled &&
                        option.className === "main-location-dropdown-option"
                      ) {
                        // Find child assets of this parent
                        const childOptions = options.assets.filter(
                          (subOption) =>
                            subOption.parentId === option.key &&
                            !subOption.disabled
                        );

                        // Add child assets to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...childOptions.map((child) => child.value),
                        ];

                        // For each child, find its grandchildren (if the child is selected)
                        childOptions.forEach((child) => {
                          const grandChildren = options.assets.filter(
                            (grandChild) =>
                              grandChild.childParentId === child.key &&
                              !grandChild.disabled
                          );

                          // Add grandchildren to the selected values if they are not disabled
                          newSelectedValues = [
                            ...newSelectedValues,
                            ...grandChildren.map(
                              (grandChild) => grandChild.value
                            ),
                          ];
                        });
                      }

                      // When selecting a child asset
                      if (
                        !option?.disabled &&
                        option.className === "sub-location-dropdown-option"
                      ) {
                        // Find grandchild assets of this child
                        const grandChildren = options.assets.filter(
                          (grandChild) =>
                            grandChild.childParentId === option.key &&
                            !grandChild.disabled
                        );

                        // Add grandchildren to the selected values if they are not disabled
                        newSelectedValues = [
                          ...newSelectedValues,
                          ...grandChildren.map(
                            (grandChild) => grandChild.value
                          ),
                        ];
                      }
                    });

                    // Update state with new selected values
                    setData({
                      ...data,
                      assets: [...new Set(newSelectedValues)], // Ensure unique values only
                    });
                  }}
                  // onChange={(e, value) => {

                  //   // Find selected options in the dropdown
                  //   const selectedOptions = options.assets.filter(
                  //     (option) => value?.value.includes(option.value)
                  //   );

                  //   // Create a new list of selected values
                  //   let newSelectedValues = [...value?.value];

                  //   selectedOptions.forEach((option) => {
                  //     // If the selected option is a parent, find its sub-locations
                  //     if (!option?.disabled) {
                  //       const subLocations = options.assets.filter(
                  //         (subOption) => subOption.parentId === option.key
                  //       );

                  //       // Add sub-locations to new selected values
                  //       newSelectedValues = [
                  //         ...newSelectedValues,
                  //         ...subLocations.map((sub) => sub.value),
                  //       ];
                  //     }
                  //   });

                  //   setData({
                  //     ...data,
                  //     assets: [
                  //       ...new Set(newSelectedValues),
                  //     ],
                  //   })

                  //   // Update the form values with the new selected values
                  //   // setFieldValue("locations", [
                  //   //   ...new Set(newSelectedValues),
                  //   // ]);
                  //   // setFieldValue("teams", "");
                  //   // setFieldValue("supervisor", "");
                  // }}
                />
              </Form.Field>

              <label className="label-item mb-0">
                {t.workOrders.form.part_set}
              </label>


              {selectedFinalPartData?.length > 0 ? (
                <>
                  
                  <div className="scroll-data" style={{ overflow: "auto" }}>
                    <div className={"mt-6"}>
                      <div
                        
                        className={`kanbar-header`}
                      >
                        <div class="grid-container-element set wo-form2">
                          <div
                            style={{ minWidth: "86px", backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.workOrders.table.ID}
                          </div>
                          <div
                            style={{ minWidth: "140px",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.workOrders.details_view.name}
                          </div>
                          <div
                            style={{ minWidth: "140px",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.inv_loc}
                          </div>
                          <div
                            style={{ minWidth: "120px",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.parts.table.quantity}
                          </div>{" "}
                          <div
                            style={{ minWidth: "130px",textAlign:"center",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.unit_cost}
                          </div>
                          <div
                            style={{ minWidth: "130px",textAlign:"center",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          >
                            {t.parts.form.total_cost}
                          </div>
                          <div
                            style={{ minWidth: "40px",backgroundColor: "#e9f2ff" }}
                            class="grid-child-element set"
                          ></div>
                        </div>
                      </div>
                    </div>

                    {selectedFinalPartData.map((part, index) => (
                      <div
                        className="grid-container-element set wo-form2 form"
                        key={index}
                      >
                    
                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "86px",
                          }}
                        
                        >
                          {part?.countId ? part.countId : null}
                        </div>

                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "140px",
                          }}
                        
                        >
                          {DisplayFormattedText(part?.name, 13)}
                        </div>
                        <div
                          className="grid-child-element set form"
                          style={{
                            padding: "14px 9px",
                            minWidth: "140px",
                          }}
                        
                        >
                          {DisplayFormattedText(part?.location_name, 13)}
                        </div>
                        <div
                          className="wrap-input-set grid-child-element set form"
                          style={{
                            minWidth: "120px",
                          }}
                        >
                          <Input
                            autoComplete="new-password"
                            style={{ width: "52px", height: "34px" }}
                            type="number"
                            onWheel={(e) => e.target.blur()}
                            onKeyDown={(evt) => handleKeyDown(evt)}
                            value={part?.quantity}
                            className="d-flex"
                            min="1"
                            onChange={(e) => {
                              let allPartData = [...selectedFinalPartData];
                         
                              if (Number(e.target.value)) {
                                let value = parseFloat(e.target.value).toFixed(
                                  4
                                );
                                
                               
                                allPartData[index].quantity = Number(value);

                                let totalCostData = handleDecimalPoints(
                                  Number(value * part?.unitCost)
                                );
                                allPartData[index].totalCost = totalCostData;

                                setSelectedFinalPartData(allPartData);
                              

                             
                              } else {
                                allPartData[index].quantity = "";

                                allPartData[index].totalCost = 0;

                                setSelectedFinalPartData(allPartData);
                             
                              }
                            }}
                            error={selectedFinalPartData[index]?.quantity == ""}
                         
                          >
                            <input />

                            <span
                              className={` align-items-center justify-content-center ${
                                selectedLanguage == "en" ? "ml-2" : "mr-2"
                              }`}
                              style={
                                part?.unitType
                                  ? {
                                      backgroundColor: "#E9F2FF",
                                      color: "#0C66E4",
                                      padding: "3px 4px",
                                      fontSize: "14px",
                                      minWidth:selectedLanguage == "en" ? "33px" : "48px",
                                      textAlign:"center"
                                      
                                    }
                                  : {}
                              }
                            >
                              {part?.unitType
                                ? HandleUnitType(part?.unitType)
                                : null}
                            </span>
                          </Input>
                          {selectedFinalPartData[index]?.quantity == "" ? (
                            <span className="label-error">
                              {t.common.required_field}
                            </span>
                          ) : null}
                        </div>
                        <div
                          className={
                            selectedLanguage == "en"
                              ? "grid-child-element set form mt-2"
                              : "grid-child-element set form mt-2"
                          }
                          style={{
                            minWidth: "130px",
                          }}
                       
                        >
                          <div style={{width:"75px",display:"inline-block",textAlign:"end"}} className="number-cost-set">
                            {DisplayFormattedText(String(part?.unitCost), 7)}
                          </div>
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </div>

                        <div
                          className={
                            selectedLanguage == "en"
                              ? "grid-child-element set form mt-2"
                              : "grid-child-element set form mt-2"
                          } 
                          style={{
                            minWidth: "130px",
                          }}
                        
                         
                        >
                          <div   style={{width:"75px",display:"inline-block",textAlign:"end"}} className="number-cost-set">
                            {DisplayFormattedText(String(part?.totalCost), 7)}
                          </div>
                          <span
                            className={` align-items-center justify-content-center ${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",
                            
                              textAlign:"end"
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </div>

                        <div
                          onClick={() => {
                          
                            removeFromSelectedParts(
                              index,
                              selectedFinalPartData
                            );
                          }}
                          className="grid-child-element set form mt-2"
                        >
                          <DeleteIcon />
                        </div>
                      </div>
                    ))}
                  </div>
                </>
              ) : null}

              <button
                className={`buttun-type-link ${
                  selectedFinalPartData?.length > 0 ? "mt-5" : "mt-2"
                }  mb-7`}
                basic
                type="button"
                style={{ background: "transparent" }}
                // disabled={!values?.locations}
                onClick={() => addParts(selectedTablePartData)}
              >
                <span className="customer-add-btn">
                  {" "}
                  <CustomerPlusIcon />{" "}
                </span>

                <p className={selectedLanguage == "en" ? "ml-2" : "mr-2"}>
                  {t.workOrders.form.add_part_set}
                </p>
              </button>
              {/* : null} */}
              {/* {userPermissions?.part?.view ?  */}
              <>
                {/* <div className="wrapper-parts-dropdown">
                  <PartsDropdown
                    title={t.workOrders.form.select_part}
                    options={options.partsData}
                    // disabled={!data?.location || !data.area}
                    selectedValues={data.partsData}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setData({
                          ...data,
                          partsData: [...data.partsData, e.target.value],
                        });
                      } else {
                        let filteredLocations = data.partsData.filter(
                          (el) => el !== e.target.value
                        );
                        setData({
                          ...data,
                          partsData: filteredLocations,
                        });
                      }
                    }}
                    icon={false}
                    image={false}
                    onRemovePart={handleRemovePart}
                  />
                </div>
                <label className="label-item mt-5">{t.parts.tab.second}</label>
                <div className="wrapper-parts-dropdown">
                  <PartsDropdown
                    title={t.workOrders.form.select_set}
                    options={options.sets}
                    // disabled={!data?.location || !data.area}
                    selectedValues={data.sets}
                    onChange={(e) => {
                      if (e.target.checked) {
                        setData({
                          ...data,
                          sets: [...data.sets, e.target.value],
                        });
                      } else {
                        let filteredLocations = data.sets.filter(
                          (el) => el !== e.target.value
                        );
                        setData({
                          ...data,
                          sets: filteredLocations,
                        });
                      }
                    }}
                    icon={false}
                    image={false}
                  />
                </div> */}
              </>
              {/* : null} */}

              <Form.Field>
                <label
                  className={
                    userPermissions?.part?.view ? "label-item" : "label-item"
                  }
                >
                  {t.workOrders.form.people}
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.people_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  // disabled={
                  //   !data.location &&
                  //   !(roleData === "super_admin" || roleData === "admin")
                  // }
                  multiple
                  onAddItem={handleAddPeople}
                  options={options.people}
                  value={data.people}
                  onChange={onValuesChange("people")}
                  onOpen={fetchPeopleOptions}
                />
              </Form.Field>

              <Form.Field>
                <label className="label-item">{t.workOrders.form.teams}</label>
                <Dropdown
                  placeholder={t.workOrders.form.teams_placeholder}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  search
                  // disabled={
                  //   !data.location &&
                  //   !(roleData === "super_admin" || roleData === "admin")
                  // }
                  multiple
                  onAddItem={handleAddTeam}
                  options={options.teams}
                  value={data.teams}
                  onChange={onValuesChange("teams")}
                  onOpen={fetchTeamsOptions}
                />
              </Form.Field>

              {/* <Form.Field>
          
            <Dropdown
              placeholder={t.workOrders.form.select_part}
              fluid
              selection
              multiple
              noResultsMessage={t.common.no_results_found}
              search
              // onAddItem={handleAddLocation}
              options={options.parts}
              // onOpen={fetchLocationsOptions}
              value={data.parts}
              onChange={onValuesChange('parts')}
            
            />
           
          </Form.Field> */}

              {/* <Form.Field>
            <label className="label-item">{t.parts.tab.second}</label>
            <Dropdown
              placeholder={t.workOrders.form.select_set}
              fluid
              selection
              multiple
              noResultsMessage={t.common.no_results_found}
              search
           
              options={options.sets}
             
              value={data.sets}
              onChange={onValuesChange('sets')}
             
            />
          
          </Form.Field> */}

              {/* 
                <Grid columns="equal" style={{ marginBottom: '10px' }}>
                  <Grid.Column>
                    <Form.Field>
                      <label className="label-item">
                        {t.workOrders.form.code} <Asterisk color="#172B4D" />
                      </label>
                      <Dropdown
                        placeholder={t.workOrders.form.add_code}
                        error={validation.code.error && validation.code.skipped}
                        fluid
                        selection
                        noResultsMessage={t.common.no_results_found}
                        search
                        options={options.code}
                        value={data.code}
                        onChange={onValuesChange('code')}
                        onBlur={(e) => {
                          if (validation.code.pattern(data.code)) {
                            setValidation({
                              ...validation,
                              code: {
                                ...validation.code,
                                error: false,
                                skipped: true,
                              },
                            });
                          } else {
                            setValidation({
                              ...validation,
                              code: {
                                ...validation.code,
                                error: true,
                                skipped: true,
                              },
                            });
                          }
                        }}
                      />
                      {validation.code.error && validation.code.skipped ? (
                        <div className="label-error">
                          {validation.code.message}
                        </div>
                      ) : (
                        ''
                      )}
                    </Form.Field>
                  </Grid.Column>
                </Grid> */}

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.order_type}
                  <Asterisk color="#172B4D" />
                </label>
                <Dropdown
                  placeholder={t.workOrders.form.add_order_type}
                  fluid
                  noResultsMessage={t.common.no_results_found}
                  selection
                  options={options.orderTypes}
                  value={data.orderType}
                  onChange={onValuesChange("orderType")}
                  error={
                    validation.orderType.error && validation.orderType.skipped
                  }
                  onBlur={(e) => {
                    if (validation.orderType.pattern(data.orderType)) {
                      setValidation({
                        ...validation,
                        orderType: {
                          ...validation.orderType,
                          error: false,
                          skipped: true,
                        },
                      });
                    } else {
                      setValidation({
                        ...validation,
                        orderType: {
                          ...validation.orderType,
                          error: true,
                          skipped: true,
                        },
                      });
                    }
                  }}
                />
                {validation.orderType.error && validation.orderType.skipped ? (
                  <div className="label-error">
                    {validation.orderType.message}
                  </div>
                ) : (
                  ""
                )}
              </Form.Field>

              {data.orderType ? (
                <>
                  <Form.Field>
                    <label className="label-item">
                      {data.orderType === "scheduled"
                        ? t.workOrders.details_view.start_date
                        : t.workOrders.table.dueDate}
                    </label>

                    <div
                      className="change-date-time-inll position-relative"
                      style={{ marginBottom: "-13px" }}
                    >
                      <DatePicker
                        minDate={new Date().setHours(0, 0, 0, 0)}
                        highlightToday={false}
                        style={{ width: "100%" }}
                        value={data.dueDate ? new Date(data.dueDate) : null}
                        onChange={(value) => {
                          if (value && value.valueOf()) {
                            setData({
                              ...data,
                              dueDate: format(value.valueOf(), "yyyy-MM-dd"),
                              endDate: format(value.valueOf(), "yyyy-MM-dd"),
                            });
                          } else {
                            setData({
                              ...data,
                              dueDate: format(new Date(), "yyyy-MM-dd"),
                              endDate: format(new Date(), "yyyy-MM-dd"),
                            });
                          }
                        }}
                        showMonthDropdown
                        showYearDropdown
                        placeholder={t.workOrders.form.add_due_date}
                        className={"active"}
                        weekDays={[
                          t.workOrders.details_view.sun,
                          t.workOrders.details_view.mon,
                          t.workOrders.details_view.tue,
                          t.workOrders.details_view.wed,
                          t.workOrders.details_view.thu,
                          t.workOrders.details_view.fri,
                          t.workOrders.details_view.sat,
                        ]}
                        months={[
                          t.common.january,
                          t.common.february,
                          t.common.march,
                          t.common.april,
                          t.common.may,
                          t.common.june,
                          t.common.july,
                          t.common.august,
                          t.common.september,
                          t.common.october,
                          t.common.november,
                          t.common.december,
                        ]}
                        format="DD.MM.YYYY"
                      ></DatePicker>
                      <img
                        className="position-absolute"
                        style={
                          selectedLanguage == "en"
                            ? { top: "8px", right: "7px" }
                            : { top: "8px", left: "7px" }
                        }
                        src={calendar_icon}
                      />
                    </div>

                    <Checkbox
                      label={
                        data.orderType === "scheduled"
                          ? t.workOrders.form.include_start_time
                          : t.workOrders.form.include_due_time
                      }
                      checked={data?.includeDueTime}
                      onChange={onValuesChange("includeDueTime")}
                      className=""
                      style={{ marginBottom: "15px", top: "1rem" }}
                    />
                  </Form.Field>
                  {data?.includeDueTime ? (
                    <Form.Field>
                      <label className="label-item mt-3">
                        {data.orderType === "scheduled"
                          ? t.workOrders.details_view.start_time
                          : t.workOrders.form.due_time}
                        {/* <Icon name="asterisk" color="red" size="small" /> */}
                      </label>
                      <div className="position-relative">
                        <Input
                          autoComplete="new-password"
                          type="time"
                          fluid
                          placeholder={t.workOrders.form.add_due_time}
                          disabled={!data.includeDueTime}
                          value={data.dueTime}
                          onChange={onValuesChange("dueTime")}
                        >
                          <input />
                        </Input>

                        <img
                          className="mb-5 position-absolute"
                          style={
                            selectedLanguage == "en"
                              ? { top: "8px", right: "7px" }
                              : { top: "8px", left: "7px" }
                          }
                          src={clock_icon}
                        />
                      </div>
                    </Form.Field>
                  ) : null}
                </>
              ) : null}

              {data.orderType == "scheduled" ? (
                <>
                  <label className="label-item">
                    {" "}
                    <label className="label-item mb-2">
                      {t.workOrders.details_view.repeat_every}
                    </label>
                  </label>
                  <div
                    className={
                      data.repeatEvery != "daily"
                        ? "repeat-container-flex"
                        : "repeat-container"
                    }
                  >
                    <Form.Field>
                      <Dropdown
                        placeholder="Repeat Everly"
                        className="repeat-everly workorder-date-input"
                        fluid
                        noResultsMessage={t.common.no_results_found}
                        selection
                        options={options.repeatEvery}
                        value={data.repeatEvery}
                        onChange={(e, value) => {
                          let findPriority = value.options.find(
                            (one) => one.value == value.value
                          );
                          if (findPriority.value == "daily") {
                            setData({
                              ...data,
                              repeatEvery: "daily",
                              daysRepeat: {
                                sat: true,
                                sun: true,
                                mon: true,
                                tue: true,
                                wed: true,
                                thu: true,
                                fri: true,
                              },
                            });
                          } else if (findPriority.value == "weekly") {
                            setData({
                              ...data,
                              repeatEvery: "weekly",
                              daysRepeat: {
                                sat: false,
                                sun: true,
                                mon: false,
                                tue: false,
                                wed: false,
                                thu: false,
                                fri: false,
                              },
                            });
                          } else if (findPriority.value == "monthly") {
                            setData({ ...data, repeatEvery: "monthly" });
                          } else if (findPriority.value == "yearly") {
                            setData({ ...data, repeatEvery: "yearly" });
                          }
                        }}
                      />
                    </Form.Field>

                    {data.repeatEvery != "daily" ? (
                      data.repeatEvery == "weekly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            {t.workOrders.details_view.every_day}
                            <Dropdown
                              className="week-dropdown"
                              style={{ margin: "0 5px" }}
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              options={options.weeks}
                              value={data.repeatEveryWeek}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryWeek")}
                              onOpen={fetchWeekOptions}
                            />
                            {t.workOrders.details_view.week_on}
                          </div>
                        </Form.Field>
                      ) : data.repeatEvery == "monthly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            {t.workOrders.details_view.every_day}
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              className="week-dropdown"
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              options={options.months}
                              value={data.repeatEveryMonth}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryMonth")}
                              onOpen={fetchMonthOptions}
                            />
                            {t.workOrders.details_view.month_on}
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              className="week-dropdown"
                              options={options.monthsDays}
                              value={data.repeatMonthDay}
                              placeholder={t.workOrders.details_view.select_day}
                              onChange={onValuesChange("repeatMonthDay")}
                              onOpen={fetchMonthDaysOptions}
                            />
                          </div>
                        </Form.Field>
                      ) : data.repeatEvery == "yearly" ? (
                        <Form.Field>
                          <div className="flexbox align-center">
                            {t.workOrders.details_view.every_day}
                            <Dropdown
                              style={{ margin: "0 5px" }}
                              inline
                              search
                              noResultsMessage={t.common.no_results_found}
                              className="week-dropdown"
                              options={options.years}
                              value={data.repeatEveryYear}
                              placeholder={t.workOrders.details_view.select_no}
                              onChange={onValuesChange("repeatEveryYear")}
                              onOpen={fetchYearsOptions}
                            />
                            {t.workOrders.details_view.year}
                          </div>
                        </Form.Field>
                      ) : (
                        ""
                      )
                    ) : (
                      <Form.Field>
                        <Button.Group basic fluid className="basic-btn">
                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  sun: !data.daysRepeat.sun,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.sun ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.sun}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  mon: !data.daysRepeat.mon,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.mon ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.mon}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  tue: !data.daysRepeat.tue,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.tue ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.tue}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  wed: !data.daysRepeat.wed,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.wed ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.wed}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  thu: !data.daysRepeat.thu,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.thu ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.thu}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  fri: !data.daysRepeat.fri,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.fri ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.fri}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  sat: !data.daysRepeat.sat,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.sat ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.sat}
                          </Button>
                        </Button.Group>

                        {dayFlag ? (
                          <div className="label-error">
                            {t.common.required_field}
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Field>
                    )}
                  </div>

                  {data.repeatEvery == "weekly" ? (
                    <div className="weekly-btns">
                      <Form.Field>
                        <Button.Group basic fluid className="basic-btn">
                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  sun: !data.daysRepeat.sun,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.sun ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.sun}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  mon: !data.daysRepeat.mon,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.mon ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.mon}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  tue: !data.daysRepeat.tue,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.tue ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.tue}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  wed: !data.daysRepeat.wed,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.wed ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.wed}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  thu: !data.daysRepeat.thu,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.thu ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.thu}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  fri: !data.daysRepeat.fri,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.fri ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.fri}
                          </Button>

                          <Button
                            onClick={() => {
                              setData({
                                ...data,
                                daysRepeat: {
                                  ...data.daysRepeat,
                                  sat: !data.daysRepeat.sat,
                                },
                              });
                            }}
                            className={
                              data.daysRepeat.sat ? "day-btn active" : "day-btn"
                            }
                          >
                            {t.workOrders.details_view.sat}
                          </Button>
                        </Button.Group>

                        {dayFlag ? (
                          <div className="label-error">
                            {t.common.required_field}
                          </div>
                        ) : (
                          ""
                        )}
                      </Form.Field>
                    </div>
                  ) : (
                    ""
                  )}

                  <Form.Field>
                    <label className="label-item">
                      {t.workOrders.details_view.ends}
                    </label>

                    <Form>
                      <div className="d-flex align-items-center side-dropdown gap-5">
                        <div>
                          <Radio
                            label={t.workOrders.details_view.on}
                            name="radioGroup"
                            value="on"
                            checked={data.endType === "on"}
                            onChange={onValuesChange("endType")}
                          />
                        </div>
                        {data.endType === "on" ? (
                          <div className="mt-4 mb-5">
                            <div className="position-relative">
                              <DatePicker
                                value={
                                  data.endDate ? new Date(data.endDate) : null
                                }
                                minDate={new Date(data.dueDate)}
                                onChange={(value) => {
                                  if (value && value.valueOf()) {
                                    setValidation({
                                      ...validation,
                                      endDate: {
                                        ...validation.endDate,
                                        error: false,
                                        skipped: true,
                                      },
                                    });

                                    setData({
                                      ...data,
                                      endDate: format(
                                        value.valueOf(),
                                        "yyyy-MM-dd"
                                      ),
                                    });
                                  } else {
                                    setValidation({
                                      ...validation,
                                      endDate: {
                                        ...validation.endDate,
                                        error: true,
                                        skipped: true,
                                      },
                                    });

                                    setData({
                                      ...data,
                                      endDate: null,
                                    });
                                  }
                                }}
                                showMonthDropdown
                                showYearDropdown
                                placeholder={t.workOrders.form.end_date}
                                className={"active"}
                                weekDays={[
                                  t.workOrders.details_view.sun,
                                  t.workOrders.details_view.mon,
                                  t.workOrders.details_view.tue,
                                  t.workOrders.details_view.wed,
                                  t.workOrders.details_view.thu,
                                  t.workOrders.details_view.fri,
                                  t.workOrders.details_view.sat,
                                ]}
                                months={[
                                  t.common.january,
                                  t.common.february,
                                  t.common.march,
                                  t.common.april,
                                  t.common.may,
                                  t.common.june,
                                  t.common.july,
                                  t.common.august,
                                  t.common.september,
                                  t.common.october,
                                  t.common.november,
                                  t.common.december,
                                ]}
                                format="DD.MM.YYYY"
                              ></DatePicker>

                              <img
                                className="position-absolute"
                                style={
                                  selectedLanguage == "en"
                                    ? { top: "6px", right: "7px" }
                                    : { top: "6px", left: "7px" }
                                }
                                src={calendar_icon}
                              />
                            </div>
                            {validation?.endDate?.error &&
                            validation?.endDate?.skipped ? (
                              <div className="label-error">
                                {validation?.endDate?.message}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                      <div className="d-flex align-items-center side-dropdown">
                        <div>
                          <Radio
                            label={t.workOrders.details_view.after}
                            name="radioGroup"
                            value="after"
                            checked={data.endType === "after"}
                            onChange={onValuesChange("endType")}
                          />
                        </div>
                       
                        {data.endType === "after" ? (
                          <div className="mt-4 mb-5 ">
                            <Input
                              autoComplete="new-password"
                              highlightToday={false}
                              type="number"
                              onKeyDown={(evt) => handleKeyDown(evt)}
                              min="0"
                              className="work-input"
                              placeholder="Enter number of orders"
                              value={data.endRepeat}
                              onChange={onValuesChange("endRepeat")}
                              error={
                                validation.endRepeat.error &&
                                validation.endRepeat.skipped
                              }
                              onBlur={(e) => {
                                if (
                                  validation.endRepeat.pattern(data.endRepeat)
                                ) {
                                  setValidation({
                                    ...validation,
                                    endRepeat: {
                                      ...validation.endRepeat,
                                      error: false,
                                      skipped: true,
                                    },
                                  });
                                } else {
                                  setValidation({
                                    ...validation,
                                    endRepeat: {
                                      ...validation.endRepeat,
                                      error: true,
                                      skipped: true,
                                    },
                                  });
                                }
                              }}
                              disabled={data.endType !== "after"}
                            ></Input>
                            {validation?.endRepeat?.error &&
                            validation?.endRepeat?.skipped ? (
                              <div className="label-error">
                                {validation?.endRepeat?.message}
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        ) : null}
                      </div>
                    </Form>
                  </Form.Field>
                </>
              ) : (
                ""
              )}
              <Form.Field>
                <label
                  className={
                    !data?.includeDueTime && data.orderType === "reactive"
                      ? "label-item mt-5"
                      : "label-item"
                  }
                >
                  {" "}
                  {t.workOrders.details_view.estimation_time}
                </label>

                <Input
                  // labelPosition="right"
                  autoComplete="new-password"
                  type="number"
                  min="0"
                  fluid
                  placeholder={t.workOrders.form.time_placeholder}
                  value={data.estimationTime}
                  onChange={onValuesChange("estimationTime")}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                >
                  <input />

                  <span
                    className={`mr-2 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    style={{
                      padding: "5px",
                      backgroundColor: "#E9F2FF",
                      color: "#0C66E4",
                    }}
                  >
                    {t.common.hrs}
                  </span>
                </Input>
              </Form.Field>

              <Form.Field>
                <label className="label-item">
                  {t.workOrders.form.labor_cost}
                </label>
                <Input
                  autoComplete="new-password"
                  type="number"
                  min="0"
                  fluid
                  placeholder={t.workOrders.form.add_labor_cost}
                  value={data.laborCost}
                  onChange={onValuesChange("laborCost")}
                  onKeyDown={(evt) => handleKeyDown(evt)}
                >
                  <input />
                  <span
                    className={`mr-2 p-1 d-flex align-items-center justify-content-center ${
                      selectedLanguage == "en" ? "ml-5" : "mr-5"
                    }`}
                    style={{ backgroundColor: "#E9F2FF", color: "#0C66E4" }}
                  >
                    {organisationCurrency}
                  </span>
                </Input>
              </Form.Field>
              
              <Form.Field>
                <FileUpload
                  onFileChange={onFileChange}
                  name="warrantyFile"
                  attachments={attachments}
                  setAttachments={setAttachments}
                />
              </Form.Field>

              {data.qrcode ? (
                <>
                  <label className="qr-code-container label-item mt-5">
                    {t.assets.filter.qrcode}
                  </label>
                  <div style={{ marginTop: "5px" }} className="mb-3">
                    {/* <QRcodeBlueIcon width={"80px"} height={"80px"} /> */}
                    <QRCode
                      id="QRCode"
                      title="WorkOrder QR Code"
                      value={data.qrcode}
                      size={80}
                      disabled
                    />
                  </div>
                </>
              ) : (
                <Form.Field>
                  <div>
                    <div
                      onClick={() => {
                        setData({
                          ...data,
                          qrcode: data.qrcode ? null : uuidv4(),
                        });
                      }}
                      className="warrenty-file-dropzone qr-code-conrtainer d-flex align-items-center justify-content-between "
                    >
                      <div className="d-flex ml-4 mr-4">
                        <AddFile />
                        <div className="pl-4 pr-4">
                          <p className="p1 m-0">
                            {t.workOrders.form.create_QR}
                          </p>
                          <p className="p2 m-0">
                            {t.workOrders.form.create_QR_for_WO}
                          </p>
                        </div>
                      </div>
                      <div>
                        <AddIconWithCircle />
                      </div>
                    </div>
                  </div>
                </Form.Field>
              )}

              <Divider />

              <div className="form-button-container">
                <button
                  onClick={() =>
                    initChange
                      ? setNewCancelModal(initChange && true)
                      : cancelHandler()
                  }
                  className="form-action-button"
                >
                  {t.assets.form.cancel}
                </button>

                <button
                  onClick={createNewWorkOrderHandler}
                  className="form-action-button blue"
                  disabled={buttonStatus == 1}
                >
                  {t.common.submit}
                </button>
              </div>
            </div>
          </Resizable>
        </>
      </div>
      <div
        className={
          toggled ? "aside-modal-background active" : "aside-modal-background"
        }
        onClick={() => (initChange ? setNewCancelModal(true) : cancelHandler())}
      ></div>

      {/* success modal */}

      <SuccessModal
        closeCallback={async () => {
          const currentPathname = window.location.pathname;
          setRefresh(!refresh);
          if (currentPathname == "/work-orders") {
            setSearchTitle("");
            setSelectedDates([]);
            setFilterOptions({
              serialNumber: "",

              people: [],
              teams: [],
              customers: [],
              locations: [],
              createdByUser: [],

              status: [],
              priority: [],
              categories: [],
            });

            if (initData?.length == 0) {
              const fetchData = await dispatch(
                fetchWOInitData({
                  organisationId,
                  role: roleData,
                  allIds,
                  currentUserId,
                })
              );
            }
          }
        }}
      >
        <span>{t.workOrders.form.success.msg}</span>
      </SuccessModal>

      {/* cancel modal */}
      <NewCancelModal
        yesCallback={() => {
          cancelHandler();
        }}
        title={t.parts.form.changes}
      >
        <p style={{ lineHeight: "24px" }}> {t.common.cancel_2statement}</p>
      </NewCancelModal>

      <Modal
        size="large"
        open={addPartModal}
        className="assets-modal part-list-modal"
        style={{ height: "770px" }}
        onClose={() => {
          handleClosePartModal();
        }}
      >
        <div className="header-container">
          <span className="assets-header">
            {t.workOrders.form.add_part_set}
          </span>
          <div>
            <span
              className="c-pointer text-right"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              <CloseIcon />
            </span>
          </div>
        </div>

        <Modal.Content className="mt-2">
          <div style={{ gap: "10px" }} className="d-flex">
            <Form.Field style={{ minHeight: "43px" }}>
              <Dropdown
                placeholder={t.parts.form.inv_loc}
                style={{ minWidth: "300px", padding: "6px 2px 1px 12px" }}
                className="loc-dropdown"
                selection
                noResultsMessage={t.common.no_results_found}
                search
                clearable
                // onAddItem={handleAddLocation}
                options={options.inv_location}
                // onOpen={fetchLocationsOptions}
                value={selectedInvLoc}
                onChange={(e, value) => {
                  setSelectedInvLoc(value?.value);
                }}
              />
            </Form.Field>
            <div
              style={{ width: "-webkit-fill-available" }}
              className="set-input"
            >
              <span className="search-svg-set">
                <SearchIcon />
              </span>
              <Input
                style={{ width: "-webkit-fill-available" }}
                placeholder={t.workOrders.form.search_parts}
                value={partSearchTitle}
                onChange={(e) => setPartSearchTitle(e.target.value)}
              />
            </div>
          </div>
          {loading ? (
            <Dimmer active inverted>
              <Loader inverted content={t.common.loading} />
            </Dimmer>
          ) : (
            <>
              <div
                style={{ }}
                className=" mt-5  bulk-upload-table"
              >
                <DataTable
                className="wo-table-div"
                  columns={[
                    {
                      name: (
                        <div className={selectedLanguage == "en" ? '' : "mt-3"}>
                          <Checkbox
                            checked={areAllSelected}
                            disabled={partsData?.every((item) => item?.disable == true)}
                            onChange={(e, value) =>
                              handleSelectAll(value.checked)
                            }
                            // onClick={() => handleRowAllSelected()}
                            // onChange={(e, value) => {
                            //   handleRowSelected(
                            //     row,
                            //     value.checked,

                            //     selectedTablePartData
                            //   );
                            // }}
                          />
                        </div>
                      ),
                      width: "50px",
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <Checkbox
                              checked={row?.selectedPart}
                              disabled={row?.disable}
                              className="set-checkbox-gery"
                              // defaultChecked={row?.selectedPart}
                              onChange={(e, value) => {
                                if (!row?.disable) {
                                  handleRowSelected(
                                    row,
                                    value.checked,
                                    selectedTablePartData
                                  );
                                }
                              }}
                            />
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "100px",
                      name: t.workOrders.table.ID,
                      //   name: t.categories.table.name,
                      sortField: "name",
                      sortable: true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.countId ? row?.countId : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "240px",
                      name: t.workOrders.details_view.name,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.name ?  DisplayFormattedText(row?.name,selectedLanguage == "en" ?  23 : 20): ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.parts.form.inv_loc,

                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.location_name ? DisplayFormattedText(row?.location_name,selectedLanguage == "en" ?  20 :  18)  : ""}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "200px",
                      name: t.assets.title,
                      cell: (row) => {
                        const locationData = row?.asset;
                        if (locationData?.length > 0) {
                          const firstLocation = locationData[0]?.name;
                          const remainingCount = locationData.length - 1;
                          const remainingLocations = locationData
                            .slice(1)
                            .map((location) => location?.name)
                            .join(', ');
                          const displayText =
                            remainingCount > 0
                              ? `${firstLocation} ... +${remainingCount}`
                              : firstLocation;
                          return (
                            <span
                              title={locationData
                                .map((location) => location?.name)
                                .join(', ')}
                            >
                              {displayText}
                            </span>
                          );
                          // const displayText = locationData
                          //   .map((location) => {
                          //     const nameParts = location?.attributes?.name;
                
                          //     return nameParts;
                          //   })
                          //   .join(', '); // Adjust the join character as needed
                          // return DisplayFormattedText(displayText, 20);
                        } else {
                          return '';
                        }
                      },
                        
                      // cell: (row) => (
                      //   <div className="d-flex align-items-center">
                      //     <div>
                      //       <span className="id-text-style">
                      //         {/* {row?.asset ? row?.asset : ""} */}
                      //       </span>
                      //     </div>
                      //   </div>
                      // ),
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.avl_qty,
        right:true,
                      cell: (row) => (
                        <div className="d-flex align-items-center">
                          <div>
                            <span className="id-text-style">
                              {row?.type == "Set" ? (
                                row?.status == "outOfStock" ? (
                                  <div style={{ color: "#788CA5",width:"103px",textAlign:"end" }}>
                                    {t.parts.status.out_of_stock}
                                  </div>
                                ) : <div style={{ color: "#788CA5",width:"103px",textAlign:"end"  }}>
                                {t.parts.status.inStock}
                              </div>
                              ) : row?.status == "outOfStock" ? (
                                <div style={{ color: "#788CA5",width:"103px",textAlign:"end" }}>
                                  {t.parts.status.out_of_stock}
                                </div>
                              ) : row?.availableQty ? (
                                <>
                               <div className="id-text-style d-flex align-items-center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              textAlign:"end",
                              minWidth: "65px", // Set a fixed width based on the longest expected value
                              // Align text to the right within the fixed space
                              display: "inline-block",
                            }}
                          >
                            {row?.availableQty ? row?.availableQty : 0}
                          </span>
                          <span
                            className={`align-items-center justify-content-center ${
                              selectedLanguage === "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",
                             minWidth:selectedLanguage == "en" ? "33px" : "48px",
                              display: "inline-block",
                              textAlign:"center"
                            }}
                          >
                            {row?.unitType ? HandleUnitType(row?.unitType) : null}
                          </span>
                        </div>
                      </div></>
                              ) : (
                                0
                              )}
                            </span>
                          </div>
                        </div>
                      ),
                    },
                    {
                      minWidth: "150px",
                      name: t.parts.form.unit_cost,
                      right:true,
                      style: {
                        justifyContent: "end", // Align header text to the right
                      },
                      cell: (row) => (
                        <div className="id-text-style d-flex align-items-center">
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <span
                            style={{
                              textAlign:"end",
                              minWidth: "65px", // Set a fixed width based on the longest expected value
                              // Align text to the right within the fixed space
                              display: "inline-block",
                            }}
                          >
                            {row?.unitCost ? row?.unitCost : 0}
                          </span>
                          <span
                            className={`align-items-center justify-content-center ${
                              selectedLanguage === "en" ? "ml-2" : "mr-2"
                            }`}
                            style={{
                              backgroundColor: "#E9F2FF",
                              color: "#0C66E4",
                              minWidth: "33px",
                              padding: "9px 3px 9px 2px",
                              fontSize: "14px",
                              display: "inline-block",
                            }}
                          >
                            {organisationCurrency}
                          </span>
                        </div>
                      </div>
                      ),
                    },
                  ]}
                  data={getPaginatedData(partsData)}
                  // data={partsData}
                  fixedHeader
                  customStyles={customStyles}
                  onRowClicked={(row) => {
                    if (!row.disable) {
                      handleRowSelected(
                        row,
                        row?.selectedPart ? false : true,
                        selectedTablePartData
                      );
                    }
                  }}
                
                  noDataComponent={<span>{t.sets.no_data_wo}</span>}
                />
              </div>

             { partsData?.length > 0 && <div className="d-flex justify-content-end">
                <Pagination
                  ofString={t.common.of}
                  total={partsData.length}
                  prevItem={
                    selectedLanguage === "en" ? <ArrowLeft /> : <ArrowRight />
                  }
                  nextItem={
                    selectedLanguage === "en" ? <ArrowRight /> : <ArrowLeft />
                  }
                  page={currentPage}
                  limit={pageSize}
                  handleNext={() => {
                    setCurrentPage(currentPage + 1);
                  }}
                  handlePrevious={() => {
                    setCurrentPage(currentPage - 1);
                  }}
                  parts={true}
                />
              </div> }
            </>
          )}
        </Modal.Content>
        {!loading && (
          <div
            className={`modal-button-container ${
              selectedLanguage == "en" ? "mr-8" : "ml-10"
            }`}
          >
            <button
              className="modal-form-action-button black"
              onClick={() => {
                handleClosePartModal();
              }}
            >
              {t.assets.form.cancel}
            </button>

            <button
              onClick={() => handleAddPartData()}
              className="modal-form-action-button light-blue"
            >
              {t.common.add}
            </button>
          </div>
        )}
      </Modal>

      {/* on the go popups */}
      <AddCustomerModal
        toggled={addCustomerModal}
        untoggle={() => {
          toggleAddCustomerModal(false);
        }}
        returnAddCustomer={returnAddCustomer}
        title={customerText}
      />

      <AddLocationModal
        toggled={addLocationModal}
        untoggle={() => {
          toggleAddLocationModal(false);
        }}
        customer={data.customer}
        returnAddLocation={returnAddLocation}
        title={locationText}
      />

      <AddPeopleModal
        toggled={addPeopleModal}
        untoggle={() => {
          toggleAddPeopleModal(false);
        }}
        returnAddPeople={returnAddPeople}
        title={peopleText}
      />

      <AddTeamModal
        toggled={addTeamModal}
        untoggle={() => {
          toggleAddTeamModal(false);
        }}
        returnAddTeam={returnAddTeam}
        title={teamText}
      />

      <AddProcedureModal
        toggled={addProcedureModal}
        untoggle={() => {
          toggleAddProcedureModal(false);
        }}
        returnAddProcedure={returnAddProcedure}
        title={procedureText}
        cloneList={[]}
      />

      <AddAssetModal
        toggled={addAssetModal}
        untoggle={() => {
          toggleAddAssetModal(false);
        }}
        returnAddAsset={returnAddAsset}
        title={assetText}
      />

      {/* <AddPartModal
        toggled={addPartModal}
        untoggle={() => {
          toggleAddPartModal(false);
        }}
        returnAddPart={returnAddPart}
        title={partText}
      /> */}
    </>
  );
}

export default Add;


