import React, { useState, useEffect, useRef } from "react";
import {
  Icon,
  Image,
  List,
  Popup,
  Menu,
  Divider,
  Loader,
  Modal,
  Button,
  Header,
  Dimmer,
  Grid,
} from "semantic-ui-react";
import {
  humanize,
  deleteTeam,
  IMAGES_URL,
  getAllParentLocations,
} from "../../../config/functions";

import md5 from "md5";
import Moment from "react-moment";
import { usePermissionsSimplified } from "../../../../hooks/usePermissionsSimplified";
import { LetteredAvatarImageUrl } from "../../../components/common/userProfilePlaceholder";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { selectTranslations } from "../../../config/i18n/slice";
import { useDispatch, useSelector } from "react-redux";
import {
  CloseIcon,
  DeleteIcon,
  DownloadIconBucket,
  EditTextIcon,
  FileDownloadIcon,
  LinkBlueIcon,
  LocationBlueNoBackground,
  LocationDropDownIcon,
  LocationDropDownIconBlue,
  ShareIcon,
  SubLocationDropDownBlueIcon,
} from "../../../../Assets/Icons/svg";
import useDeletePermissionModal from "../../../../hooks/Messages/useDeletePermissionModal";
import ToolTip from "react-power-tooltip";
import DownloadExcelFileWithData from "../../../../services/DownloadExcelFileWithData";
import DownloadPdfFileWithData from "../../../../services/DownloadPdfFileWithData";
import moment from "moment";

const Details = ({ targetedData }) => {
  const history = useHistory();

  const dispatch = useDispatch();
  const t = useSelector(selectTranslations);
  const { userPermissions, checkForPermission } = usePermissionsSimplified();
  const [fetchLoading, setFetchLoading] = useState(false);
  const [locOption, setLocOption] = useState([]);

  const popUpRef = useRef(null);

  const rowStyle = { cursor: "pointer" };

  const organisationId = useSelector((state) => {
    if (state?.auth?.user?.organisation) {
      return state.auth.user.organisation.id;
    } else {
      return null;
    }
  });
  const roleData = useSelector((state) => {
    if (state?.auth?.user?.id) {
      return state.auth.user.role.type;
    } else {
      return null;
    }
  });
  const selectedLanguage = useSelector((state) => {
    if (state?.i18n?.lang) {
      return state.i18n.lang;
    } else {
      return "en";
    }
  });

  const roles = [
    {
      key: 0,
      value: 5,
      text: t.people.add_modal.admin,

      description: t.people.add_modal.admin_description,
    },
    {
      key: 0,
      value: 4,
      text: t.people.add_modal.super_admin,
      description: t.people.add_modal.admin_description,
    },
    // {
    //   key: 89,
    //   value: 7,
    //   text: 'Supervisor',
    //   icon: 'handshake outline',
    //   description: 'Can add orders, assets, locations, people',
    // },
    {
      key: 2,
      value: 9,
      text: t.people.add_modal.limited_admin,

      description: t.people.add_modal.limited_admin_description,
    },
    {
      key: 1,
      value: 8,
      text: t.people.add_modal.technician,

      description: t.people.add_modal.user_description,
    },

    {
      key: 3,
      value: 3,
      text: t.people.add_modal.viewer,

      description: t.people.add_modal.viewer_description,
    },
    // {
    //   key: 2,
    //   value: 6,
    //   text: t.people.add_modal.requester,
    //   icon: <ViewerIcon />,
    //   description:
    //    t.people.add_modal.requester_desc
    // },
    // {
    //   key: 3,
    //   value: 3,
    //   text: 'Viewer only',
    //   icon: 'sitemap',
    //   description:
    //     'Lorem ipsum is placeholder text commonly used in the graphic',
    // },
  ];

  useEffect(() => {
    setFetchLoading(true);

    const fetchLocOption = async () => {
      if (
        targetedData?.attributes?.locations &&
        targetedData?.attributes?.locations?.data?.length
      ) {
        const fetchData = await getAllParentLocations(
          organisationId,
          "",
          1,
          99999,
          [],
          [],
          [],
          ["name:asc"]
        );
        let the_options = [];
        let parentOptions = [];
        let subOptions = [];

        const allIds = targetedData?.attributes?.locations?.data?.length
          ? targetedData?.attributes?.locations?.data?.map((each) => each?.id)
          : [];



        if (fetchData?.response?.length > 0 && allIds.length > 0) {
          fetchData.response.forEach((el) => {
            const subLocationCount =
              el?.attributes?.subLocations?.data?.filter((subEl) =>
                allIds.includes(subEl.id)
              ).length || 0;
            // Check if the element's ID is included in allIds
            if (allIds?.includes(el.id)) {
              // Add parent option normally
              parentOptions.push({
                key: el.id,
                value: el.id,
                text: el.attributes.name,
                countId: el.attributes.countId,
                subLocationCount: subLocationCount,
                // image: { src: location_icon },
                disabled: false, // Parent option enabled
              });

              // Collect sub-options
              if (el?.attributes?.subLocations?.data?.length > 0) {
                el.attributes.subLocations.data.forEach((subEl) => {
                  if (allIds?.includes(subEl.id)) {
                    subOptions.push({
                      key: subEl.id,
                      value: subEl.id,
                      text: subEl.attributes.name,
                      countId: subEl.attributes.countId,
                      // image: { src: sub_location_icon },
                      className: "sub-location-dropdown-option",
                      parentId: el.id, // Keep track of the parent
                    });
                  }
                });
              }
            } else {
              // If the element's ID is not in allIds, check sub-locations
              const hasMatchingSubLocation = el?.attributes?.subLocations?.data?.some(
                (subEl) => allIds?.includes(subEl.id)
              );

              if (hasMatchingSubLocation) {
                // Add parent option as disabled
                parentOptions.push({
                  key: el.id,
                  value: el.id,
                  subLocationCount,
                  text: el.attributes.name,
                  countId: el.attributes.countId,
                  // image: { src: location_icon },
                  disabled: !(
                    roleData === "super_admin" || roleData === "admin"
                  )
                    ? true
                    : false, // Disable the parent option
                });

                // Collect matching sub-options
                el?.attributes?.subLocations?.data?.length > 0 &&
                  el.attributes.subLocations.data.forEach((subEl) => {
                    if (allIds?.includes(subEl.id)) {
                      subOptions.push({
                        key: subEl.id,
                        value: subEl.id,
                        text: subEl.attributes.name,
                        countId: subEl.attributes.countId,
                        // image: { src: sub_location_icon },
                        className: "sub-location-dropdown-option",
                        parentId: el.id, // Keep track of the parent
                      });
                    }
                  });
              }
            }

            // else {
            //   // Add parent option
            //   parentOptions.push({
            //     key: el.id,
            //     value: el.id,
            //     text: el.attributes.name,
            //     image: { src: location_icon },
            //   });

            //   // Collect sub-options
            //   if (el?.attributes?.subLocations?.data?.length > 0) {
            //     el.attributes.subLocations.data.forEach((subEl) => {
            //       subOptions.push({
            //         key: subEl.id,
            //         value: subEl.id,
            //         text: subEl.attributes.name,
            //         image: { src: sub_location_icon },
            //         className: "sub-location-dropdown-option",
            //         parentId: el.id, // Keep track of the parent
            //       });
            //     });
            //   }
            // }
          });

          // Sort sub-options alphabetically
          subOptions.sort((a, b) => a.text.localeCompare(b.text));

          // Merge parent options with sorted sub-options
          parentOptions.forEach((parentOption) => {
            the_options.push(parentOption);
            // Add sorted sub-options belonging to this parent
            subOptions.forEach((subOption) => {
              if (subOption.parentId === parentOption.key) {
                the_options.push(subOption);
              }
            });
          });

          // Update the options state
          setLocOption(the_options);

          return the_options;
        }
      }
    };

    const loadData = async () => {
      await fetchLocOption(); // Wait for fetchLocOption to complete
      setFetchLoading(false); // Set loading state to false after both calls
    };
    loadData();
  }, [targetedData]); 
  return (
    <>
      {!fetchLoading ? (
        <div className="preview-details-wrapper">
          <div className="data-header">
            <div className="header-title d-flex justify-content-between align-items-center">
              <div className="header-pill-container">
                <p className="detail-title">
                  {humanize(targetedData?.attributes?.name)}
                </p>
              </div>
            </div>
          </div>

          <div className="detail-intro">
            {/* <div className="d-flex align-items-center mt-4 mb-6">
              <label className="detail-intro-label">
                {t.common.created_by}
                {':'}
              </label>
              <p className="detail-intro-value">
                {targetedData?.attributes?.createdByUser?.data
                  ? targetedData?.attributes?.createdByUser.data
                      ?.attributes?.firstName +
                    ' ' +
                    targetedData?.attributes?.createdByUser?.data
                      ?.attributes?.lastName
                  : t.common.na}
              </p>
            </div> */}

            {/* <div
              className="flexbox align-center"
              style={{ fontSize: '15px' }}
            >
              <span style={{ marginRight: '2px' }}>
                {t.common.created_by}:
              </span>
              {createdByName}
              <span style={{ margin: '0 2px' }}>@</span>
              <Moment
                format="DD-MM-YYYY LT"
                date={targetedData?.attributes.updatedAt}
              />
            </div> */}

            {/* <div
              className="flexbox align-center"
              style={{ margin: '20px 0' }}
            >
              <div>
                <Image
                  circular
                  size="tiny"
                  title={targetedData?.attributes.name}
                  style={{ height: '80px', objectFit: 'cover' }}
                  src={LetteredAvatarImageUrl(
                    targetedData?.attributes.name
                  )}
                />
              </div>
              <div style={{ marginLeft: '10px' }}>
                <h2>{targetedData?.attributes.name}</h2>
                <div>{targetedData?.attributes.description}</div>
              </div>
            </div> */}
          </div>
          <Divider />

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label team-label">
              {t.teams.team_info_form.description}
              {":"}
            </label>
            <p className="workorder-intro-value">
              {targetedData?.attributes?.description || t.common.na}
            </p>
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex align-items-start mt-5">
              <label className="label-item ">
                {""}
                <label className="workorder-intro-label team-label">
                  {t.teams.team_info_form.superior}
                  {":"}
                </label>
              </label>

              <p
                className={`workorder-intro-value ${
                  selectedLanguage == "en"
                    ? targetedData?.attributes?.superior?.data
                      ? "ml-4"
                      : "ml-5"
                    : targetedData?.attributes?.superior?.data
                    ? "mr-4"
                    : "mr-5"
                }`}
              >
                {targetedData?.attributes?.superior?.data ? (
                  <div>
                    <Image
                      avatar
                      title={
                        targetedData?.attributes.superior?.data?.attributes
                          ?.firstName +
                        " " +
                        targetedData?.attributes?.superior?.data?.attributes
                          ?.lastName
                      }
                      src={
                        targetedData?.attributes.superior?.data?.attributes
                          .profileImage.data
                          ? IMAGES_URL +
                            targetedData?.attributes.superior?.data?.attributes
                              .profileImage.data?.attributes?.url
                          : LetteredAvatarImageUrl(
                              targetedData?.attributes.superior?.data
                                ?.attributes?.firstName +
                                " " +
                                targetedData?.attributes?.superior?.data
                                  ?.attributes?.lastName
                            )
                      }
                      style={{ width: "34px", height: "34px" }}
                    />
                    <span style={{ marginLeft: "4px" }}>
                      {targetedData?.attributes.superior?.data?.attributes
                        ?.firstName +
                        " " +
                        targetedData?.attributes?.superior?.data?.attributes
                          ?.lastName}
                    </span>
                  </div>
                ) : (
                  t.common.na
                )}
              </p>
            </div>
          </div>
          <div style={{ width: "100%" }}>
            <div className="d-flex align-items-start mt-5">
              <label className="label-item ">
                {""}
                <label className="workorder-intro-label team-label">
                  {t.teams.team_info_form.location}{" "}
                  {`${
                    targetedData?.attributes?.locations?.data
                      ? `(${targetedData?.attributes?.locations?.data.length})`
                      : ""
                  }`}
                  {":"}
                </label>
              </label>

              <p
                style={{ gap: "10px" }}
                className="workorder-intro-value d-flex text-left flex-column"
              >
                {locOption?.length > 0
                  ? locOption.map((each) => {
                      return (
                        <span
                          className={`${
                            selectedLanguage == "en"
                              ? "text-left"
                              : "text-right"
                          } `}
                          basic
                          onClick={() => {
                            if (!each?.disabled) {
                              history.push(`/locations?id=${each?.countId}`);
                            }
                          }}
                        >
                          <span
                            className={
                              each?.parentId
                                ? selectedLanguage == "en"
                                  ? "ml-5"
                                  : "mr-5"
                                : ""
                            }
                          >
                            {each?.parentId ? (
                              <SubLocationDropDownBlueIcon />
                            ) : each?.disabled ? (
                              <LocationDropDownIcon />
                            ) : (
                              <LocationDropDownIconBlue />
                            )}
                          </span>
                          <span
                            className={`${
                              selectedLanguage == "en" ? "ml-2" : "mr-2"
                            } ${each?.disabled ? "" : "work-order-link"}`}
                          >
                            {each?.text}
                          </span>{" "}
                          <span
                            style={{ color: "#0C66E4" }}
                            className="ml-2 mr-2 link-span"
                          >
                            {each?.subLocationCount &&
                            each?.subLocationCount > 0 ? (
                              <LinkBlueIcon />
                            ) : null}
                            <span
                              style={{ textDecoration: "none" }}
                              className="ml-1"
                            >
                              {each?.subLocationCount &&
                              each?.subLocationCount > 1
                                ? each?.subLocationCount
                                : ""}
                            </span>
                          </span>
                        </span>
                      );
                    })
                  : t.common.na}
              </p>
            </div>
          </div>

          <div className="d-flex align-items-start mt-5">
            
              {""}
              <label className="workorder-intro-label team-label">
                {t.teams.team_info_form.customer}{" "}
                {`${
                  targetedData?.attributes?.customers?.data
                    ? `(${targetedData?.attributes.customers.data.length})`
                    : ""
                }`}
                {":"}
             
            </label>

            <p className={`workorder-intro-value mt-1 ${
                  selectedLanguage == "en"
                    ? targetedData?.attributes?.customers?.data
                      ? ""
                      : "ml-5"
                    : targetedData?.attributes?.customers?.data
                    ? ""
                    : "mr-5"
                }`}>
              {targetedData?.attributes.customers?.data?.length > 0
                ? targetedData?.attributes?.customers?.data?.map(
                    (el, index) => (
                      <List.Item className={`d-flex mb-3`}>
                        <div className="d-flex  ">
                          <Image
                            avatar
                            title={el.attributes?.name}
                            src={
                              el.attributes.logo?.data?.attributes?.url
                                ? IMAGES_URL +
                                  el.attributes.logo?.data?.attributes?.url
                                : LetteredAvatarImageUrl(el.attributes?.name)
                            }
                            style={{ width: "34px", height: "34px" }}
                            // height="34px"
                            // width="34px"
                          />
                        </div>

                        <span className="mt-1 ml-1">
                          {el?.attributes?.name}
                        </span>
                      </List.Item>
                    )
                  )
                : t.common.na}
            </p>
          </div>

          <div className="d-flex align-items-start mt-5">
            <label className="label-item mt-1">
              {""}
              <label className="workorder-intro-label team-label">
                {t.teams.team_info_form.team_members}{" "}
                {`${
                  targetedData?.attributes?.users?.data
                    ? `(${targetedData?.attributes.users.data.length})`
                    : ""
                }`}
                {":"}
              </label>
            </label>

            <p className="workorder-intro-value">
              {targetedData?.attributes.users.data.length > 0
                ? targetedData?.attributes?.users?.data?.map((el, index) => (
                    <List.Item className={`d-flex mb-3`}>
                      <div className="d-flex  ">
                        <Image
                          avatar
                          title={
                            el.attributes?.firstName +
                            " " +
                            el?.attributes?.lastName
                          }
                          src={
                            el.attributes.profileImage?.data
                              ? IMAGES_URL +
                                el.attributes?.profileImage?.data.attributes.url
                              : LetteredAvatarImageUrl(
                                  el.attributes?.firstName +
                                    " " +
                                    el?.attributes?.lastName
                                )
                          }
                          style={selectedLanguage == "en" ?  { width: "34px", height: "34px",right:"6px" } :{ width: "34px", height: "34px",right:"-6px"  }}
                          // height="34px"
                          // width="34px"
                        />
                      </div>

                      <span className="mt-1 ml-1">
                        {el?.attributes?.firstName +
                          " " +
                          el?.attributes?.lastName}
                      </span>
                    </List.Item>
                  ))
                : t.common.na}
            </p>
          </div>
          <Divider />

          <div className="d-flex align-items-center mt-5">
            <label
              className="workorder-intro-label team-label grey"
              style={{ minWidth: "77px" }}
            >
              {t.workOrders.table.createdByUser}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.createdByUser?.data?.attributes
                ?.firstName &&
              targetedData?.attributes?.createdByUser?.data?.attributes
                ?.lastName
                ? targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.firstName +
                  " " +
                  targetedData?.attributes?.createdByUser?.data?.attributes
                    ?.lastName
                : t.common.na}
              {", "}
              <Moment
                format="DD-MM-YYYY LT"
                date={targetedData?.attributes?.createdAt}
              />
            </p>
          </div>

          <div className="d-flex align-items-center mt-3">
            <label className="workorder-intro-label team-label grey">
              {t.common.update_by}
              {":"}
            </label>
            <p className="workorder-intro-value grey">
              {targetedData?.attributes?.updatedByUser?.data
                ? targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.firstName +
                  " " +
                  targetedData?.attributes?.updatedByUser?.data?.attributes
                    ?.lastName +
                  ", " +
                  moment(targetedData?.attributes?.updatedAt).format(
                    "DD-MM-YYYY LT"
                  )
                : t.common.na}
            </p>
          </div>

          {/* <div className="preview-details-wrapper">
            <div className="d-flex align-items-center mt-5">
              <label className="detail-intro-label">
                {t.teams.team_preview.supervisor}
                {':'}
              </label>
              <p className="detail-intro-value">
                {targetedData?.attributes.superior?.data?.attributes
                  ?.firstName +
                  ' ' +
                  targetedData?.attributes?.superior?.data?.attributes
                    ?.lastName}
              </p>
            </div>
          </div> */}

          {/* <Grid columns="equal">
            <Grid.Column>
              <ul className="details-list">
                <li
                  className="list-item"
                  style={{ marginBottom: '10px' }}
                >
                  <label className="item-label">
                    {t.teams.team_preview.supervisor}
                  </label>
                  <div className="item-value">{superiorNameImg}</div>
                </li>
                <li className="list-item">
                  <label className="item-label">
                    {t.teams.team_info_form.team_members} (
                    {targetedData?.attributes.users.data.length})
                  </label>
                  <div className="item-value">
                    {targetedData?.attributes.users.data.length > 0 ? (
                      <List relaxed>
                        {targetedData?.attributes.users.data.map(
                          (person) => peopleNameImg(person)
                        )}
                      </List>
                    ) : (
                      t.common.na
                    )}
                  </div>
                </li>
              </ul>
            </Grid.Column>
            <Grid.Column>
              <ul className="details-list">
                <li className="list-item">
                  <label className="item-label">
                    {t.teams.team_preview.locations} (
                    {targetedData?.attributes?.locations?.data?.length})
                  </label>
                  <div className="item-value">
                    {targetedData?.attributes?.locations?.data?.length >
                    0 ? (
                      <List relaxed>
                        {targetedData?.attributes.locations.data.map(
                          (location) => {
                            return (
                              <div className="item-value flexbox align-center justify-between mt-4">
                                <div
                                  className="buttun-type-link"
                                  basic
                                  onClick={() =>
                                    history.push(
                                      `/locations?id=${location?.id}`
                                    )
                                  }
                                >
                                  {location?.attributes?.name}
                                  <Icon name="angle right" />
                                </div>
                              </div>
                            );
                          }
                        )}
                      </List>
                    ) : (
                      t.common.na
                    )}
                  </div>
                </li>
              </ul>
            </Grid.Column>
          </Grid> */}
        </div>
      ) : (
        <Loader active inline="centered">
          {t.common.loading}
        </Loader>
      )}
    </>
  );
};

export default Details;
