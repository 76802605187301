export default {
  customers: 'العملاء',
  customer: 'العملاء',
  customer_vendors: 'العملاء والبائعين',
  vendor: 'الموردين',
  add_customer: "إضافة العملاء",
  table: {
    id: 'رمز التعريف',
    name: 'الإسم التجاري',
    phone: 'رقم الهاتف',
    email: 'البريد الإلكتروني',
    website: 'الموقع إلاكتروني',
    country: 'البلد',
    city: 'المدينة',
    service_type: 'نوع الخدمة',
    hour_rate: 'الأجر بالساعة',
    hourly_rate: 'الأجر بالساعة',
    created_by: 'انشأ من قبل',
    created_at: 'أنشا في',
    updated_at: 'تاريخ التحديث',
    details: 'التفاصيل',
  },
  industry: {
    commercial: 'الشركات العقارية التجارية',
    corporate: 'مكاتب الشركات',
    manufacturing: 'الشركات المصنعة',
    healthcare: 'منظمات الرعاية الصحية',
    educational: 'المؤسسات التعليمية',
    government: 'مؤسسات الحكومة والقطاع العام',
    hospitality: 'شركات الضيافة والترفيه',
    data_centers: 'مراكز البيانات',
    retails: 'مراكز البيانات',
    sports: 'المرافق الرياضية والترفيهية',
  },
  filter: {
    search: 'بحث',
    country: 'البلد',
    search_by_country: 'البحث حسب البلد',
    cities: 'المدن',
    search_by_cities: 'البحث حسب المدن',
    created_at: 'تاريخ الإنشاء',
    updated_at: 'تاريخ التحديث',
    selected: 'محدد',
  },
  created_at_options: {
    any_date: 'أي تاريخ',
    today: 'اليوم',
    this_month: 'هذا الشهر',
    this_year: 'هذا العام',
    last_7_days: '٧ ايام الماضية',
    last_month: 'الشهر الماضي',
    last_year: 'العام الماضي',
  },
  no_customer_statement: 'يبدو أنك ليس لديك عملاء حتى الآن',
  press_to_add_customer: "اضغط على زر '+' لإضافة أول العملاء لك",
  form: {
    preferences:"اللغة المفضلة",
    add_preferences:"Select preferred language",
    add_name: 'أضف الإسم',
    add_description: 'أضف الوصف',
    add_location: 'اختر الموقع',
    add_email: 'أضف البريد الإلكتروني',
    add_address: 'أضف العنوان',
    add_rate: 'أضف السعر',
    add_currency: 'أضف العملة',
    add_website: 'أضف الموقع الإلكتروني',
    add_service_type: 'اختر نوع الخدمة',
    add_postal: 'أضف الرمز البريدي',
    add_industry: 'اختر القطاع',
    add_number: 'رقم الهاتف',
    add_contact_name: 'أضف اسم جهة الاتصال',
    add_contact_email: 'أضف البريد الإلكتروني لجهة الاتصال',
    add_customer: "إضافة العملاء",
    edit_customer: "تعديل العملاء",
    name: 'الإسم',
    add_customer_name: 'أضف اسم الالعملاء',
    description: 'الوصف',
    location: 'الموقع',
    select_location: 'اختر موقعا',
    phone: 'رقم الهاتف',
    website: 'الموقع الإلكتروني',
    service_type: 'نوع الخدمة',
    example: 'مثال: السباكة الكهرباء وغيرها',
    email: 'البريد الإلكتروني',
    logo: 'الشعار',
    add: 'إضافة+',
    billing_information: 'معلومات الفوترة',
    contact_information: 'معلومات جهة الاتصال',
    country: 'الدولة',
    city: 'المدينة',
    postal_code: 'الرمز البريدي',
    address: 'العنوان',
    address_line_2: 'العنوان 2',
    address_line_3: 'العنوان 3',
    rate: 'السعر',
    currency: 'العملة',
    submit: 'تقديم الطلب',
    cancel: 'إلغاء',
    customer_added_success: 'تمت إضافة الالعملاء بنجاح',
    customer_updated_success: 'تم تحديث الالعملاء بنجاح',
    invalid_email: 'البريد الإلكتروني غير صحيح',
    invalid_phone: 'رقم الهاتف غير صحيح',
    delete_customer_statement: 'هل أنت متأكد أنك تريد حذف هذا الالعملاء؟',
    customer_delete_success: 'تم حذف الالعملاء بنجاح',
    customer_delete_related_error:
      'لا يمكن حذف هذا الالعملاء لأنه متصل ببيانات أخرى في النظام',
  },
  no_customer_added: 'ابدء  في إضافة العملاء',
  how_to_add_customer: 'كيفية إضافة العملاء',
  title_small: 'العملاء',
  contact_name: 'اسم جهة الاتصال',
  contact_phone: 'رقم هاتف جهة الاتصال',
  billing_name: 'اسم الفوترة',
  contact_email: 'البريد الإلكتروني لجهة الاتصال',
  contact_person: 'جهة الاتصال',
  same_info: 'انسخ معلومات التفاصيل إلى معلومات الفوترة',
};
