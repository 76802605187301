export default {
  title: "لوحة التحكم",
  tabs: {
    WO_management: "إدارة أوامر العمل",
    assets_management: "إدارة الممتلكات",
    inventory_management: "إدارة المخزون",
    recource_planner: "تخطيط الموارد",
    maintenance_management: "Maintenance Management",
    timesheet: "جدول المواعيد",
    cost: "التكلفة",
  },

  box: {
    unassign: "أوامر العمل الغير مكلفة ",
    overDue: "أوامر العمل المتأخرة عن الوقت المحدد",
    ontime: "أوامر العمل المنجزة في الوقت المحدد",
    increase: "زيادة عن الشهر الماضي",
    decrease: "انخفاض عن الشهر الماضي",
  },

  cards: {
    total_wos: "Total WO",
    clear: "مسح الكل",
    status: "حالة أمر العمل",
    assignee: "أمر العمل حسب الشخص المكلف",
    total: "الرقم الإجمالي",
    response: "متوسط ​​وقت الاستجابة لامر العمل حسب الأولوية",
    response_time_label: "Average Response Time (Hrs)",
    date: "التاريخ",
    time_hrs: "الوقت (الساعات)",
    technician_res: "متوسط وقت الاستجابة لامر العمل حسب الفني",
    technician_name: "اسم الفني",
    no_of_WO: "عدد أوامر العمل",
    avg_time: "متوسط ​​الوقت (دقيقة)",
    high: "عالية",
    low: "منخفضة",
    med: "متوسطة",
    resolution: "متوسط ​​وقت حل امر العمل حسب الأولوية",
    resolution_time_label: "Average Resolution Time (Hrs)",
    resolution_technician: "متوسط وقت حل امر العمل حسب الفني",
    cost: "التكلفة المتوقعة مقابل التكلفة الفعلية",
    avg_cost: "متوسط ​​تقدير التكلفة",
    avg_actual: "متوسط الكلفة الفعلية",
    days: "الأيام",
    cost_currency: "التكلفة",
    time: "الوقت المقدر مقارنة مع الوقت الفعلي",
    avg_time_data: "متوسط الوقت المقدر",
    avg_actual_time: "متوسط الوقت الفعلي",
    top10Wo: "أفضل 10 أوامر عمل",
    category: "الفئة",
    received: "مستلم",
    comp: "مكتمل",
    overdueWO: "متأخر",
    utilisation_rate: "معدل الاستغلال الزمني",
    avail_hr: "ساعات العمل المتاحة",
    incurred_hr: "ساعات العمل المستغلة",
    actual_hr: "الساعات الفعلية",
    uti: "الاستغلال",
    other_filters: "Other Filters",
    refresh: "تحديث",
    refreshed: "تم تحديثه",
  },
  filter: {
    period: "الفترة",
    time_period: "الفترة الزمنية",
    today: "اليوم",
    yesterday: "الأمس",
    week: "الأسبوع الماضي",
    month: "الشهر الماضي",
    month_6: "آخر 6 شهور",
    year: "السنة الماضية",
    date: "اختر التاريخ",
  },

  dateOptions: {
    day: "اليوم",
    week: "الأسبوع",
    month: "الشهر",
    presets:"الإعدادات المسبقة",
    custom:"مخصص"
  },

  percantage: "النسبة",
  number: "العدد",
  expand_full: "وضع ملء الشاشة",
  add_customized: "الإضافة المخصصة إلى لوحة التحكم",
  tooltip: {
    unAssgin:
      "نسبة أوامرالعمل التي تم إصدارها ولم يتم تحديد المكلف بها. ",
    overDue:
      "نسبة أوامر العمل المتأخرة / التي تخطت موعد التسليم ولم تنتهي بعد. ",
    onTime:
      " نسبة أوامر العمل التي تم تسليمها ضمن الإطار الزمني المحدد.",
    WOstatus:
      "العدد والنسبة لأوامر العمل: المنجزة: أوامر العمل المنجزة، والمغلقة. قيد المراجعة: أوامر العمل المنجزة لكنها بانتظار موافقة المشرف. قيد التنفيذ: أوامر العمل التي ما زالت قيد التنفيذ قيد الانتظار: أوامر العمل المعلقة لأسباب معينة. المتاحة: أوامر العمل الجديدة التي لم يتم البدء بتنفيذها.",
    assignee: "عدد ونسبة أوامر العمل التي تم تعيينها لكل عامل.",
    response_prioprity:
      "متوسط الوقت المستغرق لبدء العمل على أمر العمل من لحظة استلامه مصنف حسب مستوى الأولوية.",
    resolution_prioprity:
      "الوقت الإجمالي المستغرق من إنشاء أمر العمل حتى الانتهاء منه، مصنف حسب مستوى الأولوية.",
    resolution_technician:
      "الوقت الإجمالي المستغرق من إنشاء أمر العمل حتى الانتهاء منه، مصنف حسب الفني المعين.",
    response_technician:
      "متوسط الوقت المستغرق لبدء العمل على أمر العمل من لحظة استلامه، مصنف حسب الفني المعين.",
    cost:
      "مقارنة بين التكاليف المقدرة لأوامر العمل مقابل التكاليف الفعلية اللي تم دفعها أثناء حلها.",
    time:
      "مقارنة عدد الساعات المقدّرة لإنجاز أوامر العمل مقابل عدد الساعات الفعلية المقضية في إنجازها.",
    top10: "أنواع وأعداد أوامر العمل المستلمة، المنجزة، والمتأخرة.",
    utilisation:
      "نسبة ساعات العمل الإجمالية للموظف التي يتم قضاءها بشكل منتج / بفاعلية على أوامر العمل.",
  },

  asset_box:{
    total_asset: "Total Number of Assets",
    active:"Active",
    inActive_asset:"Inactive Assets",
    active_asset:"Active Assets",
    inActive:"Inactive",
  },

  asset_card:{
    ar:"> 500 الأصول",
    asset_location: "الممتلكات حسب الموقع",
    red: "اللون الأحمر",
    orange: "اللون البرتقالي",
    green: "اللون الأخضر",
    asset500: "> 500 ممتلك",
    asset250: "< 250-500 ممتلك",
    assetless250: "250 ممتلك",
   asset_category:"تصنيف الممتلك ",
   asset_no:"Assets No", // I need to check if its Asset ID or NO
   category:"Category", // I need to check with Noura for the AR
   age:"Asset Age", // need to update
   number_assets:"عدد الممتلكات",
   age_yr:"Age (Years)", // need to update
   tco:"إجمالي تكلفة الملكية",
   p_price:"تكلفة الشراء",
   m_price:"تكلفة الصيانة", // need to review
   disposal_price:"تكلفة التخلص",
   asset_category_label:"Asset Category", // need to review
   price:"السعر",
   value:"قيمة الممتلك",
   disposal_schedule:"Disposal Schedule", // need to updated
   asset_id:"رمز الممتلك",
   asset_name:"اسم الممتلك",
   remaining_life:"Remaining useful life", // need to update
   scheduled_date:"تاريخ التخلص المقرر", // need to review
   reason:"سبب التخلص",
   status:"الحالة"
  },

  asset_tooltip:{
    total_asset:"Count of all assets registered. This includes all types of assets currently recorded in the system.",
    active_asset:"Count of active assets vs. inactive assets. The number changes when you move the toggle between active and inactive states.",
    category_asset:"Count of assets grouped by category. See the distribution of assets across different categories.",
     location_asset:"Count of assets grouped by location. Visualize where assets are located, helping to manage and allocate resources efficiently.",
     age_asset:"Count of assets grouped by age grouping, considering that the asset age starts from when the asset was placed in service.",  
     disposal: "Number of assets scheduled for disposal.  Track upcoming disposals and manage the phasing out of assets.",
     value_asset: "Total purchase price of all assets registered minus depreciation. Shows the current book value of your assets.",
     TCO_asset: "Sum of acquisition cost, maintenance cost, and disposal cost. Gives a comprehensive view of the total expenditure on assets."
  },

  maintenance_card: {
    total_downtime: "Total Downtime Events",
    reactive_maintenance: "Reactive Maintenance Events",
    preventive_maintenance: "Preventive Maintenance Events",
    total_cost_maintenance: "Total Cost of Maintenance",
    category_maintenance: "Maintenance Cost Per Asset Category",
    total_cost: "Total Cost",
    mtbf: "MTBF",
    mtbf_lebal: "MTBF (hours)",
    mttr: "MTTR",
    mttr_lebal: "Mean Time to Repair (hours)",
    purchase_price_title: "Maintenance Vs Purchase Price",
    purchase_price: "Purchase Price",
    maintenance_price: "Maintenance Cost",
    mttr_hrs: "MTTR (hours) ",
    mttr_time: "Mean Time",
    world_class: "World-class MTTR: ",
    hr_5: "<5 hours",
    planned_preventive: "Planned Preventive Maintenance",
    maintenance_hours: "Maintenance Hours",
    planned_maintenance_hours: "Planned Maintenance Hours",
  },
};
